import i18next from 'i18next';
import moment from 'moment-timezone';
import React from 'react';

import Tooltip from '@mui/material/Tooltip';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { formatNumberWithCurrency } from '@commons/DisplayNumber';
import { getTheme } from '@commons/utils/theme';
import Text, { ENUM_COLORS } from '@commons/Text';

import { ColoredRound } from './styledComponents';

const theme = getTheme();

const ENUM_STATUS = {
  OK: 'ok',
  OK_NO_TURNOVER: 'ok_no_turnover',
  MISSING_FILE: 'missing_file',
  EMPTY_FILE: 'empty_file',
  MISSING_DATA: 'missing_data',
  NO_CHECK: 'no_check',
  NO_STORE_MAPPED: 'no_store_mapped',
  CLOSED: 'closed',
  UNAVAILABLE: 'unavailable',
  UNAUTHORIZED: 'unauthorized',
  FORBIDDEN: 'forbidden',
};

const dayOfWeek = (date) => (moment(date).day() + 6) % 7;

const getStatusColor = (status) => {
  switch (status) {
    case ENUM_STATUS.OK:
      return theme.colors.brand.primaryDarker;
    case ENUM_STATUS.OK_NO_TURNOVER:
      return theme.colors.infoOrange;

    case ENUM_STATUS.MISSING_FILE:
    case ENUM_STATUS.EMPTY_FILE:
    case ENUM_STATUS.MISSING_DATA:
    case ENUM_STATUS.UNAVAILABLE:
    case ENUM_STATUS.UNAUTHORIZED:
    case ENUM_STATUS.FORBIDDEN:
      return theme.colors.infoRed;

    case ENUM_STATUS.NO_CHECK:
      return theme.colors.dgrey;

    case ENUM_STATUS.NO_STORE_MAPPED:
      return theme.colors.infoBlue;

    case ENUM_STATUS.CLOSED:
      return theme.colors.infoLightOrange;

    default:
      return theme.colors.dgrey;
  }
};

const renderInputCell = (props, currency, translatedStoreName) => {
  const { value, row, field } = props;

  if (!value) {
    return;
  }

  const { status, turnover } = value;
  const {
    store: { closingDays },
  } = row;

  const formattedStatus =
    closingDays && closingDays.includes(dayOfWeek(field)) ? ENUM_STATUS.CLOSED : status;

  const statusColor = getStatusColor(formattedStatus);

  const statusText =
    formattedStatus === ENUM_STATUS.NO_STORE_MAPPED
      ? `${translatedStoreName} ${i18next
          .t(`ADMIN.FTP_DEBUG.STATUS_${formattedStatus.toUpperCase()}`)
          .toLowerCase()}`
      : i18next.t(`ADMIN.FTP_DEBUG.STATUS_${formattedStatus.toUpperCase()}`);

  const formattedTurnover = turnover
    ? `${i18next.t('GENERAL.METRIC_TURNOVER_EX_TAX_WITHOUT_CURRENCY')} : ${formatNumberWithCurrency(
        {
          number: turnover,
          currency,
          displayCurrencyCode: true,
          withoutDecimals: true,
          shortenDigits: false,
        },
      )}`
    : null;

  return (
    <Tooltip
      placement="top"
      title={<Text color={ENUM_COLORS.LIGHT}>{formattedTurnover || statusText}</Text>}
      arrow
    >
      <ColoredRound color={statusColor} />
    </Tooltip>
  );
};

export const getColumns = (selectedDates, currency, translatedStoreName) => {
  const dayColumnFields = {
    type: 'number',
    minWidth: 60,
    disableReorder: true,
    sortable: false,
    filterable: false,
    renderCell: (props) => renderInputCell(props, currency, translatedStoreName),
  };

  const storeColumn = {
    disableReorder: true,
    field: 'storeName',
    headerName: i18next.t('GENERAL.STORE'),
    minWidth: 240,
  };

  const dateColumns = selectedDates.map((date) => ({
    ...dayColumnFields,
    field: date,
    headerName: moment(date).format(DATE_DISPLAY_FORMATS.CONDENSED_DATE_MONTH),
    disableColumnMenu: true,
  }));

  return [storeColumn, ...dateColumns];
};
