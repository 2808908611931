import { connect } from 'react-redux';
import { get, isEmpty, keyBy, round, sum } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useState, useEffect, useCallback } from 'react';

import {
  DataGridPro,
  GridToolbar,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GRID_CHECKBOX_SELECTION_FIELD,
  GridCellModes,
  useGridApiRef,
  gridExpandedSortedRowIdsSelector,
} from '@mui/x-data-grid-pro';
import { Tabs, Tab, Box, IconButton } from '@mui/material';

import {
  initializeProductionPlanningValues,
  productionPlanningUpdateQuantity,
  productionPlanningUpdateQuantityByBatch,
} from '@actions/centralKitchenProductionPlanning';
import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage } from '@actions/messageconfirmation';

import { DATAGRID_LOCALES } from '@commons/utils/dataGrid';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getPreviousDayOfStore } from '@commons/utils/date';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { canEditProductionPlanning } from '@selectors/actions/productionActions';
import { getAuthorizedActions } from '@selectors/featureProps';
import { getCentralKitchenStores } from '@selectors/stores';
import { getClientInfo } from '@selectors/client';
import { getPrevHorizon } from '@selectors/featureProps';

import { getStoresMappedToSupplierOfClient } from '../../../services/client';
import { usePeriodDatePickerState } from '@hooks/usePeriodDatePickerState';

import centralService from '@services/central';
import productionPlanningService from '@services/productionPlanning';

import { convertEntityQuantityToInpulseUnit } from '@commons/utils/conversion';

import { Container, ContentContainer, HeaderIcon } from './styledComponents';
import { CustomDataGridNoRowOverlay } from './components/CustomDataGridNoRowOverlay';
import { exportProductionPlanning } from './utils/export';
import { getProductionPlanningColumns } from './utils/columns';
import { PRODUCTION_PLANNING_TAB_INDEXES } from './utils/constants';
import formatUtils from './utils/format';

import ColumnMenu from './components/ColumnMenu';
import Header from './components/Header';
import InnerContent from './components/InnerContent';

const DEFAULT_TIMEZONE = 'Europe/Paris';

const CARRET_FULL_DOWN = '/images/inpulse/carret-black.svg';

const CentralKitchenProductionPlanning = (props) => {
  const {
    client: { clientId, storeName },
    match: { path },
    centralKitchens,
    pageLoading,
    pageLoaded,
    prevHorizon,
    initializeProductionPlanningValues,
    showErrorMessage,
    productionQtyByEntityIdAndDate,
    productionPlanningUpdateQuantity,
    productionPlanningUpdateQuantityByBatch,
    user,
    authorizedActions,
    currency,
  } = props;

  // Production
  const [selectedCentralKitchen, setSelectedCentralKitchen] = useState(centralKitchens[0]);
  const [recipesById, setRecipesById] = useState({});
  const [preparations, setPreparations] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [stockByEntityId, setStockByEntityId] = useState({});
  const [isLoadingProductionPlanning, setIsLoadingProductionPlanning] = useState(true);
  const [isLoadingRecipes, setIsLoadingRecipes] = useState(true);
  const [recipeCategories, setRecipeCategories] = useState([]);

  // Consumption
  const [isLoadingStores, setIsLoadingStores] = useState(false);
  const [stores, setStores] = useState([]);
  const [selectedStores, setSelectedStores] = useState([]);
  const [consumptionByEntityId, setConsumptionByEntityId] = useState({});

  // Params
  const [columns, setColumns] = useState({});
  const [selectedTabIndex, setSelectedTabIndex] = useState(PRODUCTION_PLANNING_TAB_INDEXES.RECIPES);
  const [currentEditingCell, setCurrentEditingCell] = useState({});

  // MUI states
  const [cellModesModel, setCellModesModel] = useState({});
  const [payload, setPayload] = useState([]);
  const [selectedRecipeIds, setSelectedRecipeIds] = useState([]);

  const clientStoreNameTranslation = getClientStoreNameTranslation(storeName, true);
  const centralKitchenStoreName = clientStoreNameTranslation.toLowerCase();
  const centralKitchenTimezone = get(selectedCentralKitchen, 'timezone', DEFAULT_TIMEZONE);

  const startOfWeek = moment().startOf('week');
  const endOfWeek = moment().endOf('week');

  const productionPeriodPickerState = usePeriodDatePickerState(
    startOfWeek,
    endOfWeek,
    centralKitchenTimezone,
  );

  const consumptionPeriodPickerState = usePeriodDatePickerState(null, null, centralKitchenTimezone);

  const canEditRecipeProduction = canEditProductionPlanning(authorizedActions);

  const apiRef = useGridApiRef();

  /** USE EFFECTS */

  useEffect(() => {
    if (!selectedCentralKitchen) {
      return;
    }

    fetchStoresAssociatedToCentralKitchen();
  }, [selectedCentralKitchen]);

  // Prepare columns for DataGrid
  useEffect(() => {
    if (
      !selectedCentralKitchen ||
      !productionPeriodPickerState.startDate ||
      !productionPeriodPickerState.endDate
    ) {
      return;
    }

    const disableToggleDetailPanel =
      !consumptionPeriodPickerState.startDate || !consumptionPeriodPickerState.endDate;

    setColumns(
      getProductionPlanningColumns(
        selectedCentralKitchen,
        productionPeriodPickerState.startDate,
        productionPeriodPickerState.endDate,
        selectedTabIndex,
        disableToggleDetailPanel,
        consumptionPeriodPickerState.endDate,
        recipeCategories,
        selectedRecipeIds,
        canEditRecipeProduction,
      ),
    );
  }, [
    selectedCentralKitchen,
    productionPeriodPickerState.startDate,
    productionPeriodPickerState.endDate,
    recipeCategories,
    selectedRecipeIds,
  ]);

  useEffect(() => {
    if (
      !selectedCentralKitchen ||
      !productionPeriodPickerState.startDate ||
      !productionPeriodPickerState.endDate
    ) {
      return;
    }

    fetchProductionPlanning();

    // Reset vertical scroll when we change tabs, timeout is compulsory in order to avoid crash https://github.com/mui/mui-x/issues/6411#issuecomment-1271556519
    setTimeout(() => {
      apiRef.current.scrollToIndexes({ rowIndex: 0 });
    }, 0);
  }, [
    selectedCentralKitchen,
    productionPeriodPickerState.startDate,
    productionPeriodPickerState.endDate,
    apiRef,
  ]);

  useEffect(() => {
    if (!isLoadingProductionPlanning) {
      getRecipesComposition();
    }
  }, [isLoadingProductionPlanning]);

  // Set columns and fetch consumption when recipes or consumption period changes
  useEffect(() => {
    if (
      !recipesById ||
      !consumptionPeriodPickerState.startDate ||
      !consumptionPeriodPickerState.endDate
    ) {
      return;
    }

    const isConsumptionPeriodSet =
      !!consumptionPeriodPickerState.startDate && !!consumptionPeriodPickerState.endDate;

    setColumns(
      getProductionPlanningColumns(
        selectedCentralKitchen,
        productionPeriodPickerState.startDate,
        productionPeriodPickerState.endDate,
        selectedTabIndex,
        !isConsumptionPeriodSet,
        consumptionPeriodPickerState.endDate,
        recipeCategories,
        selectedRecipeIds,
        canEditRecipeProduction,
      ),
    );

    if (isConsumptionPeriodSet) {
      fetchRecipesConsumptions();
    }
  }, [
    recipesById,
    consumptionPeriodPickerState.startDate,
    consumptionPeriodPickerState.endDate,
    selectedStores,
  ]);

  // Set payload when all data changes
  useEffect(() => {
    const result = getSelectedRows();

    setPayload(result);
  }, [recipesById, stockByEntityId, selectedTabIndex, consumptionByEntityId]);

  /** FUNCTIONS */

  const fetchStoresAssociatedToCentralKitchen = async () => {
    try {
      setIsLoadingStores(true);
      const centralKitchenSuppliers = await centralService.getkitchenSupplierByStoreIds([
        selectedCentralKitchen.id,
      ]);

      // Default central kitchen supplier is first one available
      const centralKitchenSupplier = centralKitchenSuppliers[0];

      const storesMappedToSelectedCentralKitchen = await getStoresMappedToSupplierOfClient(
        clientId,
        centralKitchenSupplier.id,
      );

      setStores(storesMappedToSelectedCentralKitchen);
      setSelectedStores(storesMappedToSelectedCentralKitchen);
    } catch {
      showErrorMessage(
        i18next.t('BACKOFFICE.STORES.FETCHING_ERROR', { storeName: centralKitchenStoreName }),
      );
    } finally {
      setIsLoadingStores(false);
    }
  };

  const getRecipesComposition = async () => {
    const isFutureProduction = moment()
      .startOf('day')
      .isSameOrBefore(moment(productionPeriodPickerState.startDate).startOf('day'));

    try {
      setIsLoadingRecipes(true);

      const formattedStartDate = isFutureProduction
        ? null
        : productionPeriodPickerState.startDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

      const formattedEndDate = isFutureProduction
        ? null
        : productionPeriodPickerState.endDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

      const fetchedRecipes = await productionPlanningService.getCentralKitchenRecipesOfStore(
        selectedCentralKitchen.id,
        formattedStartDate,
        formattedEndDate,
      );

      const fetchedRecipesValues = Object.values(fetchedRecipes);

      setRecipesById(fetchedRecipes);

      const { formattedPreparations, formattedIngredients } =
        formatUtils.formatPreparationsAndIngredients(
          productionQtyByEntityIdAndDate,
          fetchedRecipes,
        );

      setPreparations(formattedPreparations);
      setIngredients(formattedIngredients);

      const updatedQuantities = formatUtils.computePreparationAndIngredientsProduction(
        productionQtyByEntityIdAndDate,
        fetchedRecipes,
      );

      initializeProductionPlanningValues(updatedQuantities);

      const recipeCategories = fetchedRecipesValues.reduce((acc, recipe) => {
        const recipeCategory = recipe.category || i18next.t('GENERAL.NONE_VALUE');
        acc.add(recipeCategory);

        return acc;
      }, new Set());

      setRecipeCategories([...recipeCategories]);

      if (!selectedCentralKitchen.showYesterdayStock || !fetchedRecipesValues.length) {
        return;
      }

      // If showYesterdayStock is true, we fetch yesterday stocks via the recipeStocks analytics route
      const yesterdayDate = getPreviousDayOfStore(selectedCentralKitchen).format(
        DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
      );

      const fetchedRecipeCategoriesIds = fetchedRecipesValues.map(({ categoryId }) => categoryId);

      const recipesWithYesterdayStock = await centralService.getRecipesPastAnalyticsList(
        selectedCentralKitchen.id,
        yesterdayDate,
        fetchedRecipeCategoriesIds,
      );

      const yesterdayStockKeyById = keyBy(recipesWithYesterdayStock, 'entityId');

      setStockByEntityId(yesterdayStockKeyById);
    } catch {
      showErrorMessage(
        i18next.t('PRODUCTION.CENTRAL_KITCHEN.PLANNING_PRODUCTION_RECIPES_FETCH_ERROR'),
      );
    } finally {
      setIsLoadingRecipes(false);
    }
  };

  /** The purpose of this function is to build an object containing for each entity (recipes and their composition)
   * the consumption for each day of the selected calculation period.
   *
   * @param {object} consumptionByRecipeId - an object with recipeId as key and an object as value which is composed of key for each date with consumption and a total
   * @returns {object} - an object with entityId as key and an object as value which is composed of key for each date with consumption
   *
   * shape: {
   *   entityId: {
   *     'YYYY-MM-DD': consumption,
   *     'YYYY-MM-DD': consumption,
   *     ...
   *   }
   * }
   */
  const computeConsumptionByEntityIdByDay = (consumptionByRecipeId) => {
    const consumptionByEntityIdByDay = {};

    for (const { id: recipeId, composition, quantity: recipeQuantity } of Object.values(
      recipesById,
    )) {
      const recipeTotalConsumption =
        (consumptionByRecipeId[recipeId] && consumptionByRecipeId[recipeId].total) || null;

      if (!consumptionByEntityIdByDay[recipeId]) {
        consumptionByEntityIdByDay[recipeId] = { total: null };
      }

      consumptionByEntityIdByDay[recipeId].total += recipeTotalConsumption;

      for (const { id: childId, quantity } of Object.values(composition)) {
        if (!consumptionByEntityIdByDay[childId]) {
          consumptionByEntityIdByDay[childId] = { total: null };
        }

        if (recipeTotalConsumption != null) {
          const childTotalConsumptionFromRecipe =
            (recipeTotalConsumption / recipeQuantity) * quantity;

          consumptionByEntityIdByDay[childId].total += childTotalConsumptionFromRecipe;
        }

        for (
          let currentDate = moment(consumptionPeriodPickerState.startDate).clone();
          currentDate.isSameOrBefore(moment(consumptionPeriodPickerState.endDate));
          currentDate.add(1, 'day')
        ) {
          const formattedDate = currentDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

          const recipeConsumption =
            (consumptionByRecipeId[recipeId] && consumptionByRecipeId[recipeId][formattedDate]) ||
            null;

          if (!consumptionByEntityIdByDay[recipeId][formattedDate]) {
            consumptionByEntityIdByDay[recipeId][formattedDate] = recipeConsumption;
          }

          if (!consumptionByEntityIdByDay[childId][formattedDate]) {
            consumptionByEntityIdByDay[childId][formattedDate] = null;
          }

          if (recipeConsumption != null) {
            consumptionByEntityIdByDay[childId][formattedDate] +=
              (recipeConsumption / recipeQuantity) * quantity;
          }
        }
      }
    }

    return consumptionByEntityIdByDay;
  };

  const fetchRecipesConsumptions = async () => {
    pageLoading();
    try {
      const consumptionStoreIds = selectedStores.map(({ id }) => id);

      const recipeIds = Object.keys(recipesById);

      const recipesConsumptions =
        await productionPlanningService.getCentralKitchenRecipesConsumptions(
          selectedCentralKitchen.id,
          consumptionStoreIds,
          consumptionPeriodPickerState.startDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
          consumptionPeriodPickerState.endDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
          recipeIds,
        );

      const consumption = computeConsumptionByEntityIdByDay(recipesConsumptions);

      setConsumptionByEntityId(consumption);
    } catch (err) {
      showErrorMessage(
        i18next.t('PRODUCTION.CENTRAL_KITCHEN.PLANNING_CONSUMPTION_CALCULATION_ERROR'),
      );
    } finally {
      pageLoaded();
    }
  };

  const fetchProductionPlanning = async () => {
    try {
      setIsLoadingProductionPlanning(true);

      const productionStartDate = moment(productionPeriodPickerState.startDate).format(
        DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
      );
      const productionEndDate = moment(productionPeriodPickerState.endDate).format(
        DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
      );

      const fetchedProductionPlannings =
        await productionPlanningService.getCentralKitchenProductionPlanning(
          selectedCentralKitchen.id,
          productionStartDate,
          productionEndDate,
        );

      /** Set values in production planning reducer */
      const productionQtyByEntityIdAndDate = formatUtils.getProductionQtyByEntityIdAndDate(
        fetchedProductionPlannings,
      );

      initializeProductionPlanningValues(productionQtyByEntityIdAndDate);
    } catch {
      showErrorMessage(i18next.t('PRODUCTION.CENTRAL_KITCHEN.PLANNING_PRODUCTION_FETCH_ERROR'));
    } finally {
      setIsLoadingProductionPlanning(false);
    }
  };

  const handleUpsertProductionPlanning = async (entityId, productionDate, recipeQuantity) => {
    try {
      await productionPlanningService.upsertCentralKitchenProductionPlanning(
        selectedCentralKitchen.id,
        entityId,
        productionDate,
        recipeQuantity,
        user.id,
      );

      // Having an already planned quantity is not guaranteed.
      const previousRecipeQuantity = get(
        productionQtyByEntityIdAndDate,
        `${entityId}.${productionDate}`,
        0,
      );
      const diff = recipeQuantity - previousRecipeQuantity;

      /** Update production planning reducer */
      productionPlanningUpdateQuantity(entityId, productionDate, recipeQuantity);

      const recipeWithChangedQty = recipesById[entityId];

      const { quantity: recipeParentQty, unit } = recipeWithChangedQty;

      const convertedRecipeParentQty = convertEntityQuantityToInpulseUnit(recipeParentQty, unit);

      // Go though the concerned recipe's composition
      // For each component, whether a preparation or ingredient, update the quantity in the reducer
      Object.values(recipeWithChangedQty.composition).forEach(({ id, quantity }) => {
        if (!recipesById[id]) {
          // Make sure it's either a preparation or an ingredient

          // Aggregated, since a component's planned quantity can come from more than one recipe.
          const previousAggregatedQuantity = get(
            productionQtyByEntityIdAndDate,
            `${id}.${productionDate}`,
            0,
          );

          const newQuantity =
            previousAggregatedQuantity + diff * (quantity / convertedRecipeParentQty);

          productionPlanningUpdateQuantity(id, productionDate, newQuantity);
        }
      });
    } catch {
      showErrorMessage(i18next.t('PRODUCTION.CENTRAL_KITCHEN.PLANNING_PRODUCTION_UPSERT_ERROR'));
    }
  };

  const handleUpsertProductionPlanningByBatch = async (
    productionPlanningsToBeUpdated,
    recipeNewQuantitiesByRecipeId,
    productionDate,
  ) => {
    if (!productionPlanningsToBeUpdated.length) {
      return;
    }

    try {
      // Upsert production planning
      await productionPlanningService.upsertCentralKitchenProductionPlanningByBatch(
        productionPlanningsToBeUpdated,
      );

      updateProductionPlanningReducerByBatch(recipeNewQuantitiesByRecipeId, productionDate);
    } catch {
      showErrorMessage(
        i18next.t('PRODUCTION.CENTRAL_KITCHEN.PLANNING_PRODUCTION_BATCH_UPSERT_ERROR'),
      );
    }
  };

  /**
   * Update production planning reducer by batch
   *
   * @param {Object} recipeNewQuantitiesByRecipeId - Set of recipe quantities key by recipe id
   * @param {String} productionDate - Production date to be filled in
   */
  const updateProductionPlanningReducerByBatch = (
    recipeNewQuantitiesByRecipeId,
    productionDate,
  ) => {
    const recipeIdsToBeUpdated = Object.keys(recipeNewQuantitiesByRecipeId);

    // Used for recipes
    const updatedRecipeQuantitiesByEntityIdAndDate = {};

    // Used by compositions (preparation and ingredients)
    const updatedCompositionQuantitiesByEntityIdAndDate = {};

    for (const recipeId of recipeIdsToBeUpdated) {
      const newRecipeQuantity = get(recipeNewQuantitiesByRecipeId, recipeId, 0);

      // Having an already planned quantity is not guaranteed.
      const previousRecipeData = get(productionQtyByEntityIdAndDate, `${recipeId}`, {});

      const previousRecipeQuantity = get(
        productionQtyByEntityIdAndDate,
        `${recipeId}.${productionDate}`,
        0,
      );

      const diff = newRecipeQuantity - previousRecipeQuantity;

      // Prepare recipe quantity update in reducer
      updatedRecipeQuantitiesByEntityIdAndDate[recipeId] = {
        ...previousRecipeData,
        [productionDate]: newRecipeQuantity,
      };

      const recipeWithChangedQty = recipesById[recipeId];

      const { quantity: recipeParentQty, unit } = recipeWithChangedQty;

      const convertedRecipeParentQty = convertEntityQuantityToInpulseUnit(recipeParentQty, unit);

      // Go though the concerned recipe's composition
      // For each component, whether a preparation or ingredient, update the quantity for the reducer by batch
      Object.values(recipeWithChangedQty.composition).forEach(({ id, quantity }) => {
        if (!recipesById[id]) {
          const previousCompositionData = get(productionQtyByEntityIdAndDate, `${id}`, {});

          // Initialize with planned quantity from reducer if there is no data
          if (!updatedCompositionQuantitiesByEntityIdAndDate[id]) {
            const previousCompositionQuantityInReducer = get(
              productionQtyByEntityIdAndDate,
              `${id}.${productionDate}`,
              0,
            );

            updatedCompositionQuantitiesByEntityIdAndDate[id] = {
              [productionDate]: previousCompositionQuantityInReducer,
            };
          }

          // Aggregated, since a component's planned quantity can come from more than one recipe.
          const previousAggregatedQuantity = get(
            updatedCompositionQuantitiesByEntityIdAndDate,
            `${id}.${productionDate}`,
            0,
          );

          const newQuantity =
            previousAggregatedQuantity + diff * (quantity / convertedRecipeParentQty);

          updatedCompositionQuantitiesByEntityIdAndDate[id] = {
            ...previousCompositionData,
            [productionDate]: newQuantity,
          };
        }
      });

      // Update by batch compositions aggregated quantities
      productionPlanningUpdateQuantityByBatch(updatedCompositionQuantitiesByEntityIdAndDate);
    }

    // Update by batch recipes aggregated quantities
    productionPlanningUpdateQuantityByBatch(updatedRecipeQuantitiesByEntityIdAndDate);
  };

  const processRowUpdate = (updatedRow, originalRow) => {
    const { id, field } = currentEditingCell;

    const updatedQuantity = updatedRow[field];
    const oldQuantity = originalRow[field];
    const oldTotal = originalRow['total'] || 0;

    const hasChanges = updatedQuantity !== oldQuantity;

    // Parse to float only if the new input is not an integer
    const parsedQuantity =
      hasChanges && !Number.isInteger(updatedQuantity)
        ? +parseFloat(updatedQuantity).toFixed(2)
        : updatedQuantity;

    if (hasChanges) {
      const updatedQuantity = parsedQuantity && parsedQuantity >= 0 ? parsedQuantity : 0;

      handleUpsertProductionPlanning(id, field, updatedQuantity);

      const diff = updatedQuantity - (oldQuantity || 0);

      return {
        ...updatedRow,
        [field]: updatedQuantity,
        total: oldTotal + diff,
      };
    }

    return updatedRow;
  };

  /** MUI Callbacks */

  // TODO MS4: Change this to avoid reformatting on tab change
  const getSelectedRows = () => {
    if (isEmpty(recipesById)) {
      return [];
    }

    switch (selectedTabIndex) {
      case PRODUCTION_PLANNING_TAB_INDEXES.RECIPES:
        const recipesResult = formatUtils.formatRecipesProductionPlanning(
          productionQtyByEntityIdAndDate,
          recipesById,
          stockByEntityId,
          consumptionByEntityId,
        );

        return recipesResult;
      case PRODUCTION_PLANNING_TAB_INDEXES.PREPARATIONS:
        const prepResult = formatUtils.formatPreparationsAndIngredients(
          productionQtyByEntityIdAndDate,
          recipesById,
          consumptionByEntityId,
        ).formattedPreparations;

        return prepResult;
      case PRODUCTION_PLANNING_TAB_INDEXES.INGREDIENTS:
        const ingredientsResult = formatUtils.formatPreparationsAndIngredients(
          productionQtyByEntityIdAndDate,
          recipesById,
          consumptionByEntityId,
        ).formattedIngredients;

        return ingredientsResult;
      default:
        return [];
    }
  };

  const getDetailPanelContent = useCallback(
    ({ row }) => (
      <InnerContent
        consumptionComputation={selectedCentralKitchen.consumptionComputation}
        endDate={consumptionPeriodPickerState.endDate}
        row={row}
        startDate={consumptionPeriodPickerState.startDate}
      />
    ),
    [consumptionPeriodPickerState.startDate, consumptionPeriodPickerState.endDate],
  );

  const handleExport = () => {
    const userLanguageCode = get(user, 'lnkLanguageAccountrel.code', 'fr');

    // From MUI - get visible row ids (we can filter rows)
    const visibleIdsToExport = gridExpandedSortedRowIdsSelector(apiRef);

    const recipesToExport = Object.values(recipesById).reduce((acc, recipe) => {
      if (visibleIdsToExport.includes(recipe.id)) {
        const totalConsumption = get(consumptionByEntityId, `${recipe.id}.total`, null);

        const recipeProductionByDate = productionQtyByEntityIdAndDate[recipe.id];

        const recipeRealStockValue = get(stockByEntityId[recipe.id], 'realStock.unit', null);
        const recipeTheoreticalStockValue = get(
          stockByEntityId[recipe.id],
          'theoricalStock.unit',
          null,
        );

        const convertedRecipeQuantities = recipeProductionByDate || {};

        const formattedRecipe = {
          ...recipe,
          ...(convertedRecipeQuantities ? convertedRecipeQuantities : {}),
          category: recipe.category || i18next.t('GENERAL.NONE_VALUE'),
          totalConsumption: convertEntityQuantityToInpulseUnit(totalConsumption, recipe.unit),
          yesterdayStock: recipeRealStockValue || recipeTheoreticalStockValue,
          totalProduction: convertedRecipeQuantities
            ? sum(Object.values(convertedRecipeQuantities))
            : null,
        };

        acc.push(formattedRecipe);
      }

      return acc;
    }, []);

    const preparationToExport = preparations.map((preparation) => {
      const totalConsumption = get(consumptionByEntityId, `${preparation.id}.total`, null);

      const preparationProductionByDate = productionQtyByEntityIdAndDate[preparation.id];

      const convertedPreparationQuantities = preparationProductionByDate
        ? Object.entries(preparationProductionByDate).reduce((acc, [key, value]) => {
            acc[key] = convertEntityQuantityToInpulseUnit(value, preparation.unit);

            return acc;
          }, {})
        : {};

      const formattedPreparation = {
        ...preparation,
        ...(convertedPreparationQuantities ? convertedPreparationQuantities : {}),
        totalConsumption: convertEntityQuantityToInpulseUnit(totalConsumption, preparation.unit),
        totalProduction: convertedPreparationQuantities
          ? sum(Object.values(convertedPreparationQuantities))
          : null,
      };

      return formattedPreparation;
    });

    const ingredientToExport = ingredients.map((ingredient) => {
      const totalConsumption = get(consumptionByEntityId, `${ingredient.id}.total`, null);

      const ingredientProductionByDate = productionQtyByEntityIdAndDate[ingredient.id];

      const convertedIngredientQuantities = ingredientProductionByDate
        ? Object.entries(ingredientProductionByDate).reduce((acc, [key, value]) => {
            acc[key] = convertEntityQuantityToInpulseUnit(value, ingredient.unit);

            return acc;
          }, {})
        : {};

      const formattedIngredient = {
        ...ingredient,
        ...(convertedIngredientQuantities ? convertedIngredientQuantities : {}),
        category: ingredient.category || i18next.t('GENERAL.NONE_VALUE'),
        totalConsumption: convertEntityQuantityToInpulseUnit(totalConsumption, ingredient.unit),
        totalProduction: convertedIngredientQuantities
          ? sum(Object.values(convertedIngredientQuantities))
          : null,
      };

      return formattedIngredient;
    });

    const selectedStoresName = selectedStores.map(({ name }) => name).join(',');

    const metaData = {
      selectedCentralKitchen,
      selectedStoresName,
      userLanguageCode,
      productionStartDate: productionPeriodPickerState.startDate.format(
        DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
      ),
      productionEndDate: productionPeriodPickerState.endDate.format(
        DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
      ),
      consumptionStartDate: !!consumptionPeriodPickerState.startDate
        ? consumptionPeriodPickerState.startDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY)
        : null,
      consumptionEndDate: !!consumptionPeriodPickerState.endDate
        ? consumptionPeriodPickerState.endDate.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY)
        : null,
    };

    exportProductionPlanning(
      recipesToExport,
      preparationToExport,
      ingredientToExport,
      metaData,
      currency,
    );
  };

  const getDetailPanelHeight = () => 'auto';

  /** From MUI Datagrid documentation */
  const handleCellClick = useCallback((params, event) => {
    const { id, field } = params;

    if (!params.isEditable) {
      return;
    }

    // Ignore portal
    if (event.target.nodeType === 1 && !event.currentTarget.contains(event.target)) {
      return;
    }

    setCellModesModel((prevModel) => ({
      // Revert the mode of the other cells from other rows
      ...Object.keys(prevModel).reduce(
        (acc, id) => ({
          ...acc,
          [id]: Object.keys(prevModel[id]).reduce(
            (acc2, field) => ({
              ...acc2,
              [field]: { mode: GridCellModes.View },
            }),
            {},
          ),
        }),
        {},
      ),
      [params.id]: {
        // Revert the mode of other cells in the same row
        ...Object.keys(prevModel[params.id] || {}).reduce(
          (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
          {},
        ),
        [params.field]: { mode: GridCellModes.Edit },
      },
    }));

    setCurrentEditingCell({ id, field });
  }, []);

  const handleCellEditStart = useCallback((params) => {
    const { id, field } = params;
    setCurrentEditingCell({ id, field });
  }, []);

  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  const CustomMenuIcon = () => (
    <IconButton>
      <HeaderIcon src={CARRET_FULL_DOWN} />
    </IconButton>
  );

  const handleUpdateColumn = (productionDate, isErasing) => {
    if (!selectedRecipeIds.length) {
      return;
    }

    const replacingByZero = isErasing || isEmpty(consumptionByEntityId);

    const productionPlanningsToBeUpdated = [];
    const recipeNewQuantitiesByRecipeId = {};

    let updatedProductionQuantity = 0;

    const updatedPayload = payload.map((recipe) => {
      if (!selectedRecipeIds.includes(recipe.id) || !recipe.active) {
        return recipe;
      }

      if (!replacingByZero) {
        const recipeConsumption = get(consumptionByEntityId, `${recipe.id}.total`, 0);
        const convertedConsumption = convertEntityQuantityToInpulseUnit(
          recipeConsumption,
          recipe.unit,
        );

        updatedProductionQuantity = convertedConsumption || 0;
      }

      const oldTotal = sum(Object.values(productionQtyByEntityIdAndDate[recipe.id] || {}));

      // Having an already planned quantity is not guaranteed.
      const oldQuantityProduction = get(
        productionQtyByEntityIdAndDate,
        `${recipe.id}.${productionDate}`,
        0,
      );

      const diff = updatedProductionQuantity - oldQuantityProduction;

      const updatedRow = {
        ...recipe,
        ...productionQtyByEntityIdAndDate[recipe.id],
        [productionDate]: updatedProductionQuantity,
        total: oldTotal + diff,
      };

      // Format data for upsert by batch
      productionPlanningsToBeUpdated.push({
        storeId: selectedCentralKitchen.id,
        productionDate,
        entityId: recipe.id,
        quantity: round(updatedProductionQuantity, 2),
        userId: user.id,
      });

      recipeNewQuantitiesByRecipeId[recipe.id] = updatedProductionQuantity;

      return updatedRow;
    });

    apiRef.current.updateRows(updatedPayload);

    handleUpsertProductionPlanningByBatch(
      productionPlanningsToBeUpdated,
      recipeNewQuantitiesByRecipeId,
      productionDate,
    );
  };

  return (
    <Container>
      <NavigationBreadCrumb featurePath={path} />
      <ContentContainer>
        <Header
          canEditProductionPlanning={canEditProductionPlanning(authorizedActions)}
          centralKitchens={centralKitchens}
          clientStoreNameTranslation={clientStoreNameTranslation}
          consumptionPeriodPickerState={consumptionPeriodPickerState}
          handleExport={handleExport}
          isLoading={isLoadingStores}
          prevHorizon={prevHorizon}
          productionPeriodPickerState={productionPeriodPickerState}
          selectedCentralKitchen={selectedCentralKitchen}
          selectedStores={selectedStores}
          setSelectedCentralKitchen={setSelectedCentralKitchen}
          setSelectedStores={setSelectedStores}
          stores={stores}
        />
        <Box
          sx={{ borderBottom: 1, borderColor: 'divider', margin: '24px 0px', minWidth: '886px' }}
        >
          <Tabs
            aria-label="basic tabs example"
            value={selectedTabIndex}
            onChange={(_, value) => setSelectedTabIndex(value)}
          >
            <Tab
              aria-controls={`simple-tabpanel-0`}
              id={`simple-tab-0`}
              label={i18next.t('GENERAL.RECIPE_PLURAL')}
            />
            <Tab
              aria-controls={`simple-tabpanel-1`}
              id={`simple-tab-1`}
              label={i18next.t('PRODUCTION.CENTRAL_KITCHEN.PREPARATIONS')}
            />
            <Tab
              aria-controls={`simple-tabpanel-2`}
              id={`simple-tab-2`}
              label={i18next.t('GENERAL.INGREDIENT_PLURAL')}
            />
          </Tabs>
        </Box>
        <DataGridPro
          apiRef={apiRef}
          cellModesModel={cellModesModel}
          checkboxSelection={
            canEditRecipeProduction && selectedTabIndex === PRODUCTION_PLANNING_TAB_INDEXES.RECIPES
          }
          columns={columns[selectedTabIndex] || []}
          disableColumnFilter={selectedTabIndex !== PRODUCTION_PLANNING_TAB_INDEXES.RECIPES}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={getDetailPanelHeight}
          id={`simple-tabpanel-${selectedTabIndex}`}
          initialState={{
            pinnedColumns: {
              left: [
                GRID_DETAIL_PANEL_TOGGLE_FIELD,
                GRID_CHECKBOX_SELECTION_FIELD,
                'name',
                'category',
                'yesterdayStock',
                'consumption',
              ],
              right: ['total'],
            },
            sorting: {
              sortModel: [
                { field: 'category', sort: 'asc' },
                { field: 'name', sort: 'asc' },
              ],
            },
          }}
          isCellEditable={({ row }) => row.active}
          loading={isLoadingProductionPlanning || isLoadingRecipes}
          localeText={
            (DATAGRID_LOCALES[user.lnkLanguageAccountrel.code] || DATAGRID_LOCALES['fr']).components
              .MuiDataGrid.defaultProps.localeText
          }
          processRowUpdate={processRowUpdate}
          rows={isLoadingRecipes || isLoadingProductionPlanning ? [] : payload}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
            },
            columnMenu: {
              handleUpdateColumn,
            },
          }}
          slots={{
            toolbar: GridToolbar,
            noRowsOverlay: CustomDataGridNoRowOverlay,
            columnMenu: ColumnMenu,
            columnMenuIcon: CustomMenuIcon,
          }}
          sx={{
            maxHeight: 'calc(100% - 48px)',
            height: 'auto',
            minWidth: '886px',
            marginBottom: '24px',
          }}
          autosizeOnMount
          disableColumnResize
          disableColumnSelector
          disableDensitySelector
          disableRowSelectionOnClick
          hideFooter
          ignoreDiacritics
          showCellVerticalBorder
          showColumnVerticalBorder
          onCellClick={handleCellClick}
          onCellEditStart={handleCellEditStart}
          onCellModesModelChange={handleCellModesModelChange}
          onRowSelectionModelChange={(rowSelectionModel) => {
            setSelectedRecipeIds(rowSelectionModel);
          }}
        />
      </ContentContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
  client: getClientInfo(state.baseReducer.user),
  centralKitchens: getCentralKitchenStores(state.baseReducer.activeStores),
  prevHorizon: getPrevHorizon(state.baseReducer.userRights),
  productionQtyByEntityIdAndDate:
    state.centralKitchenProductionPlanningReducer.productionQtyByEntityIdAndDate,
  user: state.baseReducer.user,
  authorizedActions: getAuthorizedActions(
    state.baseReducer.userRights,
    '/central-kitchen-production/planning',
  ),
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  initializeProductionPlanningValues: (productionPlanningValues) => {
    dispatch(initializeProductionPlanningValues(productionPlanningValues));
  },
  productionPlanningUpdateQuantity: (entityId, date, quantity) => {
    dispatch(productionPlanningUpdateQuantity(entityId, date, quantity));
  },
  productionPlanningUpdateQuantityByBatch: (updatedProductionQuantitiesByEntityIdAndDate) => {
    dispatch(productionPlanningUpdateQuantityByBatch(updatedProductionQuantitiesByEntityIdAndDate));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CentralKitchenProductionPlanning);
