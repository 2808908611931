import PropTypes from 'prop-types';
import React from 'react';

import { ADMIN_FEATURE_PATHS } from '../../../commons/utils/featurePaths';
import { ENUM_MODULE_NAME } from '../../../commons/utils/features';

import { ModuleContainer } from '../../ModuleContainer';

export const AdminCashierContainer = (props) => {
  const { userRights, history } = props;
  return (
    <ModuleContainer
      history={history}
      moduleFeaturePaths={[
        ADMIN_FEATURE_PATHS.ADMIN_CASHIER_CASHIER_PRODUCTS,
        ADMIN_FEATURE_PATHS.ADMIN_CASHIER_CASHIER_PRODUCTS_MAPPING_WITH_PRODUCTS,
        ADMIN_FEATURE_PATHS.ADMIN_CASHIER_TRANSACTIONS,
        ADMIN_FEATURE_PATHS.ADMIN_CASHIER_FTP_DEBUG,
      ]}
      moduleName={ENUM_MODULE_NAME.ADMIN}
      userRights={userRights}
    />
  );
};

AdminCashierContainer.propTypes = {
  userRights: PropTypes.array,
  history: PropTypes.any, // react-router-dom's route prop
  moduleName: PropTypes.oneOf(Object.values(ENUM_MODULE_NAME)),
  moduleFeaturePaths: PropTypes.arrayOf(PropTypes.string),
};

// ===== Module features for easy import in ClientContainer =====

import CashierProducts from './cashierProducts';
import FtpDebug from './ftpDebug';
import MappingCashierProductsProducts from './cashierProducts/mappingCashierProductsProducts';
import Transactions from './transactions';

const AdminCashierModule = {
  AdminCashierContainer,
  CashierProducts,
  MappingCashierProductsProducts,
  Transactions,
  FtpDebug,
};

export default AdminCashierModule;
