import axios from '../core/http';

import { APIcaller } from './APIcaller';
import { config } from '../config';
const baseApiUrl = config.baseApiUrl;

const getSupplierProductsOfSupplierAndStore = (supplierId, storeId, startDate) =>
  APIcaller.apiGet(
    `/admin/stores/${storeId}/suppliers/${supplierId}/supplierProductsCustom?startDate=${startDate}`,
  );

function getSupplierProductsForInventories(store_id, inventories) {
  const supplierProductIds = inventories.map(({ supplierProductId }) => supplierProductId);

  return axios
    .post(`${baseApiUrl}/admin/supplier-products/list`, {
      supplierProductIds,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function getSupplierProductsOfSuppliers(suppliers, skip, limit, where) {
  const filter = {
    where: {
      supplierId: { inq: suppliers },
      ...where,
    },
    limit: limit,
    skip: skip,
    order: 'name ASC',
    include: ['lnkSupplierSupplierproductrel', 'lnkEntitySupplierproductrel'],
  };
  return axios
    .get(baseApiUrl + '/SupplierProducts/', {
      params: {
        filter,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function getSupplierProducts(filter) {
  return axios
    .get(baseApiUrl + '/supplierProducts', {
      params: {
        filter,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

// GET SINGLE SUPPLIERPRODUCT
function getSingleSupplierProduct(supplierProductId) {
  return APIcaller.apiGet(`/admin/supplier-products/${supplierProductId}`);
}

function setSupplierProductsToActive(supplierProductIds, storeSupplierProductMappings) {
  return axios
    .patch(
      baseApiUrl + '/admin/supplierProducts/active',
      {
        supplierProductIds,
        storeSupplierProductMappings,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

/**
 *
 * @param {Object} supplierProduct { id: <uuid>, price: <number> } Which SP to enable
 * @param {Uuid} entityId Which entity to map to the SP
 * @param {Object[]} storeSupplierProductMappings [ { storeId: <uuid>, available: <boolean>, hasStock: <boolean>, hasDlc: <boolean>}] The SSPM to create
 * @returns
 */
const enableSupplierProduct = async (supplierProduct, entityId, storeSupplierProductMappings) => {
  const { data } = await axios.patch(
    `${baseApiUrl}/admin/supplier-products/${supplierProduct.id}/enable`,
    {
      supplierProduct,
      entityId,
      storeSupplierProductMappings,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    },
  );

  return data;
};

function disableSupplierProducts(supplierProductIds) {
  return axios
    .patch(
      baseApiUrl + '/admin/supplierProducts/disable',
      {
        supplierProductIds,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function createSupplierProduct(supplierProduct, mappings, customPrices) {
  return axios
    .post(`${baseApiUrl}/admin/supplier-products`, {
      supplierProduct,
      mappings,
      customPrices,
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

function patchSupplierProductById(id, supplierProduct, mappings, customPrices) {
  return axios
    .patch(
      `${baseApiUrl}/admin/supplier-products/${id}`,
      { supplierProduct, mappings, customPrices },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

const checkSupplierProductData = (id) =>
  axios
    .get(`${baseApiUrl}/admin/supplier-products/${id}/check-data/`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });

const deleteByIds = (supplierProductIds) =>
  axios.delete(`${baseApiUrl}/admin/supplier-products`, {
    data: { supplierProductIds },
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('loopbackAccessToken'),
    },
  });

const getSupplierProductsGroupedByProperty = (clientId, groupBy, withPackagings = false) =>
  axios
    .get(`${baseApiUrl}/admin/supplier-products`, {
      params: {
        clientId,
        groupBy,
        withPackagings,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const updateSupplierProductColumnByBatch = (supplierProducts) =>
  axios
    .patch(
      `${baseApiUrl}/admin/supplier-products`,
      {
        supplierProductToUpdate: { updates: supplierProducts },
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const getSupplierProductsBatchCreationTemplate = (clientId) =>
  axios
    .get(`${baseApiUrl}/admin/clients/${clientId}/supplier-products/batchCreation/template`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });

const validateSupplierProductsBatchCreationTemplate = (clientId, supplierProducts) =>
  axios
    .post(
      `${baseApiUrl}/admin/clients/${clientId}/supplier-products/batchCreation/validate`,
      {
        ...supplierProducts,
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('loopbackAccessToken'),
        },
      },
      { responseType: 'blob' },
    )
    .then(function (response) {
      return { status: 'success', data: response.data };
    })
    .catch(function (error) {
      if (error.response) {
        return { status: 'error', data: error.response.data, statusCode: error.response.status };
      }

      throw error;
    });

const getExcelSupplierProductEditPriceVatRate = ({
  clientId,
  brandIds,
  entityIds,
  supplierIds,
  inventoryListTemplateIds,
  search,
  orderBy,
  orderType,
  queryParams,
  priceStartDate,
  categoryIds,
  subCategoryIds,
}) => {
  let requestUri = `/admin/clients/supplier-products/excel/price?`;

  if (orderBy != null) {
    requestUri = requestUri.concat('orderBy=', `${orderBy}&`);
  }

  if (orderType != null) {
    requestUri = requestUri.concat('orderType=', `${orderType}&`);
  }

  if (search) {
    requestUri = requestUri.concat('search=', `${search}&`);
  }

  if (priceStartDate) {
    requestUri = requestUri.concat('priceStartDate=', `${priceStartDate}&`);
  }

  requestUri = requestUri.replace(/(&)$/, '');

  if (queryParams) {
    requestUri = requestUri.concat(queryParams);
  }

  return APIcaller.apiPost(
    requestUri,
    {
      clientId,
      brandIds,
      entityIds,
      supplierIds,
      inventoryListTemplateIds,
      categoryIds,
      subCategoryIds,
    },
    'blob',
  );
};

const getWithPagination = ({
  clientId,
  brandIds,
  entityIds,
  inventoryListTemplateIds,
  storageAreaIds,
  supplierIds,
  search,
  skip,
  limit,
  orderBy,
  orderType,
  queryParams,
  withPackagings,
  spIdsToExclude,
  priceStartDate,
  categoryIds,
  subCategoryIds,
}) => {
  let requestUri = `/admin/supplier-products/paginated-list?`;

  if (skip != null) {
    requestUri = requestUri.concat('skip=', `${skip}&`);
  }

  if (limit != null) {
    requestUri = requestUri.concat('limit=', `${limit}&`);
  }

  if (orderBy != null) {
    requestUri = requestUri.concat('orderBy=', `${orderBy}&`);
  }

  if (orderType != null) {
    requestUri = requestUri.concat('orderType=', `${orderType}&`);
  }

  if (withPackagings != null) {
    requestUri = requestUri.concat('withPackagings=', `${withPackagings}&`);
  }

  if (search) {
    requestUri = requestUri.concat('search=', `${search}&`);
  }

  if (priceStartDate) {
    requestUri = requestUri.concat('priceStartDate=', `${priceStartDate}&`);
  }

  requestUri = requestUri.replace(/(&)$/, '');

  if (queryParams) {
    requestUri = requestUri.concat(queryParams);
  }

  return APIcaller.apiPost(requestUri, {
    clientId,
    brandIds,
    entityIds,
    inventoryListTemplateIds,
    storageAreaIds,
    supplierIds,
    spIdsToExclude,
    categoryIds,
    subCategoryIds,
  });
};

const updateBySupplierProductIds = (supplierProductIds, available, hasStock, hasDlc) =>
  APIcaller.apiPost('/admin/store-supplier-product-mappings/update-by-supplier-products', {
    supplierProductIds,
    hasStock,
    hasDlc,
    available,
  });

const createAvailabilitiesByBatch = (supplierProductIds, startDate, available) =>
  APIcaller.apiPost('/admin/store-supplier-product-mappings/schedule/create', {
    supplierProductIds,
    startDate,
    available,
  });

const deleteScheduledAvailabilitiesBySPIds = (supplierProductIds) =>
  APIcaller.apiDelete('/admin/store-supplier-product-mappings/schedule', {
    supplierProductIds,
  });

const deleteScheduledPricesBySPIds = (supplierProductIds) =>
  APIcaller.apiDelete('/admin/supplier-products/prices/schedule', {
    supplierProductIds,
  });

const updatePriceEditableAtReceptionByIds = (supplierProductIds, priceEditableAtReception) =>
  APIcaller.apiPost('/admin/supplier-products/price-editable-at-reception', {
    supplierProductIds,
    priceEditableAtReception,
  });

const getElligibleAutoMapping = (clientId, supplierIds) =>
  APIcaller.apiPost('/admin/supplier-products/auto-mapping-suggestions', {
    clientId,
    supplierIds,
  });

const assignEntityInBatch = (mappings) =>
  APIcaller.apiPost('/admin/supplier-products/assign-entity', {
    mappings,
  });

const patchCategoriesSubCategories = (supplierProductIds, itemId, itemType) =>
  APIcaller.apiPatch('/admin/supplier-products/categories-sub-categories', {
    supplierProductIds,
    itemId,
    itemType,
  });

export const supplierProduct = {
  getWithPagination,
  getSingleSupplierProduct,
  getSupplierProductsOfSupplierAndStore,
  getSupplierProductsForInventories,
  getSupplierProductsOfSuppliers,
  getSupplierProducts,
  setSupplierProductsToActive,
  enableSupplierProduct,
  disableSupplierProducts,
  createSupplierProduct,
  patchSupplierProductById,
  checkSupplierProductData,
  getSupplierProductsGroupedByProperty,
  updateSupplierProductColumnByBatch,
  getSupplierProductsBatchCreationTemplate,
  validateSupplierProductsBatchCreationTemplate,
  getExcelSupplierProductEditPriceVatRate,
  deleteByIds,
  updateBySupplierProductIds,
  createAvailabilitiesByBatch,
  deleteScheduledAvailabilitiesBySPIds,
  deleteScheduledPricesBySPIds,
  updatePriceEditableAtReceptionByIds,
  getElligibleAutoMapping,
  assignEntityInBatch,
  patchCategoriesSubCategories,
};
