import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useRef, useState } from 'react';

import {
  DEFAULT_QUERY_PARAMS,
  ENUM_QUERY_PARAMS,
  useListViewQueryParams,
} from '@hooks/useListViewQueryParams';

import { Button, ListView } from '@commons/utils/styledLibraryComponents';

import {
  closeGenericModal,
  openGenericModal,
  openModal,
  openSmallModal,
  refreshGenericModal,
} from '@actions/modal';
import { loading, loadingFailure, loadingSuccess } from '@actions/loading';
import {
  showConfirmationMessage,
  showErrorMessage,
  showSuccessMessage,
} from '@actions/messageconfirmation';

import {
  cancelReceptionOrderById,
  deleteOrderById,
  fetchOrdersOfStores,
  fetchOrdersStoresCount,
  updateOrderStatus,
  updatePreparationStatus,
} from './common/services';
import { getActions, getRowAction } from './common/actions';
import { getCentralKitchenPreparationColumns, getOrderColumns } from './common/columns';
import { sortArrayOfObjectsAlphabetically } from '@commons/utils/sorting';

import { Container } from './styledComponents';

import { brand as brandService } from '../../../services/brand';
import { group as groupService } from '../../../services/group';
import { storeSupplierProfileMapping as storeSupplierProfileMappingService } from '@services/storeSupplierProfileMapping';
import { supplier as supplierService } from '../../../services/supplier';
import accountService from '../../../services/account';
import clientService from '../../../services/client';

import DeepsightFiltersButton from '../components/FilterButton';
import OrderForm from './components/OrderForm/index';

import { GenericModalContainer } from '@commons/Modals/GenericModal/styledComponents';
import GenericModal from '../../../commons/Modals/GenericModal';

import EmptyState from '../../../commons/EmptyState';
import GeneralEmptyStateListView from '../../../commons/GeneralEmptyStateListView';

import centralService from '../../../services/central';
import ORDER_STATUS, { ORDER_PREPARATION_STATUS } from './components/OrderForm/constants/status';
import orderService from '../../../services/order';

import { getMarkAsSentConfirmationModalParams } from './common/modal';

import { getAuthorizedActions, getGenerateInvoice } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';
import { STANDARD_LISTVIEW_PADDING } from '@commons/constants/listViewProps';
import ConfirmationModal from '../components/ConfirmationModal';
import mixpanelUtils, { ENUM_EVENTS } from '../../../commons/utils/mixpanel';

import { canCreateDraftOrder } from '@selectors/actions/orderActions';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_CONFIRM_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { getCentralKitchenStores } from '@selectors/stores';

import { downloadFile, formatXlsFileToJson } from '@backoffice/utils';
import {
  exportOrdersImportErrors,
  formatOrderBatchCreationFile,
  getImportOrdersModalConfig,
  IMPORT_MODAL_STEPS,
} from '@orders/OrderList/common/utils/imports/batchImport';
import BatchModal from './components/Exports/BatchModal';
import exportConfig, { ExportTypes } from './common/utils/exports';

const ORDER_TYPE = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
};

const modalTitleByExportType = new Map([
  [ExportTypes.ANOMALIES, i18next.t('ORDERS.ORDERS.EXPORT_ANOMALIES_IN_PROGRESS')],
  [ExportTypes.CONTENT, i18next.t('ORDERS.ORDERS.EXPORT_CONTENTS_IN_PROGRESS')],
  [ExportTypes.LIST, i18next.t('ORDERS.ORDERS.EXPORT_IN_PROGRESS')],
  [ExportTypes.AGGREGATED, i18next.t('ORDERS.ORDERS.EXPORT_AGGREGATED_IN_PROGRESS')],
]);

export const handleNewOrder = (
  props,
  stores,
  reloadOrders,
  setIsRetrievingData,
  setShouldRetrieveData,
  setEdiSettingsErrorModalParams,
) => {
  const params = {
    component: OrderForm,
    orderButton: true,
    labelTopButton: i18next.t('GENERAL.LEAVE'),
    orderId: '',
    stores,
    storeName: stores.length > 0 ? stores[0].name : null,
    storeTimezone: stores.length > 0 ? stores[0].timezone : null,
    storeId: stores.length > 0 ? stores[0].id : null,
    reloadOrders: reloadOrders,
    displayStocks: props.displayStocks,
    user: props.user,
    categoriesOrder: props.categoriesOrder,
    constraints: props.constraints || [],
    send: props.send,
    canOrderWithMin: props.canOrderWithMin,
    deactivateBackgroundClick: true,
    setIsRetrievingData,
    setShouldRetrieveData,
    setEdiSettingsErrorModalParams,
  };
  props.openModal(params, true);
};

export const handleEditOrder = (
  order,
  props,
  reloadOrders,
  setIsRetrievingData,
  setShouldRetrieveData,
  setEdiSettingsErrorModalParams,
) => {
  const params = {
    component: OrderForm,
    orderButton: true,
    labelTopButton: i18next.t('GENERAL.LEAVE'),
    orderId: order.id,
    stores: props.stores,
    storeId: order.lnkStoreOrderrel.id,
    storeName: order.lnkStoreOrderrel.name,
    storeTimezone: order.lnkStoreOrderrel.timezone,
    order: order,
    reloadOrders: reloadOrders,
    displayStocks: props.displayStocks,
    user: props.user,
    categoriesOrder: props.categoriesOrder,
    constraints: props.constraints || [],
    send: props.send,
    canOrderWithMin: props.canOrderWithMin,
    deactivateBackgroundClick: true,
    setIsRetrievingData,
    setShouldRetrieveData,
    setEdiSettingsErrorModalParams,
  };
  props.openModal(params, true);
};

async function fetchBrandsOfClient(clientId, showMessage) {
  try {
    const brands = await brandService.getBrandsOfClient(clientId);

    return brands;
  } catch (err) {
    showMessage(i18next.t('HOME.ACTIVITY_REPORT_FETCH_BRANDS_FAILURE'), 'error');

    return [];
  }
}

const getLocationsOfAccount = async (accountId, showMessage) => {
  try {
    return await accountService.getLocationsOfAccount(accountId);
  } catch (err) {
    showMessage(i18next.t('HOME.ACTIVITY_REPORT_FETCH_LOCATIONS_FAILURE'), 'error');

    return [];
  }
};

async function fetchRetailersOfClient(clientId, showMessage) {
  try {
    const retailers = await clientService.getRetailersOfClient(clientId);

    return retailers;
  } catch (err) {
    showMessage(i18next.t('STOCKS.CURRENT_STOCKS.GET_RETAILERS_FAILURE'), 'error');

    return [];
  }
}

async function handleDeleteOrderById(orderIds, pageLoading, pageLoaded, showMessage) {
  pageLoading();

  try {
    const promises = [];
    orderIds.map((orderId) => {
      promises.push(deleteOrderById(orderId));
    });

    await Promise.all(promises);

    if (orderIds.length > 1) {
      showMessage(i18next.t('ORDERS.ORDERS.DELETE_SUCCESS_PLURAL'));
    } else {
      showMessage(i18next.t('ORDERS.ORDERS.DELETE_SUCCESS'));
    }
  } catch (err) {
    showMessage(i18next.t('ORDERS.ORDERS.DELETE_FAILURE'), 'error');
  } finally {
    pageLoaded();
  }
}

async function handleCancelReceptionOrderById(orderIds, pageLoading, pageLoaded, showMessage) {
  pageLoading();

  try {
    const promises = [];
    orderIds.map((orderId) => {
      promises.push(cancelReceptionOrderById(orderId));
    });

    await Promise.all(promises);

    if (orderIds.length > 1) {
      showMessage(i18next.t('ORDERS.ORDERS.CANCEL_RECEPTION_SUCCESS_PLURAL'));
    } else {
      showMessage(i18next.t('ORDERS.ORDERS.CANCEL_RECEPTION_SUCCESS'));
    }
  } catch (err) {
    showMessage(i18next.t('ORDERS.ORDERS.CANCEL_RECEPTION_FAILURE'), 'error');
  } finally {
    pageLoaded();
  }
}

async function handleDeleteSentOrderById(orderId, pageLoading, pageLoaded, showMessage) {
  pageLoading();

  try {
    await deleteOrderById(orderId);

    showMessage(i18next.t('ORDERS.ORDERS.DELETE_SUCCESS'));
  } catch (err) {
    showMessage(i18next.t('ORDERS.ORDERS.DELETE_FAILURE'), 'error');
  } finally {
    pageLoaded();
  }
}

async function handleFetchGroupsByStoreIds(storeIds, showMessage, setGroups) {
  const formattedGroups = {};

  try {
    const groupMappings = await groupService.getGroupsOfStores(storeIds);

    groupMappings.forEach((groupMapping) => {
      const groupId = groupMapping.lnkGroupStoregroupmappingrel.id;
      const groupName = groupMapping.lnkGroupStoregroupmappingrel.name;

      if (!formattedGroups[groupId]) {
        formattedGroups[groupId] = {
          groupId,
          storeIds: [],
          name: groupName,
          id: groupMapping.id,
        };
      }

      formattedGroups[groupId].storeIds.push(groupMapping.storeId);
    });
  } catch (err) {
    showMessage(i18next.t('HOME.ACTIVITY_REPORT_FETCH_GROUPS_FAILURE'), 'error');
  } finally {
    setGroups(Object.values(formattedGroups));
  }
}

async function fetchSuppliersMadeOnPastOrders(storeIds, showMessage) {
  try {
    const suppliers = await supplierService.getSuppliersFromHistoricalOrdersMadeOnStores(storeIds);

    return suppliers;
  } catch (err) {
    showMessage(i18next.t('ORDERS.BY_CATEGORY.FETCH_SUPPLIERS_FAILURE'), 'error');

    return [];
  }
}

export const Orders = (props) => {
  const {
    user,
    client: { clientId, hasMultipleBrands, storeName },
    stores,
    centralStores,
    showMessage,
    pageLoading,
    pageLoaded,
    authorizedActions,
    hasGenerateInvoiceProps,
    createOrdersXLS: hasCreateOrdersXlsProps,
    isForCentralKitchenPreparation = false,
  } = props;
  const accountId = _.get(user, 'id');

  const listViewRef = useRef();

  const [orders, onOrdersChange] = useState([]);
  const [totalOrdersCount, setTotalOrdersCount] = useState(0);
  const [ordersSelected, onOrdersSelectedChange] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isRetrievingData, setIsRetrievingData] = useState(false);
  const [shouldRetrieveData, setShouldRetrieveData] = useState(false);

  const [actions, onActionsChange] = useState([]);
  const [rowActions, onRowActionsChange] = useState([]);
  const dataColumns = isForCentralKitchenPreparation
    ? getCentralKitchenPreparationColumns(storeName)
    : getOrderColumns(storeName);

  const [queryParams, setQueryParams] = useState({
    params: isForCentralKitchenPreparation
      ? '&status={"gte":["3"]}&preparationStatus={"neq":[null]}'
      : '',
    selectedFilters: {},
  });

  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);

  const [brands, setBrands] = useState(null);
  const [selectedBrands, setSelectedBrands] = useState([]);

  const [retailers, setRetailers] = useState([]);
  const [selectedRetailers, setSelectedRetailers] = useState([]);

  const [suppliers, setSuppliers] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);

  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);

  const [selectedStores, setSelectedStores] = useState([]);

  const [applyFilters, setApplyFilters] = useState(true);
  const [advancedFilters, setAdvancedFilters] = useState(null);
  const [filters, setFilters] = useState(null);

  const columnsFilterList = dataColumns.filter((column) =>
    ['date', 'string', 'numeric'].includes(column.filterType),
  );

  const [hasPastOrders, onHasPastOrders] = useState(null);

  const [listViewQueryParams, setListViewQueryParams] = useListViewQueryParams(false);

  const [ediSettingsErrorModalParams, setEdiSettingsErrorModalParams] = useState(null);

  const [selectedFile, setSelectedFile] = useState(null);

  const fetchSuppliersFromPreviousOrders = async (storeIds) =>
    fetchSuppliersMadeOnPastOrders(storeIds, showMessage);

  const reloadOrders = async () => {
    const storeIds = selectedStores.map((store) => store.id);
    const centralStoreIds = centralStores.map(({ id }) => id);

    const supplierIds = suppliers.map(({ id }) => id);

    // Check whether there is orders to fetch
    const ordersCount = isForCentralKitchenPreparation
      ? await fetchOrdersStoresCount({
          storeIds,
          supplierIds,
          status: 3,
          isForCentralKitchenPreparation,
        })
      : await fetchOrdersStoresCount({ storeIds, isForCentralKitchenPreparation });

    // Handle cases where request could fail but should not interfere with normal behaviour
    onHasPastOrders(ordersCount === null || ordersCount > 0);

    if (ordersCount === 0) {
      return;
    }

    const areAllSupplierSelected =
      suppliers.length === selectedSuppliers.length && suppliers.length > 0;

    const suppliersOfStores = isForCentralKitchenPreparation
      ? await centralService.getkitchenSupplierByStoreIds(centralStoreIds)
      : await fetchSuppliersMadeOnPastOrders(storeIds, showMessage);

    // If there is no difference between recently fetched suppliers & the actual list of suppliers
    if (
      suppliersOfStores.every(({ id }) => suppliers.some((supplier) => supplier.id === id)) &&
      selectedSuppliers.every(({ id }) =>
        suppliersOfStores.some((supplier) => supplier.id === id),
      ) &&
      suppliersOfStores.length === suppliers.length
    ) {
      return loadOrders();
    }

    const sortedSuppliers = sortArrayOfObjectsAlphabetically(suppliersOfStores, ['name']);

    setSuppliers(sortedSuppliers);
    setSelectedSuppliers(sortedSuppliers);

    const updatedFilters = _.cloneDeep(filters);

    _.set(updatedFilters, 'suppliers.list', sortedSuppliers);
    _.set(updatedFilters, 'suppliers.activeList', sortedSuppliers);

    // If all suppliers are selected, then replace list in both actual component & Filter component
    if (areAllSupplierSelected) {
      _.set(updatedFilters, 'suppliers.selectedSuppliers', sortedSuppliers);

      setFilters(updatedFilters);

      return;
    }

    setFilters(updatedFilters);

    return loadOrders(sortedSuppliers);
  };

  const loadOrders = async (updatedListOfSuppliers) => {
    setIsRetrievingData(true);

    try {
      const storeIds = selectedStores.map((store) => store.id);
      const supplierIds = (updatedListOfSuppliers || selectedSuppliers).map(
        (supplier) => supplier.id,
      );

      const formattedMaxPerPage =
        Number.isInteger(listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE]) &&
        listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE] > 0
          ? listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE]
          : DEFAULT_QUERY_PARAMS.MAX_PER_PAGE;

      const formattedCurrentPage =
        listViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE] > 0
          ? (listViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE] - 1) *
            (listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE] ||
              DEFAULT_QUERY_PARAMS.MAX_PER_PAGE)
          : (DEFAULT_QUERY_PARAMS.CURRENT_PAGE - 1) * DEFAULT_QUERY_PARAMS.MAX_PER_PAGE;

      const columnPropertyKeys = dataColumns.map((column) => column.propertyKey);
      const formattedOrderBy = columnPropertyKeys.includes(
        listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY],
      )
        ? listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY]
        : columnPropertyKeys[0];

      const formattedOrderType =
        listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE] === ORDER_TYPE.ASCENDING ||
        listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE] === ORDER_TYPE.DESCENDING
          ? listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE]
          : DEFAULT_QUERY_PARAMS.ORDER_TYPE;

      await fetchOrdersOfStores(
        storeIds,
        supplierIds,
        onOrdersChange,
        setTotalOrdersCount,
        listViewQueryParams[ENUM_QUERY_PARAMS.SEARCH] || DEFAULT_QUERY_PARAMS.SEARCH,
        formattedCurrentPage,
        formattedMaxPerPage,
        formattedOrderBy,
        formattedOrderType,
        queryParams.params,
      );

      props.pageLoaded();
    } catch {
      props.showMessage(i18next.t('ORDERS.ORDERS.LOADING_FAILURE'), 'error');

      props.pageDidNotLoad();
    } finally {
      setIsRetrievingData(false);
    }
  };

  const markOrdersAsSent = async (orders, suppliers) => {
    props.pageLoading();
    try {
      await updateOrderStatus(orders, ORDER_STATUS.SENT, suppliers);

      props.showMessage(i18next.t('ORDERS.ORDERS.STATUS_CHANGE_SUCCESS'));

      // IPP-4378 - code that might be deleted
      mixpanelUtils.sendMetric(ENUM_EVENTS.SET_ORDER_STATUS_TO_SENT_WITHOUT_SENDING_IT);

      await reloadOrders();
    } catch (err) {
      props.showMessage(i18next.t('ORDERS.ORDERS.STATUS_CHANGE_ERROR'), 'error');
    } finally {
      props.pageLoaded();
    }
  };

  const handleMarkOrdersAsSent = (ordersSelection) => {
    const params = getMarkAsSentConfirmationModalParams({
      markOrdersAsSent,
      ordersSelection,
      suppliers,
    });
    props.openGenericModal(params);
  };

  const markAsDraft = async (orderIds) => {
    props.pageLoading();
    try {
      await orderService.markOrdersAsDraft(orderIds);
      props.showMessage(i18next.t('ORDERS.ORDERS.CHANGE_STATUS_TO_DRAFT_SUCCESS'));
      await reloadOrders();
    } catch (err) {
      props.showMessage(i18next.t('ORDERS.ORDERS.CHANGE_STATUS_TO_DRAFT_FAILURE'), 'error');
    } finally {
      props.pageLoaded();
    }
  };

  const markAsPreparing = async (orders) => {
    props.pageLoading();
    try {
      await updatePreparationStatus(orders, ORDER_PREPARATION_STATUS.PREPARING);
      props.showMessage(
        i18next.t('ORDERS.ORDERS.CHANGE_STATUS_TO_PREPARING', { count: orders.length }),
      );

      await reloadOrders();
    } catch (err) {
      props.showErrorMessage(
        i18next.t('ORDERS.ORDERS.CHANGE_STATUS_TO_PREPARING_FAILURE', { count: orders.length }),
      );
    } finally {
      props.pageLoaded();
    }
  };

  const markAsPrepared = async (orders) => {
    props.pageLoading();
    try {
      await updatePreparationStatus(orders, ORDER_PREPARATION_STATUS.COMPLIANT_PREPARED);
      props.showMessage(
        i18next.t('ORDERS.ORDERS.CHANGE_STATUS_TO_PREPARED', { count: orders.length }),
      );
      await reloadOrders();
    } catch (err) {
      props.showErrorMessage(
        i18next.t('ORDERS.ORDERS.CHANGE_STATUS_TO_PREPARED_FAILURE', { count: orders.length }),
      );
    } finally {
      props.pageLoaded();
    }
  };

  const handleConfirmationMarkAsDraft = (orders) => {
    const orderIds = orders.map((order) => order.id);

    const params = {
      type: 'warning',
      width: '542px',
      height: 'auto',
      icon: '/images/inpulse/warning-white-small.svg',
      title: i18next.t('ORDERS.ORDERS.CHANGE_STATUS_TO_DRAFT_CONFIRMATION_MODAL_TITLE'),
      component: ConfirmationModal,
      data: {
        content: i18next.t('ORDERS.ORDERS.CHANGE_STATUS_TO_DRAFT_CONFIRMATION_MODAL_CONTENT'),
      },
      actions: [
        {
          key: 0,
          color: 'inpulse-outline',
          label: i18next.t('GENERAL.CANCEL'),
          icon: '/images/inpulse/close-black-small.svg',
        },
        {
          key: 1,
          color: 'inpulse-default',
          label: i18next.t('GENERAL.VALIDATE'),
          icon: '/images/inpulse/check-white-small.svg',
          handleClick: async () => {
            await markAsDraft(orderIds);
          },
        },
      ],
    };
    props.openGenericModal(params);
  };

  const handleDeleteOrder = (orders) => {
    const orderIds = orders.map((order) => order.id);

    const params = {
      type: 'warning',
      width: '542px',
      height: 'auto',
      icon: '/images/inpulse/warning-white-small.svg',
      title: i18next.t('ORDERS.ORDERS.DELETE_CONFIRMATION_MODAL_TITLE'),
      component: ConfirmationModal,
      data: {
        content:
          orders.length > 1
            ? i18next.t('ORDERS.ORDERS.DELETE_CONFIRMATION_MODAL_CONTENT_PLURAL')
            : i18next.t('ORDERS.ORDERS.DELETE_CONFIRMATION_MODAL_CONTENT'),
      },
      actions: [
        {
          key: 0,
          color: 'inpulse-outline',
          label: i18next.t('GENERAL.CANCEL'),
          icon: '/images/inpulse/close-black-small.svg',
        },
        {
          key: 1,
          color: 'inpulse-default',
          label: i18next.t('GENERAL.VALIDATE'),
          icon: '/images/inpulse/check-white-small.svg',
          handleClick: async () => {
            await handleDeleteOrderById(
              orderIds,
              props.pageLoading,
              props.pageLoaded,
              props.showMessage,
            );

            await reloadOrders();
          },
        },
      ],
    };
    props.openGenericModal(params);
  };

  const handleDeleteSentOrder = (order) => {
    const params = {
      type: 'warning',
      width: '542px',
      height: 'auto',
      icon: '/images/inpulse/warning-white-small.svg',
      title: i18next.t('ORDERS.ORDERS.DELETE_CONFIRMATION_MODAL_TITLE_DELETE_SENT_ORDER'),
      component: ConfirmationModal,
      data: {
        content: i18next.t('ORDERS.ORDERS.DELETE_CONFIRMATION_MODAL_CONTENT_DELETE_SENT_ORDER'),
      },
      actions: [
        {
          key: 0,
          color: 'inpulse-outline',
          label: i18next.t('GENERAL.CANCEL'),
          icon: '/images/inpulse/close-black-small.svg',
        },
        {
          key: 1,
          color: 'inpulse-default',
          label: i18next.t('GENERAL.VALIDATE'),
          icon: '/images/inpulse/check-white-small.svg',
          handleClick: async () => {
            await handleDeleteSentOrderById(
              order.id,
              props.pageLoading,
              props.pageLoaded,
              props.showMessage,
            );

            await reloadOrders();
          },
        },
      ],
    };
    props.openGenericModal(params);
  };

  const handleCancelReceptionOrder = (orders) => {
    const orderIds = orders.map((order) => order.id);

    const checkNonCompliantOrder = orders.some((order) =>
      [ORDER_STATUS.NON_COMPLIANT, ORDER_STATUS.CREDIT_REQUEST_PROCESSED].includes(
        parseInt(order.status),
      ),
    );

    const params = {
      type: 'warning',
      width: '542px',
      height: 'auto',
      icon: '/images/inpulse/pen-white-small.svg',
      title: i18next.t('ORDERS.ORDERS.ACTION_MARK_AS_RECEPTION'),
      component: ConfirmationModal,
      data: {
        content: i18next.t(
          checkNonCompliantOrder
            ? 'ORDERS.ORDERS.CANCEL_RECEPTION_MODAL_CONTENT_NON_COMPLIANT'
            : 'ORDERS.ORDERS.CANCEL_RECEPTION_MODAL_CONTENT',
        ),
      },
      actions: [
        {
          key: 0,
          color: 'inpulse-outline',
          label: i18next.t('GENERAL.CANCEL'),
          icon: '/images/inpulse/close-black-small.svg',
        },
        {
          key: 1,
          color: 'inpulse-default',
          label: i18next.t('GENERAL.SAVE'),
          icon: '/images/inpulse/save-white-small.svg',
          handleClick: async () => {
            await handleCancelReceptionOrderById(
              orderIds,
              props.pageLoading,
              props.pageLoaded,
              props.showMessage,
            );

            await reloadOrders();
          },
        },
      ],
    };
    props.openGenericModal(params);
  };

  const handleOrdersExport = async (type, selectedOrders = []) => {
    const selectedStoreIds = selectedStores.map(({ id }) => id);
    const selectedSupplierIds = selectedSuppliers.map(({ id }) => id);

    const anomalyStatuses = [ORDER_STATUS.NON_COMPLIANT, ORDER_STATUS.CREDIT_REQUEST_PROCESSED];

    // If there are no orders with anomaly in the list for the given filters, do not export
    const anomalyOrdersInList = orders.filter(({ status }) =>
      anomalyStatuses.includes(parseInt(status)),
    );
    if (type === ExportTypes.ANOMALIES && anomalyOrdersInList.length === 0) {
      props.showMessage(i18next.t('ORDERS.ORDERS.LIST_EXPORT_ANOMALY_NO_CONTENT'), 'error');
      return;
    }

    // Only filter orders with anomalies for selected orders
    const filteredOrders =
      type === ExportTypes.ANOMALIES
        ? selectedOrders.filter(({ status }) => anomalyStatuses.includes(parseInt(status)))
        : selectedOrders;

    const selectedOrderIds = filteredOrders.length ? filteredOrders.map(({ id }) => id) : null;

    props.openModalExportInfo({
      request: {
        getByIds: selectedOrderIds
          ? () => exportConfig[type].request.getByIds(selectedOrderIds)
          : null,
        getPaginated: (skip, limit) =>
          exportConfig[type].request.getPaginated(
            selectedStoreIds,
            selectedSupplierIds,
            listViewQueryParams[ENUM_QUERY_PARAMS.SEARCH] || DEFAULT_QUERY_PARAMS.SEARCH,
            skip,
            limit,
            queryParams.params,
          ),
        totalCount: selectedOrderIds ? selectedOrderIds.length : null,
      },
      config: {
        excel: exportConfig[type].config,
        isCentralExport: isForCentralKitchenPreparation,
        titleModal: modalTitleByExportType.get(type),
      },
      component: BatchModal,
    });
  };

  const setDefaultQueryParam = () => {
    if (!listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE]) {
      setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE]('desc');
    }

    if (!listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY]) {
      setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY](
        isForCentralKitchenPreparation ? 'startOrderDate' : 'orderDate',
      );
    }
  };

  const setCreditRequestProcessed = async (orderIds) => {
    props.pageLoading();
    try {
      await orderService.setCreditRequestProcessed(clientId, orderIds);
      props.showMessage(
        i18next.t('ORDERS.ORDERS.SET_CREDIT_REQUEST_PROCESSED_SUCCESS', {
          count: orderIds.length,
        }),
      );
      await reloadOrders();
    } catch (err) {
      props.showErrorMessage(
        i18next.t('ORDERS.ORDERS.SET_CREDIT_REQUEST_PROCESSED_FAILURE', {
          count: orderIds.length,
        }),
      );
    } finally {
      props.pageLoaded();
    }
  };

  const handleConfirmationSetCreditRequestProcessed = (orders) => {
    const orderIds = orders.map((order) => order.id);

    const params = {
      type: 'warning',
      width: '542px',
      height: 'auto',
      icon: '/images/inpulse/warning-white-small.svg',
      title: i18next.t('ORDERS.ORDERS.SET_CREDIT_REQUEST_PROCESSED_MODAL_TITLE'),
      component: ConfirmationModal,
      data: {
        content: i18next.t('ORDERS.ORDERS.SET_CREDIT_REQUEST_PROCESSED_MODAL_CONTENT', {
          count: orderIds.length,
        }),
      },
      actions: [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          ...GENERIC_MODAL_CONFIRM_BUTTON(),
          handleClick: async () => {
            await setCreditRequestProcessed(orderIds);
          },
        },
      ],
    };
    props.openGenericModal(params);
  };

  const generateInvoice = async (order) => {
    props.pageLoading();

    try {
      const areStoreInformationComplete = await orderService.checkCompaniesInformation(
        order.storeId,
        order.supplierId,
      );

      if (!areStoreInformationComplete) {
        props.showErrorMessage(i18next.t('ORDERS.ORDERS.SET_ORDER_TO_INVOICED_PARAMS_FAILURE'));
        return;
      }

      await orderService.setOrderToInvoiced(order.id);
      props.showMessage(i18next.t('ORDERS.ORDERS.SET_ORDER_TO_INVOICED_SUCCESS'));
      await reloadOrders();
    } catch (err) {
      props.showErrorMessage(i18next.t('ORDERS.ORDERS.SET_ORDER_TO_INVOICED_FAILURE'));
    } finally {
      props.pageLoaded();
    }
  };

  const handleConfirmationGenerateInvoice = (order) => {
    const params = {
      type: 'warning',
      width: '542px',
      height: 'auto',
      icon: '/images/inpulse/warning-white-small.svg',
      title: i18next.t('ORDERS.ORDERS.ACTION_GENERATE_PREPARATION_INVOICE'),
      component: ConfirmationModal,
      data: {
        content: i18next.t('ORDERS.ORDERS.SET_GENERATE_INVOICE_MODAL_CONTENT'),
      },
      actions: [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          ...GENERIC_MODAL_CONFIRM_BUTTON(),
          handleClick: () => generateInvoice(order),
        },
      ],
    };
    props.openGenericModal(params);
  };

  const handleDuplicateOrder = async (order) => {
    const NO_MAPPING_ERROR_CODE = 'no_mappings';

    try {
      pageLoading();

      const mappings =
        await storeSupplierProfileMappingService.getStoreSupplierProfileMappingsOfSupplier(
          order.lnkSupplierOrderrel.id,
        );

      const associatedStore = mappings.find(({ storeId }) => storeId === order.lnkStoreOrderrel.id);

      if (!associatedStore) {
        throw new Error(NO_MAPPING_ERROR_CODE);
      }
    } catch (err) {
      if (err.message === NO_MAPPING_ERROR_CODE) {
        props.showErrorMessage(
          i18next.t('ORDERS.ORDERS.DUPLICATE_ERROR_NO_MAPPING_FOR_STORE', {
            storeName: order.storeName,
            supplierName: order.supplierName,
          }),
        );
        return;
      }

      props.showErrorMessage(i18next.t('ADMIN.SUPPLIERS.GET_SUPPLIER_PROFILE_ERROR'));
      return;
    } finally {
      pageLoaded();
    }

    const params = {
      // Miscellaneous
      component: OrderForm,
      orderButton: true,
      labelTopButton: i18next.t('GENERAL.LEAVE'),
      deactivateBackgroundClick: true,
      isDuplicatingOrder: true,
      // Order
      order: {
        ...order,
        status: ORDER_STATUS.CREATION,
        preparationStatus: null,
        reference: '',
        orderDate: new Date().toISOString(),
      },
      orderId: order.id,
      storeId: order.lnkStoreOrderrel.id,
      storeName: order.lnkStoreOrderrel.name,
      storeTimezone: order.lnkStoreOrderrel.timezone,
      // Props
      user: props.user,
      stores: props.stores,
      displayStocks: props.displayStocks,
      categoriesOrder: props.categoriesOrder,
      constraints: props.constraints || [],
      send: props.send,
      canOrderWithMin: props.canOrderWithMin,
      // Handlers
      reloadOrders,
      // Setters
      setIsRetrievingData,
      setShouldRetrieveData,
      setEdiSettingsErrorModalParams,
    };

    props.openModal(params, true);
  };

  const getMultipleOrderCreationTemplate = async () => {
    pageLoading();
    try {
      const storeIds = stores.map(({ id }) => id);
      const result = await orderService.getOrderBatchCreationTemplate(clientId, storeIds);

      downloadFile(result, i18next.t('ORDERS.ORDERS.DOWNLOAD_TEMPLATE_FOR_IMPORT_FILENAME'));
    } catch {
      showErrorMessage(i18next.t('ORDERS.ORDERS.DOWNLOAD_TEMPLATE_FOR_IMPORT_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const openOrdersImportModal = () => {
    const params = getImportOrdersModalConfig(
      IMPORT_MODAL_STEPS.SELECT_FILE,
      {
        updatedFile: null,
        selectedFile: null,
        handleFileChange: onImportOrdersFileChange,
      },
      props,
    );

    props.openGenericModal(params);
  };

  const onImportOrdersFileChange = (file) => {
    setSelectedFile(file);

    props.refreshGenericModal(
      getImportOrdersModalConfig(IMPORT_MODAL_STEPS.VALIDATE_FILE, {
        updatedFile: file,
        selectedFile,
        handleFileChange: onImportOrdersFileChange,
        handleFileValidation: handleOrdersCreationFileValidation,
      }),
    );
  };

  const handleOrdersCreationFileValidation = async (file) => {
    pageLoading();

    try {
      const ordersCreationFile = await formatXlsFileToJson(file);
      const formattedOrdersCreationFile = formatOrderBatchCreationFile(ordersCreationFile);

      await orderService.ordersBatchCreationValidation(clientId, formattedOrdersCreationFile);

      props.refreshGenericModal(
        getImportOrdersModalConfig(IMPORT_MODAL_STEPS.VALIDATED_FILE, {
          updatedFile: file,
          handleFileImport: () => handleBatchOrdersCreation(formattedOrdersCreationFile),
        }),
      );
    } catch (error) {
      if (!error.response) {
        props.showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.TOASTER_IMPORT_BATCH_FAILED'));

        props.closeGenericModal();
        return;
      }

      props.refreshGenericModal(
        getImportOrdersModalConfig(IMPORT_MODAL_STEPS.ERROR_FILE, {
          updatedFile: file,
          errorFile: error.response.data,
          status: error.response.status,
          handleFileChange: onImportOrdersFileChange,
          exportOrdersImportErrors,
        }),
      );
    } finally {
      pageLoaded();
    }
  };

  const handleBatchOrdersCreation = async (batchOrdersPayload) => {
    pageLoading();

    try {
      await orderService.ordersBatchCreation(clientId, batchOrdersPayload);
      props.showSuccessMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.BATCH_IMPORT_SUCCESS'));

      reloadOrders();
    } catch {
      props.showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.BATCH_IMPORT_FAILURE'));
    } finally {
      pageLoaded();
      props.closeGenericModal();
    }
  };

  useEffect(() => {
    setDefaultQueryParam();
  }, []);

  useEffect(() => {
    if (!stores || !stores.length) {
      return;
    }

    pageLoading();

    const storeIds = stores.map((store) => store.id);
    const centralStoreIds = centralStores.map(({ id }) => id);

    (async function load() {
      const suppliersOfStores = isForCentralKitchenPreparation
        ? await centralService.getkitchenSupplierByStoreIds(centralStoreIds)
        : await fetchSuppliersMadeOnPastOrders(storeIds, showMessage);

      const sortedSuppliers = sortArrayOfObjectsAlphabetically(suppliersOfStores, ['name']);

      setSuppliers(sortedSuppliers);

      setSelectedSuppliers(sortedSuppliers);

      const supplierIds = suppliersOfStores.map(({ id }) => id);

      const ordersCount = isForCentralKitchenPreparation
        ? await fetchOrdersStoresCount({
            storeIds,
            supplierIds,
            status: 3,
            isForCentralKitchenPreparation,
          })
        : await fetchOrdersStoresCount({ storeIds, isForCentralKitchenPreparation });

      // Handle cases where request could fail but should not interfere with normal behaviour
      onHasPastOrders(ordersCount === null || ordersCount > 0);

      await handleFetchGroupsByStoreIds(storeIds, showMessage, setGroups);

      if (!isForCentralKitchenPreparation && hasMultipleBrands) {
        const result = await fetchBrandsOfClient(clientId, showMessage);

        setBrands(result);
      }

      const fetchedLocations = await getLocationsOfAccount(accountId, showMessage);
      setLocations(fetchedLocations);

      const retailersOfClient = await fetchRetailersOfClient(clientId, showMessage);

      setRetailers(retailersOfClient);

      setSelectedStores(stores);

      pageLoaded();

      setIsLoading(false);
    })();
  }, [stores.length]);

  useEffect(() => {
    onActionsChange(
      getActions({
        ...props,
        stores,
        handleNewOrder,
        loadOrders: reloadOrders,
        locations,
        reloadOrders,
        setIsRetrievingData,
        setShouldRetrieveData,
        ordersSelected,
        markAsSent: (orders) => handleMarkOrdersAsSent(orders),
        deleteOrder: handleDeleteOrder,
        deleteSentOrder: (order) => handleDeleteSentOrder(order),
        cancelReception: handleCancelReceptionOrder,
        markAsDraft: handleConfirmationMarkAsDraft,
        markAsPreparing,
        markAsPrepared,
        setCreditRequestProcessed: handleConfirmationSetCreditRequestProcessed,
        authorizedActions,
        setEdiSettingsErrorModalParams,
        isForCentralKitchenPreparation,
        // Import methods
        hasCreateOrdersXlsProps,
        getMultipleOrderCreationTemplate,
        openOrdersImportModal,
        // Export methods
        handleOrdersContentExport: (selectedOrders) =>
          handleOrdersExport(ExportTypes.CONTENT, selectedOrders),
        handleOrdersListExport: (selectedOrders) =>
          handleOrdersExport(ExportTypes.LIST, selectedOrders),
        handleOrdersAggregatedExport: (selectedOrders) =>
          handleOrdersExport(ExportTypes.AGGREGATED, selectedOrders),
        handleOrdersAnomaliesExport: (selectedOrders) =>
          handleOrdersExport(ExportTypes.ANOMALIES, selectedOrders),
      }),
    );

    onRowActionsChange(
      getRowAction({
        markAsSent: (order) => handleMarkOrdersAsSent([order]),
        deleteOrder: handleDeleteOrder,
        deleteSentOrder: handleDeleteSentOrder,
        cancelReception: handleCancelReceptionOrder,
        markAsDraft: handleConfirmationMarkAsDraft,
        markAsPreparing,
        markAsPrepared,
        setCreditRequestProcessed: handleConfirmationSetCreditRequestProcessed,
        generateInvoice: handleConfirmationGenerateInvoice,
        duplicateOrder: handleDuplicateOrder,
        authorizedActions,
        hasGenerateInvoiceProps,
        isForCentralKitchenPreparation,
        // Export methods
        handleOrdersListExport: (order) => handleOrdersExport(ExportTypes.LIST, [order]),
        handleOrdersContentExport: (order) => handleOrdersExport(ExportTypes.CONTENT, [order]),
        handleOrdersAggregatedExport: (order) =>
          handleOrdersExport(ExportTypes.AGGREGATED, [order]),
        handleOrdersAnomaliesExport: (order) => handleOrdersExport(ExportTypes.ANOMALIES, [order]),
      }),
    );
  }, [ordersSelected, user]);

  useEffect(() => {
    if (!shouldRetrieveData || hasPastOrders === false) {
      setShouldRetrieveData(false);
      return;
    }

    (async function loadData() {
      await loadOrders();

      onOrdersSelectedChange([]);

      setShouldRetrieveData(false);
    })();
  }, [shouldRetrieveData]);

  useEffect(() => {
    if (
      !shouldRetrieveData &&
      (selectedStores.length || !!_.get(queryParams, 'selectedFilters.stores'))
    ) {
      setShouldRetrieveData(true);
    }
  }, [
    listViewQueryParams[ENUM_QUERY_PARAMS.SEARCH],
    listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY],
    listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE],
    listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE],
    queryParams,
    selectedStores,
    selectedSuppliers,
  ]);

  useEffect(() => {
    if (!applyFilters || !listViewRef || !listViewRef.current) {
      return;
    }

    const selectedFilters = {};
    selectedFilters.groups = selectedGroups;
    selectedFilters.stores = selectedStores;
    selectedFilters.suppliers = selectedSuppliers;

    if ((!advancedFilters || !advancedFilters.length) && applyFilters) {
      setQueryParams({
        ...queryParams,
        selectedFilters,
        params: isForCentralKitchenPreparation
          ? '&status={"gte":["3"]}&preparationStatus={"neq":[null]}'
          : '',
      });
      setListViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE](1);

      listViewRef.current.resetPagination();
      return;
    }

    const params = advancedFilters.reduce(
      (result, { getQueryParam, propertyKey, value }) => {
        // edge case where we want to remove default value for key
        if (propertyKey === 'preparationStatus') {
          result = result.replace(
            '&preparationStatus={"neq":[null]}',
            getQueryParam(propertyKey, value),
          );
        } else {
          result += getQueryParam(propertyKey, value);
        }

        return result;
      },
      isForCentralKitchenPreparation
        ? '&status={"gte":["3"]}&preparationStatus={"neq":[null]}'
        : '',
    );

    setQueryParams({
      ...queryParams,
      selectedFilters,
      params,
    });
    setListViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE](1);

    listViewRef.current.resetPagination();
  }, [advancedFilters, applyFilters, selectedGroups, selectedStores, selectedSuppliers]);

  if (isLoading) {
    return <div></div>;
  }

  if (hasPastOrders === false) {
    return (
      <GeneralEmptyStateListView
        icon={'/images/inpulse/first-order.svg'}
        renderAction={() =>
          !isForCentralKitchenPreparation && canCreateDraftOrder(authorizedActions) ? (
            <Button
              color={'inpulse-default'}
              handleClick={() => {
                handleNewOrder(
                  props,
                  stores,
                  reloadOrders,
                  setIsRetrievingData,
                  setShouldRetrieveData,
                  setEdiSettingsErrorModalParams,
                );
              }}
              icon={'/images/inpulse/add-white-small.svg'}
              label={i18next.t('ORDERS.ORDERS.EMPTY_LIST_CREATE_YOUR_FIRST_ORDER_ACTION')}
            />
          ) : null
        }
        subtitle={i18next.t(
          isForCentralKitchenPreparation
            ? 'ORDERS.ORDERS.EMPTY_LIST_PREPARATION'
            : 'ORDERS.ORDERS.EMPTY_LIST_CREATE_YOUR_FIRST_ORDER_CONTENT',
        )}
        title={
          !isForCentralKitchenPreparation &&
          i18next.t('ORDERS.ORDERS.EMPTY_LIST_CREATE_YOUR_FIRST_ORDER_TITLE')
        }
      />
    );
  }

  const userLanguageCode = _.get(user, 'lnkLanguageAccountrel.code', 'fr');

  return (
    <div style={{ overflowY: 'hidden' }}>
      <Container>
        <ListView
          actionOnClick={(order) =>
            handleEditOrder(
              order,
              props,
              loadOrders,
              setIsRetrievingData,
              setShouldRetrieveData,
              setEdiSettingsErrorModalParams,
            )
          }
          actions={actions}
          columns={dataColumns}
          countElements={totalOrdersCount}
          data={orders}
          defaultCurrentPage={listViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE]}
          defaultMaxPerPage={listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE]}
          defaultOrderBy={listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY] || 'orderDate'}
          defaultOrderType={listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE] || 'desc'}
          defaultSearchInput={listViewQueryParams[ENUM_QUERY_PARAMS.SEARCH]}
          handleCurrentPageChange={(input) =>
            setListViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE](input)
          }
          handleMaxPerPageChange={(input) =>
            setListViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE](input)
          }
          handleOrderByChange={(input) => setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY](input)}
          handleOrderTypeChange={(input) =>
            setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE](input)
          }
          handleSearchInputChange={(input) =>
            setListViewQueryParams[ENUM_QUERY_PARAMS.SEARCH](input)
          }
          hideAllPerPageOption={true}
          isLoading={isRetrievingData}
          languageCode={userLanguageCode}
          padding={STANDARD_LISTVIEW_PADDING}
          placeholderShape={i18next.t('GENERAL.SEARCH')}
          queryParams={queryParams}
          ref={listViewRef}
          renderEmptyState={() => <EmptyState />}
          renderFilterButton={() => (
            <DeepsightFiltersButton
              advancedFilters={advancedFilters}
              applyFilters={applyFilters}
              brands={!isForCentralKitchenPreparation && brands}
              columnsFilterList={columnsFilterList}
              fetchCustomSupplierList={fetchSuppliersFromPreviousOrders}
              filters={filters}
              groups={!isForCentralKitchenPreparation && groups}
              isLoading={isRetrievingData}
              locations={!isForCentralKitchenPreparation && locations}
              readOnly={isRetrievingData}
              retailers={retailers}
              selectedBrands={!isForCentralKitchenPreparation && selectedBrands}
              selectedGroups={!isForCentralKitchenPreparation && selectedGroups}
              selectedLocations={!isForCentralKitchenPreparation && selectedLocations}
              selectedRetailers={selectedRetailers}
              selectedStores={selectedStores}
              selectedSuppliers={selectedSuppliers}
              setAdvancedFilters={setAdvancedFilters}
              setApplyFilters={setApplyFilters}
              setFilters={setFilters}
              setSelectedBrands={!isForCentralKitchenPreparation && setSelectedBrands}
              setSelectedGroups={!isForCentralKitchenPreparation && setSelectedGroups}
              setSelectedLocations={!isForCentralKitchenPreparation && setSelectedLocations}
              setSelectedRetailers={setSelectedRetailers}
              setSelectedStores={setSelectedStores}
              setSelectedSuppliers={setSelectedSuppliers}
              stores={stores}
              suppliers={suppliers}
              textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
            />
          )}
          rowActions={rowActions}
          setSelectedItems={(items) => onOrdersSelectedChange(items)}
          onQueryParamsChange={setQueryParams}
        />

        {ediSettingsErrorModalParams && (
          <GenericModalContainer>
            <GenericModal
              closeGenericModal={() => setEdiSettingsErrorModalParams(null)}
              component={ediSettingsErrorModalParams.component}
              params={ediSettingsErrorModalParams}
            />
          </GenericModalContainer>
        )}
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  currency: state.baseReducer.currency,
  authorizedActions: getAuthorizedActions(state.baseReducer.userRights, '/order/orders'),
  client: getClientInfo(state.baseReducer.user),
  centralStores: getCentralKitchenStores(state.baseReducer.activeStores),
  hasGenerateInvoiceProps: getGenerateInvoice(state.baseReducer.userRights),
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  showSuccessMessage: (message, type) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message, type) => {
    dispatch(showErrorMessage(message));
  },
  openModal: (params, fullscreen) => {
    dispatch(openModal(params, fullscreen));
  },
  openModalExportInfo: (params) => {
    dispatch(openSmallModal(params));
  },
  openModalExportAggregated: (params) => {
    dispatch(openSmallModal(params));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  closeGenericModal: (params) => {
    dispatch(closeGenericModal(params));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  pageDidNotLoad: () => {
    dispatch(loadingFailure());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
