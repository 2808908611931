import { connect } from 'react-redux';
import { get, head, isEmpty, keyBy, orderBy } from 'lodash';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { buildArraySchema } from '@commons/GenericForm';
import { Button, Dropdown, INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';
import {
  Container,
  DropdownItem,
  DropdownItemImage,
  DropdownItemMainValue,
  DropdownItemSecondaryValue,
  DropdownItemValuesContainer,
  FooterContainer,
  FooterRightContainer,
  HeaderContainer,
  SubTitle,
  Title,
  TitleContainer,
} from '@commons/TableForm/TableFormInModal/styledComponents.js';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { ENTITY_UNITS } from '@commons/constants/units';
import { ExportButton } from '@commons/ExportButton';
import { formatNumber } from '@commons/DisplayNumber';
import {
  FullScreenModalContent,
  FullScreenModalContentInfos,
  FullScreenModalContentSelectors,
  FullScreenModalContentSelectorsAndInfos,
} from '@commons/FullScreenModal/Content';
import { FullScreenModalHeaderButtons } from '@commons/FullScreenModal/Header';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_CONFIRM_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { GenericModalContainer } from '@commons/Modals/GenericModal/styledComponents';
import { getConfirmationModal } from '@commons/Modals/ConfirmationModal';
import { getFormattedLastUpdatedText } from '@commons/FullScreenModal/utils';
import { getPropertyNoneValue } from '@commons/constants/categoryTypes';
import { getUserTimezone } from '@commons/utils/date';
import { LeftRightSplitter } from '@commons/LeftRightSplitter';
import { NONE_VALUE } from '@commons/constants/none';
import { PastDayDatePicker } from '@commons/DatePickers/PastDayDatePicker';
import { TableForm } from '@commons/TableForm';
import { TableFormFooter } from '@commons/TableForm/Footer';
import GenericModal from '@commons/Modals/GenericModal';

import { Input } from '@lib/inpulse/Input';

import {
  canCorrectRushOrder,
  canCreateRushOrder,
  canEditRushOrder,
} from '@selectors/actions/rushOrderActions';
import { getAuthorizedActions } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';
import { getSalesPointStores } from '@selectors/stores';

import { ingredient as ingredientService } from '@services/ingredient';
import rushOrderService from '@services/rushOrders';

import { exportContent } from '@orders/RushOrders/utils/ExportConfigs/exportContent';
import DeliveryReceiptModal from '@orders/components/DeliveryReceiptModal';
import ProgressBarModal from '@orders/OrderList/components/OrderForm/components/ProgressBarModal';

import { ActionDot, ActionsContainer, RushSupplierContainer } from './styledComponents';
import { getFormActions } from './utils/getFormActions';
import { getTableFormColumns } from './utils/getTableFormColumns';
import { resolveRushOrderRowInputs } from './utils/formInputsConfigurations';

const FIELD_ARRAY_NAME = 'rushOrderEntities';

const SHORT_DATE_FORMAT = 'DDMMYY';
const RUSH_ORDER_PREFIX = 'DEP-';

const UNIT_SELECT_ITEM = () => ({ name: 'unit', value: i18next.t('GENERAL.UNIT') });
const INGREDIENT_UNIT_POSSIBLE_ITEMS = () => ({
  [ENTITY_UNITS.UNIT]: [UNIT_SELECT_ITEM()],
  [ENTITY_UNITS.LITER]: [
    { name: 'L', value: 'L' },
    { name: 'mL', value: 'mL' },
  ],
  [ENTITY_UNITS.KILOGRAM]: [
    { name: 'kg', value: 'kg' },
    { name: 'g', value: 'g' },
  ],
});

const RushOrderForm = (props) => {
  const {
    // state props
    currency,
    client: { clientId, storeName },
    stores,
    // dispatch props
    pageLoading,
    pageLoaded,
    showSuccessMessage,
    showErrorMessage,
    closeModal,
    authorizedActions,
    params: { isCreation, fetchRushOrders, rushOrderId },
  } = props;
  // ==================
  // ===== States =====
  // ==================

  const userTimezone = getUserTimezone();

  const [rushOrder, setRushOrder] = useState(null);
  const [reference, setReference] = useState('DEP-');
  const [rushOrderTime] = useState(moment.tz(userTimezone).format('HHmmss'));

  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [rushSupplier, setRushSupplier] = useState(null);
  const [currentDateWithTz, setCurrentDateWithTz] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [, setFocusedMonth] = useState(moment());

  const [warningModalParams, setWarningModalParams] = useState(null);

  const [clientIngredients, setClientIngredients] = useState([]);
  const [allClientIngredients, setAllClientIngredients] = useState([]);

  const [rushOrderTotalPrice, setRushOrderTotalPrice] = useState(0);

  const [isEditingRushOrder, setIsEditingRushOrder] = useState(false);

  const [isEditingDeliveryDate, setIsEditingDeliveryDate] = useState(false);

  // Receipts
  const [openProgressBarModal, setOpenProgressBarModal] = useState(false);
  const [displayReceiptsModal, setDisplayReceiptsModal] = useState(false);
  const [isEditingReceipts, setIsEditingReceipts] = useState(false);
  const [progressBarStatus, setProgressBarStatus] = useState({ index: 0, totalPicturesCount: 0 });
  const [picturesUrls, setPicturesUrls] = useState([]);
  const [receptionComment, setReceptionComment] = useState('');

  const tableColumns = getTableFormColumns(currency);

  const movableColumns = tableColumns.filter((column) => !column.isFixed);

  const [rushOrderFormInputs, setRushOrderFormInputs] = useState(
    movableColumns.map((column) => column.input),
  );

  let isAfter48h = false;

  if (!isCreation && rushOrder) {
    isAfter48h = moment
      .tz(userTimezone)
      .isAfter(moment.tz(rushOrder.createdAt, userTimezone).add(2, 'd'));
  }

  const hasExistingReceipts = !isEmpty(picturesUrls) || !isEmpty(receptionComment);

  const handleExportContent = () => {
    pageLoading();

    const currentRushOrderEntities = rushOrderForm.getValues();

    const fileName = !isCreation && rushOrder ? rushOrder.reference : resolveReference();

    const receptionDate =
      !isCreation && rushOrder
        ? moment
            .tz(rushOrder.receptionDate, selectedStore.timezone)
            .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY)
        : moment
            .tz(selectedDate, selectedStore.timezone)
            .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

    const createdAt =
      !isCreation && rushOrder
        ? moment
            .tz(rushOrder.createdAt, userTimezone)
            .format(DATE_DISPLAY_FORMATS.HYPHEN_YEAR_MONTH_DAY_HOUR_MINUTE_SECOND)
        : moment
            .tz(userTimezone)
            .format(DATE_DISPLAY_FORMATS.HYPHEN_YEAR_MONTH_DAY_HOUR_MINUTE_SECOND);

    const currentRushOrderForExport = {
      reference,
      createdAt,
      receptionDate,
      storeId: get(selectedStore, 'id', ''),
      storeName: get(selectedStore, 'name', ''),
      totalPrice: rushOrderTotalPrice,
      clientId,
      rushOrderEntities: currentRushOrderEntities.rushOrderEntities,
      rushSupplier: rushSupplier || '',
    };

    try {
      exportContent([currentRushOrderForExport], storeName, currency, fileName);
    } catch (error) {
      showErrorMessage(i18next.t('ORDERS.RUSH_ORDERS.EXPORT_FAILED'));
    } finally {
      pageLoaded();
    }
  };

  // ===========================
  // ===== TableForm hooks =====
  // ===========================

  const rushOrderForm = useForm({
    mode: 'all', // To make sure that validation is run on every action
    defaultValues: {
      [FIELD_ARRAY_NAME]: [],
    },
    resolver: yupResolver(buildArraySchema(FIELD_ARRAY_NAME, rushOrderFormInputs)),
  });

  const { fields, prepend, remove, replace } = useFieldArray({
    control: rushOrderForm.control,
    name: FIELD_ARRAY_NAME,
    defaultValues: {
      [FIELD_ARRAY_NAME]: [],
    },
  });

  const formFields = useWatch({
    name: FIELD_ARRAY_NAME,
    control: rushOrderForm.control,
  });

  // ======================
  // ===== Form Actions =====
  // ======================

  const formActions = getFormActions({
    isAfter48h,
    setIsEditingRushOrder,
    setIsEditingDeliveryDate,
    setIsEditingReceipts,
    setDisplayReceiptsModal,
    handleExportContent,
    authorizedActions,
    isEditingRushOrder,
    isEditingDeliveryDate,
    displayReceiptsModal,
    fields,
    hasExistingReceipts,
  });

  // ======================
  // ===== UseEffects =====
  // ======================

  useEffect(() => {
    if (isCreation || !rushOrderId) {
      getClientIngredients();
      const storesToSelect = orderBy(stores, 'name', 'asc');

      const firstStore = head(storesToSelect);

      setSelectedStore(firstStore);
      setSelectedDate(moment.tz(firstStore.timezone));
      setCurrentDateWithTz(moment.tz(firstStore.timezone));
      return;
    }

    fetchRushOrderDetails();
  }, [rushOrderId]);

  useEffect(() => {
    if (!rushOrder) {
      return;
    }

    const entities = get(rushOrder, 'rushOrderEntities', null);

    if (!entities) {
      return;
    }

    const mappedStore = stores.find((store) => store.id === rushOrder.storeId);

    const formattedEntities = entities.map((entity) => ({
      ...entity,
      savedUnit: entity.unit,
    }));

    const formattedRushOrderEntities = formatIngredientsForDropdownDisplay(formattedEntities);
    replace(formattedRushOrderEntities);

    setReference(rushOrder.reference);
    setSelectedStore(mappedStore);
    setSelectedDate(moment.tz(rushOrder.receptionDate, mappedStore.timezone));
    setRushSupplier(rushOrder.rushSupplier);
    setReceptionComment(rushOrder.receptionComment);
    setPicturesUrls(get(rushOrder, 'pictures', []));
  }, [rushOrder]);

  useEffect(() => {
    if (isCreation || !rushOrder) {
      return;
    }

    formatRushOrderFormInputs();
  }, [rushOrder, isEditingRushOrder]);

  useEffect(() => {
    if (progressBarStatus.index >= progressBarStatus.totalPicturesCount) {
      setOpenProgressBarModal(false);
    }
  }, [progressBarStatus]);

  // ====================
  // ===== Methods ======
  // ====================

  const fetchRushOrderDetails = async () => {
    if (!rushOrderId) {
      return;
    }

    pageLoading();

    try {
      const fetchedRushOrder = await rushOrderService.getRushOrderById(rushOrderId);
      setRushOrder(fetchedRushOrder);

      // Get ingredients and filter those already in the rush order
      await getClientIngredients(fetchedRushOrder);
    } catch {
      showErrorMessage('ORDERS.RUSH_ORDERS.FETCH_BY_ID_ERROR');
    } finally {
      pageLoaded();
    }
  };

  const formatRushOrderFormInputs = () => {
    const rushOrderFomInputFormatted = rushOrderFormInputs.map((input) => ({
      ...input,
      isDisabled: () =>
        (!isCreation && !isAfter48h && !canEditRushOrder(authorizedActions)) ||
        (isAfter48h && !isEditingRushOrder),
    }));

    setRushOrderFormInputs(rushOrderFomInputFormatted);
  };

  const resolveReference = () => {
    if (!isCreation && rushOrder) {
      return rushOrder.reference;
    }

    if (selectedStore && selectedStore.partnerId) {
      const currentReference = `${RUSH_ORDER_PREFIX}${selectedStore.partnerId}-${moment
        .tz(userTimezone)
        .format(SHORT_DATE_FORMAT)}-${rushOrderTime}`;

      if (currentReference !== reference) {
        setReference(currentReference);
      }

      return currentReference;
    }

    return RUSH_ORDER_PREFIX;
  };

  const computeTotalHT = () => {
    if (!formFields.length) {
      return '-';
    }

    const totalHt = formFields.reduce((total, field) => {
      const fieldTotal = parseFloat(field.totalPrice);

      if (fieldTotal > 0) {
        total += fieldTotal;
      }

      return total;
    }, 0);

    const formattedTotal = parseFloat(totalHt.toFixed(2));

    if (formattedTotal !== rushOrderTotalPrice) {
      setRushOrderTotalPrice(formattedTotal);
    }

    return formatNumber(totalHt, currency.numberDecimals);
  };

  const addEntity = async (item) => {
    prepend(item);

    rushOrderForm.trigger(FIELD_ARRAY_NAME); // Force validate added item to render proper icon status

    const filteredClientIngredients = clientIngredients.filter(
      (ingredient) => ingredient.__entityId !== item.__entityId,
    );
    setClientIngredients(filteredClientIngredients);
  };

  const deleteEntity = (index, field) => {
    const clientIngredientsWithNewIngredient = [...clientIngredients, field];

    const formattedIngredients = formatIngredientsForDropdownDisplay(
      clientIngredientsWithNewIngredient,
    );

    setClientIngredients(formattedIngredients);
    remove(index);
  };

  const deleteAllEntities = () => {
    setClientIngredients(allClientIngredients);

    remove();
  };

  const formattedRushOrder = () => {
    const formEntities = rushOrderForm.getValues();
    const formattedEntities = formEntities.rushOrderEntities.map((entity) => {
      let entityId = get(entity, '__entityId');

      const lnkEntityrelId = get(entity, 'lnkEntityrel.id', null);
      if (rushOrder && lnkEntityrelId) {
        entityId = lnkEntityrelId;
      }

      return {
        id: entityId,
        quantity: parseFloat(entity.quantity),
        unit: get(entity, 'unit.name', null),
        totalPrice: parseFloat(entity.totalPrice),
      };
    });

    return {
      reference,
      createdAt: moment.tz(userTimezone).format(),
      updatedAt: moment.tz(userTimezone).format(),
      receptionDate: moment
        .tz(selectedDate, selectedStore.timezone)
        .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      storeId: selectedStore.id,
      totalPrice: rushOrderTotalPrice,
      clientId,
      entities: formattedEntities,
      rushSupplier: rushSupplier,
      receptionComment: receptionComment,
      pictures: picturesUrls,
    };
  };

  const create = async () => {
    pageLoading();

    const rushOrderToCreate = formattedRushOrder();

    rushOrderToCreate.rushSupplier = !!rushSupplier ? rushSupplier.toUpperCase() : NONE_VALUE;

    try {
      await rushOrderService.createRushOrder(rushOrderToCreate);

      showSuccessMessage(i18next.t('ORDERS.RUSH_ORDERS.CREATION_SUCCESS'));
      closeModal();

      await fetchRushOrders();
    } catch {
      showErrorMessage(i18next.t('ORDERS.RUSH_ORDERS.CREATION_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const update = async () => {
    pageLoading();

    const updatedRushOrder = formattedRushOrder();

    if (!!rushSupplier) {
      updatedRushOrder.rushSupplier = rushSupplier.toUpperCase();
    } else {
      updatedRushOrder.rushSupplier = NONE_VALUE;
    }

    Object.assign(updatedRushOrder, { id: rushOrder.id });

    try {
      await rushOrderService.updateRushOrder(updatedRushOrder);

      showSuccessMessage(i18next.t('ORDERS.RUSH_ORDERS.UPDATE_SUCCESS'));
      closeModal();

      await fetchRushOrders(stores);
    } catch {
      showErrorMessage(i18next.t('ORDERS.RUSH_ORDERS.UPDATE_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  // IPP-4672
  const validateForm = (type) => {
    const errors = rushOrderForm.formState.errors;

    if (!isEmpty(errors)) {
      errors[FIELD_ARRAY_NAME].forEach((_, index) => {
        const path = `${FIELD_ARRAY_NAME}[${index}]`;

        rushOrderForm.setValue(path, {
          ...rushOrderForm.getValues(path),
          hasErrors: true, // Allows to keep track of fields on which validation has already been run
        });
      });

      return;
    }

    if (type === 'update') {
      rushOrderForm.handleSubmit(update)();
      return;
    }

    rushOrderForm.handleSubmit(create)();
  };

  const getClientIngredients = async (rushOrder) => {
    pageLoading();

    try {
      const ingredients = await ingredientService.getIngredients(clientId);

      const activeIngredients = ingredients.filter((ingredient) => ingredient.active);

      const reformattedIngredients = activeIngredients.map((ingredient) => ({
        ...ingredient,
        savedUnit: ingredient.unit,
      }));

      const formattedIngredients = formatIngredientsForDropdownDisplay(reformattedIngredients);

      setAllClientIngredients(formattedIngredients);

      if (isCreation || !rushOrder) {
        setClientIngredients(formattedIngredients);
      } else {
        // Filter out ingredients already in the rush order
        const inRushOrderEntitiesIds = rushOrder.rushOrderEntities.map(({ entityId }) => entityId);

        const availableIngredients = formattedIngredients.filter(
          ({ id }) => !inRushOrderEntitiesIds.includes(id),
        );
        setClientIngredients(availableIngredients);
      }
    } catch {
      showErrorMessage(i18next.t('ADMIN.INGREDIENTS.LOAD_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const handleCloseModal = () => {
    if (isCreation) {
      closeModal();
      return;
    }

    if (getIfFormIsDirty()) {
      setWarningModalParams(
        getConfirmationModal({
          title: i18next.t('GENERAL.LEAVE'),
          content: `${i18next.t('ORDERS.ORDERS.MODAL_CLOSE_TEXT_1')} ${i18next.t(
            'ORDERS.ORDERS.MODAL_CLOSE_TEXT_2',
          )}`,
          actions: [
            GENERIC_MODAL_CANCEL_BUTTON(),
            {
              ...GENERIC_MODAL_CONFIRM_BUTTON(),
              handleClick: () => {
                closeModal();
              },
            },
          ],
        }),
      );
      return;
    }

    closeModal();
  };

  // Do this method because as I understand the settings of the form is not good so
  // the control to check if form is dirty doesn't work properly. Mainly with inputIncrement component
  const getIfFormIsDirty = () => {
    const formEntities = rushOrderForm.getValues();
    const entities = get(rushOrder, 'rushOrderEntities', null);

    if (formEntities.rushOrderEntities.length !== entities.length) {
      return true;
    }

    const entitiesKeyById = keyBy(entities, 'id');
    const formEntitiesKeyById = keyBy(formEntities.rushOrderEntities, '__entityId');

    return entities.reduce((acc, entity) => {
      if (!entitiesKeyById[entity.id] || !formEntitiesKeyById[entity.id]) {
        acc = true;
        return acc;
      }

      if (
        get(entitiesKeyById[entity.id], 'lnkEntityrel.name', '') !==
        formEntitiesKeyById[entity.id].name
      ) {
        acc = true;
        return acc;
      }

      if (entitiesKeyById[entity.id].quantity !== formEntitiesKeyById[entity.id].quantity) {
        acc = true;
        return acc;
      }

      if (
        entitiesKeyById[entity.id].totalPrice !== Number(formEntitiesKeyById[entity.id].totalPrice)
      ) {
        acc = true;
        return acc;
      }

      if (entitiesKeyById[entity.id].unit !== formEntitiesKeyById[entity.id].unit.name) {
        acc = true;
        return acc;
      }

      return acc;
    }, false);
  };

  const getSelectableUnitsOfIngredient = (unit) => {
    if (unit === 'mL' || unit === 'L') {
      return INGREDIENT_UNIT_POSSIBLE_ITEMS()[ENTITY_UNITS.LITER];
    }

    if (unit === 'g' || unit === 'kg') {
      return INGREDIENT_UNIT_POSSIBLE_ITEMS()[ENTITY_UNITS.KILOGRAM];
    }
    return INGREDIENT_UNIT_POSSIBLE_ITEMS()[ENTITY_UNITS.UNIT];
  };

  const getDefaultUnitOfIngredient = (unit) => {
    if (unit === 'unit') {
      return UNIT_SELECT_ITEM();
    }

    if (isCreation) {
      if (unit === 'g' || unit === 'kg') {
        return {
          name: ENTITY_UNITS.KILOGRAM,
          value: ENTITY_UNITS.KILOGRAM,
        };
      }
      if (unit === 'mL' || unit === 'L') {
        return {
          name: ENTITY_UNITS.LITER,
          value: ENTITY_UNITS.LITER,
        };
      }
    }

    return { name: unit, value: unit };
  };

  const formatIngredientsForDropdownDisplay = (ingredients) =>
    ingredients.map((ingredient) => {
      const category = ingredient.category || getPropertyNoneValue();

      return {
        ...ingredient,
        __entityId: ingredient.__entityId || ingredient.id,
        category,
        name: get(ingredient, 'lnkEntityrel.name', ingredient.name),
        img: ingredient.img,
        selectableUnits: getSelectableUnitsOfIngredient(ingredient.savedUnit),
        unit: getDefaultUnitOfIngredient(ingredient.savedUnit),
        quantity: ingredient.quantity || 0,
        totalPrice: ingredient.totalPrice || 0,
        renderValue: () => (
          <DropdownItem>
            <DropdownItemImage
              src={
                ingredient.img
                  ? ingredient.img
                  : '/images/inpulse/supplier-product-photo-placeholder.svg'
              }
            />
            <DropdownItemValuesContainer>
              <DropdownItemMainValue>{ingredient.name}</DropdownItemMainValue>
              <DropdownItemSecondaryValue>{category}</DropdownItemSecondaryValue>
            </DropdownItemValuesContainer>
          </DropdownItem>
        ),
      };
    });

  const renderTableFormActions = () => (
    <TableFormFooter>
      <FooterContainer>
        <Dropdown
          iconSrc={'/images/inpulse/search-dmgrey-small.svg'}
          isDisabled={
            (!isCreation && !isAfter48h && !canEditRushOrder(authorizedActions)) ||
            (isAfter48h && !isEditingRushOrder)
          }
          items={clientIngredients}
          placeholder={i18next.t('GENERAL.ADD_INGREDIENT')}
          selectedItem={null}
          onSelectionChange={addEntity}
        />
        {((!isAfter48h && canEditRushOrder(authorizedActions)) || isEditingRushOrder) && (
          <FooterRightContainer isDisabled={formFields.length < 1} onClick={deleteAllEntities}>
            {i18next.t('GENERAL.FORM_DELETE_LINES')}
          </FooterRightContainer>
        )}
      </FooterContainer>
    </TableFormFooter>
  );

  const isDisableDatePicker = () => {
    if (
      !isCreation &&
      !isAfter48h &&
      !canEditRushOrder(authorizedActions) &&
      !isEditingDeliveryDate
    ) {
      return true;
    }

    if (isAfter48h && !isEditingDeliveryDate) {
      return true;
    }

    return false;
  };

  const isDisableHeaderInputs = () => {
    if (!isCreation && !isAfter48h && !canEditRushOrder(authorizedActions)) {
      return true;
    }

    if (isAfter48h) {
      return true;
    }

    return false;
  };

  const isDisabledSaveInput = () => {
    const errors = rushOrderForm.formState.errors;

    const isDirtyReceiptInputs = isEmpty(picturesUrls) && isEmpty(receptionComment);

    if (!isEmpty(errors)) {
      let shoudDisableSaveInput = false;

      errors[FIELD_ARRAY_NAME].forEach((_, index) => {
        const path = `${FIELD_ARRAY_NAME}[${index}]`;

        const rushOrderFormPathValue = rushOrderForm.getValues(path);

        if (rushOrderFormPathValue.hasErrors) {
          shoudDisableSaveInput = true;
        }
      });

      if (shoudDisableSaveInput) {
        return true;
      }
    }

    if (isEmpty(rushOrderForm.getValues().rushOrderEntities)) {
      return true;
    }

    if (!isAfter48h) {
      if (!isCreation) {
        if (!!selectedStore && selectedStore.name !== rushOrder.storeName) {
          return false;
        }

        if (!moment(selectedDate).isSame(moment(rushOrder.selectedDate), 'd')) {
          return false;
        }

        if (rushSupplier !== rushOrder.rushSupplier) {
          return false;
        }

        if (isEditingReceipts) {
          return isDirtyReceiptInputs;
        }

        return !getIfFormIsDirty();
      }
      return isEmpty(rushOrderForm.getValues().rushOrderEntities);
    }

    if (!!isEditingDeliveryDate && rushOrder) {
      return moment
        .tz(rushOrder.receptionDate, selectedStore.timezone)
        .isSame(moment(selectedDate), 'd');
    }

    if (isAfter48h && isEditingRushOrder && !isEmpty(rushOrderForm.getValues().rushOrderEntities)) {
      return !getIfFormIsDirty();
    }

    if (isEditingReceipts) {
      return isDirtyReceiptInputs;
    }

    return true;
  };

  const isSaveButtonDisplayed = () => {
    if (isCreation && canCreateRushOrder(authorizedActions)) {
      return true;
    }

    if (!isCreation && !isAfter48h ? canEditRushOrder(authorizedActions) : isEditingRushOrder) {
      return true;
    }

    if (isEditingDeliveryDate) {
      return true;
    }

    if (
      isEditingReceipts &&
      ((!isAfter48h && canEditRushOrder(authorizedActions)) ||
        canCorrectRushOrder(authorizedActions))
    ) {
      return true;
    }

    return false;
  };

  const handleDeliveryReceiptAndProgressBarModalsOpening = (
    displayProgressBarModal,
    displayDeliveryReceiptModal,
  ) => {
    setOpenProgressBarModal(displayProgressBarModal);
    setDisplayReceiptsModal(displayDeliveryReceiptModal);
  };

  return (
    <Container>
      <HeaderContainer>
        <TitleContainer>
          <Title withSubtitles>{resolveReference()}</Title>
          <SubTitle>
            {getFormattedLastUpdatedText(!isCreation && rushOrder ? rushOrder.updatedAt : null)}
          </SubTitle>
        </TitleContainer>
        <FullScreenModalHeaderButtons>
          <Button
            color={'inpulse-grey'}
            handleClick={() => handleCloseModal()}
            icon={'/images/inpulse/close-white-small.svg'}
          />
          {isSaveButtonDisplayed() && (
            <Button
              color={'inpulse-default'}
              handleClick={() => validateForm(!isCreation ? 'update' : 'create')}
              icon={'/images/inpulse/save-white-small.svg'}
              isDisabled={isDisabledSaveInput()}
            />
          )}
        </FullScreenModalHeaderButtons>
      </HeaderContainer>
      <FullScreenModalContent headerHeight={'64px'}>
        <LeftRightSplitter
          left={
            <FullScreenModalContentSelectorsAndInfos>
              <FullScreenModalContentSelectors>
                <Dropdown
                  iconSrc={'/images/inpulse/store-black-small.svg'}
                  isDisabled={isDisableHeaderInputs()}
                  isRequired={true}
                  isUniqueSelection={true}
                  items={stores}
                  selectedItem={selectedStore}
                  onSelectionChange={(newSelectedStore) => {
                    if (selectedStore && newSelectedStore.id === selectedStore.id) {
                      return;
                    }

                    setSelectedStore(newSelectedStore);
                    setSelectedDate(moment.tz(newSelectedStore.timezone));
                  }}
                />
                <PastDayDatePicker
                  date={selectedDate}
                  disabled={isDisableDatePicker()}
                  maxFutureDate={currentDateWithTz}
                  timezone={get(selectedStore, 'timezone', null)}
                  onDateChange={(it) => setSelectedDate(it)}
                  onNextMonthClick={(nextMonth) => setFocusedMonth(nextMonth)}
                  onPrevMonthClick={(prevMonth) => setFocusedMonth(prevMonth)}
                />
                <RushSupplierContainer>
                  <Input
                    icon={'app'}
                    iconSize={'small'}
                    placeholder={i18next.t('GENERAL.RUSH_SUPPLIER')}
                    readOnly={isDisableHeaderInputs()}
                    type={'text'}
                    value={rushSupplier || ''}
                    width={INPUT_WIDTH.LARGE}
                    useParentHeight
                    onChange={(e) => setRushSupplier(e.target.value)}
                  />
                </RushSupplierContainer>
              </FullScreenModalContentSelectors>
              <FullScreenModalContentInfos
                text={computeTotalHT()}
                title={i18next.t('GENERAL.TOTAL_EXCL_TAXES_WITH_CURRENCY', {
                  currencyCode: currency.alphabeticCode,
                })}
              />
            </FullScreenModalContentSelectorsAndInfos>
          }
          right={
            !hasExistingReceipts &&
            !canEditRushOrder(authorizedActions) &&
            !canCorrectRushOrder(authorizedActions) ? (
              <ExportButton
                handleClick={handleExportContent}
                hasLabel={false}
                isDisabled={isEmpty(fields)}
              />
            ) : (
              <ActionsContainer>
                <Button
                  actions={formActions}
                  color={'inpulse-outline'}
                  iconDropdown={'/images/inpulse/ellipsis-black-small.svg'}
                />
                {(!isEmpty(receptionComment) || !isEmpty(picturesUrls)) && <ActionDot />}
              </ActionsContainer>
            )
          }
        />
        {openProgressBarModal && (
          <ProgressBarModal
            progressBarStatus={progressBarStatus}
            setOpenProgressBarModal={setOpenProgressBarModal}
          />
        )}
        {displayReceiptsModal && (
          <DeliveryReceiptModal
            close={() => {
              setDisplayReceiptsModal(!displayReceiptsModal);
            }}
            handleDeliveryReceiptAndProgressBarModalsOpening={
              handleDeliveryReceiptAndProgressBarModalsOpening
            }
            handleReceptionComment={setReceptionComment}
            params={{ rushOrder: { reference } }}
            partnerId={get(selectedStore, 'partnerId', '')}
            picturesUrls={picturesUrls}
            progressBarStatus={progressBarStatus}
            readOnly={
              !isCreation &&
              (!canEditRushOrder(authorizedActions) ||
                (isAfter48h && !canCorrectRushOrder(authorizedActions)))
            }
            receptionComment={receptionComment}
            setOpenProgressBarModal={setOpenProgressBarModal}
            setPicturesUrls={setPicturesUrls}
            setProgressBarStatus={setProgressBarStatus}
            isRushOrder
          />
        )}
        <TableForm
          columns={tableColumns}
          deleteFunction={deleteEntity}
          emptyState={{
            text: i18next.t('GENERAL.NO_INGREDIENT'),
            width: INPUT_WIDTH.LARGE,
          }}
          fieldArrayName={FIELD_ARRAY_NAME}
          fields={fields}
          form={rushOrderForm}
          inputs={rushOrderFormInputs}
          isEditionAllowed={
            (!isAfter48h && canEditRushOrder(authorizedActions)) ||
            (isAfter48h && isEditingRushOrder)
          }
          register={rushOrderForm.register}
          renderActions={renderTableFormActions}
          resolveInputs={(currentField) =>
            resolveRushOrderRowInputs(currentField, rushOrderFormInputs, formFields)
          }
        />
        {!!warningModalParams && (
          <GenericModalContainer>
            <GenericModal
              closeGenericModal={() => setWarningModalParams(null)}
              component={warningModalParams.component}
              params={warningModalParams}
            />
          </GenericModalContainer>
        )}
      </FullScreenModalContent>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
  client: getClientInfo(state.baseReducer.user),
  stores: getSalesPointStores(state.baseReducer.activeStores),
  authorizedActions: getAuthorizedActions(state.baseReducer.userRights, '/order/rush-orders'),
});

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

RushOrderForm.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(RushOrderForm);
