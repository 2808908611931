import { connect } from 'react-redux';
import { find, get, isEmpty } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { showConfirmationMessage } from '@actions/messageconfirmation';

import { Button, Dropdown } from '@commons/utils/styledLibraryComponents';
import { DATE_PICKER_DOT_COLOR } from '@commons/DatePickers/constants';
import {
  FullScreenModalHeader,
  FullScreenModalHeaderButtons,
  FullScreenModalHeaderInfos,
  FullScreenModalHeaderSelectors,
} from '@commons/FullScreenModal/Header';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getFormattedLastUpdatedText } from '@commons/FullScreenModal/utils';
import { SingleDatePicker } from '@commons/DatePickers/SingleDatePicker';

import {
  canCorrectTransfer,
  canCreateTransfer,
  canEditTransfer,
  canReceiveTransfer,
} from '@selectors/actions/transferActions';
import { getAuthorizedActions } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';
import { getSalesPointStores } from '@selectors/stores';

import inventoryTransferListService from '@services/inventoryTransferList';
import storeService from '@services/store';

import { isEditionAllowed } from '@stocks/StocksInventories/common/rights';
import { STORE_LINKAGE_TYPES } from '@stocks/StocksInventories/StocksInventoriesTransfer/utils/index';

const DEFAULT_TIMEZONE = 'Europe/Paris';

const getStoresOfClient = async (clientId, translatedStoreNamePlural, showMessage) => {
  try {
    const stores = await storeService.getStoresOfClient(clientId);

    return stores.filter(({ isKitchen }) => !isKitchen);
  } catch (err) {
    showMessage(
      i18next.t('GENERAL.STORES_CLIENT_FETCH_FAILURE', {
        storeName: translatedStoreNamePlural,
      }),
      'error',
    );
    return [];
  }
};

const generateReference = (senderStoreId, recipientStoreId, date, clientStores) => {
  const senderStore = find(clientStores, ['id', senderStoreId]);
  const recipientStore = find(clientStores, ['id', recipientStoreId]);

  let reference = 'TRA-';

  if (senderStore) {
    reference = reference.concat('', senderStore.partnerId);
  }

  if (recipientStore) {
    reference = reference.concat('-', recipientStore.partnerId);
  }

  if (date) {
    reference = reference.concat('-', date.format('DDMMYY'));
  }

  return reference;
};

const TransferFormHeader = (props) => {
  const {
    client: { clientId, storeName },
    stores,
    closeModal,
    showMessage,
    transferList,
    setTransferList,
    isUpdatingDate,
    isCreation,
    isSaveAvailable,
    handleSave,
    handleValidation,
    userStoreLinkageType,
    selectedSupplierProducts,
    pageLoading,
    pageLoaded,
    isEditionAvailable,
    authorizedActions,
  } = props;

  const translatedStoreName = getClientStoreNameTranslation(storeName, false);
  const translatedStoreNamePlural = getClientStoreNameTranslation(storeName, true);

  const [clientStores, setClientStores] = useState([]);
  const [senderStoreList, setSenderStoreList] = useState(stores);
  const [recipientStoreList, setRecipientStoreList] = useState([]);
  const [forbiddenDates, setForbiddenDates] = useState([]);
  const [senderStore, setSenderStore] = useState(null);
  const [recipientStore, setRecipientStore] = useState(null);
  const [dateHasBeenChanged, setDateHasBeenChanged] = useState(false);

  const isAfter48h = !isCreation && !isEditionAllowed(transferList.createdAt);

  useEffect(() => {
    if (isCreation) {
      (async function loadClientStores() {
        pageLoading();
        try {
          const allClientStores = await getStoresOfClient(
            clientId,
            translatedStoreNamePlural,
            showMessage,
          );

          setClientStores(allClientStores.filter((store) => store.active));
        } catch (err) {
          showMessage(
            i18next.t('GENERAL.STORES_CLIENT_FETCH_FAILURE', {
              storeName: translatedStoreNamePlural,
            }),
            'error',
          );
          setClientStores([]);
        } finally {
          pageLoaded();
        }
      })();
    }
  }, []);

  useEffect(() => {
    const senderStoreId = get(transferList, 'senderStoreId');
    const recipientStoreId = get(transferList, 'recipientStoreId');

    setSenderStore(find(clientStores, ['id', senderStoreId]));
    setRecipientStore(find(clientStores, ['id', recipientStoreId]));

    setSenderStoreList(stores.filter((store) => store.id != recipientStoreId));
    setRecipientStoreList(clientStores.filter((store) => store.id != senderStoreId));

    setTransferList({
      ...transferList,
      reference: generateReference(
        senderStoreId,
        recipientStoreId,
        get(transferList, 'sentAt'),
        clientStores,
      ),
    });

    if (senderStoreId && recipientStoreId) {
      const senderStoreTimezone = get(senderStore, 'timezone', DEFAULT_TIMEZONE);

      try {
        (async function loadTransferDates() {
          const allTransferDates = await inventoryTransferListService.getInventoryTransferDates(
            senderStoreId,
            recipientStoreId,
          );

          setForbiddenDates(allTransferDates.map((date) => moment.tz(date, senderStoreTimezone)));
        })();
      } catch (err) {
        showMessage(
          i18next.t('STOCKS.TRANSFERS.FORM_FETCH_TRANSFER_DATE_ERROR', {
            storeName: translatedStoreNamePlural,
          }),
          'error',
        );
        return [];
      }
    }
  }, [get(transferList, 'senderStoreId'), get(transferList, 'recipientStoreId'), clientStores]);

  useEffect(() => {
    setTransferList({
      ...transferList,
      reference: generateReference(
        get(transferList, 'senderStoreId'),
        get(transferList, 'recipientStoreId'),
        get(transferList, 'sentAt'),
        clientStores,
      ),
    });
  }, [get(transferList, 'sentAt')]);

  const calendarInfo = {
    dotsInformations: [
      {
        dotColor: DATE_PICKER_DOT_COLOR.RED,
        dotInfo: i18next.t('STOCKS.TRANSFERS.FORM_RED_DOT_INFO'),
      },
    ],
  };

  const isValidationActionDisabled = () => {
    if (userStoreLinkageType === STORE_LINKAGE_TYPES.BOTH) {
      return isEmpty(selectedSupplierProducts) || transferList.hasBeenReceived;
    }

    if (userStoreLinkageType === STORE_LINKAGE_TYPES.RECIPIENT) {
      return transferList.hasBeenReceived;
    }

    return true;
  };

  const isSaveButtonDisplayed = () => {
    const isEditing =
      !isCreation &&
      !isAfter48h &&
      userStoreLinkageType !== STORE_LINKAGE_TYPES.RECIPIENT &&
      !transferList.hasBeenReceived;

    const isCorrecting =
      !isCreation &&
      (isAfter48h ||
        transferList.hasBeenReceived ||
        userStoreLinkageType !== STORE_LINKAGE_TYPES.SENDER) &&
      isEditionAvailable;

    if (
      isUpdatingDate ||
      (isCreation && canCreateTransfer(authorizedActions)) ||
      (isEditing && canEditTransfer(authorizedActions)) ||
      (isCorrecting && canCorrectTransfer(authorizedActions))
    ) {
      return true;
    }

    return false;
  };

  const isValidationButtonDisplayed = () => {
    if (isEmpty(senderStore) || isEmpty(recipientStore)) {
      return false;
    }

    if (
      userStoreLinkageType !== STORE_LINKAGE_TYPES.SENDER &&
      canReceiveTransfer(authorizedActions) &&
      !transferList.hasBeenReceived
    ) {
      return true;
    }
    return false;
  };

  return (
    <FullScreenModalHeader>
      <FullScreenModalHeaderSelectors>
        <Dropdown
          customStyle={{ position: 'inherit' }}
          iconSrc={
            !senderStore
              ? '/images/inpulse/pin-dmgrey-small.svg'
              : '/images/inpulse/pin-black-small.svg'
          }
          isDisabled={!isCreation}
          isRequired={true}
          isUniqueSelection={true}
          items={senderStoreList}
          placeholder={i18next.t('STOCKS.TRANSFERS.FORM_SENDER_PLACEHOLDER', {
            storeName: translatedStoreName,
          })}
          selectedItem={senderStore}
          onSelectionChange={(selectedItem) =>
            setTransferList({
              ...transferList,
              senderStoreId: selectedItem.id,
            })
          }
        />
        <Dropdown
          customStyle={{ position: 'inherit' }}
          iconSrc={
            !recipientStore
              ? '/images/inpulse/pin-dmgrey-small.svg'
              : '/images/inpulse/pin-black-small.svg'
          }
          isDisabled={!isCreation}
          isRequired={true}
          isUniqueSelection={true}
          items={recipientStoreList}
          placeholder={i18next.t('STOCKS.TRANSFERS.FORM_RECIPIENT_PLACEHOLDER', {
            storeName: translatedStoreName,
          })}
          selectedItem={recipientStore}
          onSelectionChange={(selectedItem) =>
            setTransferList({
              ...transferList,
              recipientStoreId: selectedItem.id,
              recipientStoreName: selectedItem.name,
            })
          }
        />
        <SingleDatePicker
          calendarInfo={calendarInfo}
          customStyle={{ position: 'inherit' }}
          date={get(transferList, 'sentAt')}
          disabled={(!isCreation || !senderStore || !recipientStore) && !isUpdatingDate}
          forbiddenDates={forbiddenDates}
          placeholder={i18next.t('GENERAL.DATEPICKER_PLACEHOLDER')}
          timezone={get(senderStore, 'timezone', DEFAULT_TIMEZONE)}
          onDateChange={(newDate) => {
            setTransferList({
              ...transferList,
              sentAt: newDate,
            });

            if (!isCreation) {
              setDateHasBeenChanged(true);
            }
          }}
        />
      </FullScreenModalHeaderSelectors>
      <FullScreenModalHeaderInfos
        subtitle={getFormattedLastUpdatedText(get(transferList, 'updatedAt'))}
        title={get(transferList, 'reference')}
      />
      <FullScreenModalHeaderButtons>
        <Button
          color={'inpulse-grey'}
          handleClick={() => {
            closeModal();
          }}
          icon={'/images/inpulse/close-white-small.svg'}
        />
        {isSaveButtonDisplayed() && (
          <Button
            color={
              userStoreLinkageType === STORE_LINKAGE_TYPES.BOTH
                ? 'inpulse-outline'
                : 'inpulse-default'
            }
            handleClick={() => handleSave()}
            icon={
              userStoreLinkageType === STORE_LINKAGE_TYPES.BOTH
                ? '/images/inpulse/save-black-small.svg'
                : '/images/inpulse/save-white-small.svg'
            }
            isDisabled={(!isSaveAvailable || !isEditionAvailable) && !dateHasBeenChanged}
          />
        )}
        {isValidationButtonDisplayed() && (
          <Button
            color={'inpulse-default'}
            handleClick={handleValidation}
            icon={'/images/inpulse/done-white-small.svg'}
            // Validation is disabled if the transfer has been received or if no supplierProducts has been selected (for exemple during creation)
            isDisabled={isValidationActionDisabled()}
          />
        )}
      </FullScreenModalHeaderButtons>
    </FullScreenModalHeader>
  );
};

const mapStateToProps = (state) => ({
  stores: getSalesPointStores(state.baseReducer.activeStores),
  client: getClientInfo(state.baseReducer.user),
  authorizedActions: getAuthorizedActions(
    state.baseReducer.userRights,
    '/stocks/inventories/transfer',
  ),
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TransferFormHeader);
