import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { CryptrProvider } from '@cryptr/cryptr-react';
import { I18nextProvider } from 'react-i18next';
import { QueryParamProvider } from 'use-query-params';
import { ThemeProvider } from 'styled-components';
import _ from 'lodash';
import React, { Component } from 'react';

import { datadogRum } from '@datadog/browser-rum';

import i18n from '../i18n';
import theme from '../theme';

import { config as appConfig } from '../config';
import { config as cryptrConfig } from '../core/cryptr';

import IntercomContainer from '../commons/Intercom';

import { InpulseGlobalStyle } from '../globalStyles';
import { isAuthenticated } from '../actions/user';
import { mobileCheck } from '../commons/utils/mobile';
import AskResetPassword from './sign-in/components/askResetPassword/index';
import Invite from './invite';
import LoginComponent from './sign-in/components/login';
import MobileHomePage from './MobileHomePage';
import Redirect from './redirect';
import ResetPassword from './sign-in/components/resetPassword/index';
import Secure from './Secure';
import SignInContainer from './sign-in/sign-in-container';
import StyledHome from './InpulseHomeStyle';

import { getClientInfo } from '../selectors/client';
import { removeLocalStorageItem } from '../commons/utils/localStorage';

import mixpanelService from '../providers/mixpanel';
import sentryService from '../providers/sentry';

class App extends Component {
  handleAuthenticatedChange() {
    removeLocalStorageItem('loopbackAccessToken');
    removeLocalStorageItem('userId');
    removeLocalStorageItem('isCentralMode');
    window.location.reload();
  }

  async componentDidMount() {
    if (!appConfig.isProduction) {
      console.log('ENV: ', process.env);
    }

    const loopbackAccessToken = localStorage.getItem('loopbackAccessToken');
    const userId = localStorage.getItem('userId');
    if (loopbackAccessToken !== null && userId !== null) {
      await this.props.isAuthenticated();
    }
  }

  identify() {
    const user = this.props.user;
    const client = this.props.client;

    if (
      user.firstName !== undefined &&
      user.lastName !== undefined &&
      user.id !== undefined &&
      user.email !== undefined &&
      !_.isEmpty(client)
    ) {
      const userInformation = {
        id: user.id,
        email: user.email,
        clientId: user.clientId,
        isInpulse: user.isDeepsight,
        appVersion: process.env.REACT_APP_GIT_SHA,
        name: user.firstName + ' ' + user.lastName,
        clientStatus: client.clientStatus,
        clientName: client.clientName,
        role: _.get(user, 'lnkAccountRoleAccountrel.name', 'unknown'),
      };

      // Configure Sentry from user session
      sentryService.scope(userInformation);

      // Configure MixPanel from user session
      if (process.env.REACT_APP_MIX_PANEL_PROJECT_ID) {
        const mixPanel = mixpanelService.getInstance();

        mixPanel.identity(userInformation);
      }

      /**
       * Temporary code to use datadog-rum in production & staging only for rapha inpulse account
       *
       * The goal is to evaluate the tool and decide if we are ready to use it at full scale
       */
      const authorizedEmailRegexp = /^rapha-(.*)@inpulse.ai$/;

      if (authorizedEmailRegexp.test(user.email)) {
        datadogRum.init({
          applicationId: 'bbc49ad7-de5d-42d0-a419-261faf2485d3',
          clientToken: 'pub1f4155eff210949651804ac0cb8a01d1',
          site: 'datadoghq.eu',
          service: 'inpulse',
          env: process.env.NODE_ENV,
          // Specify a version number to identify the deployed version of your application in Datadog
          // version: '1.0.0',
          sessionSampleRate: 100,
          sessionReplaySampleRate: 0,
          trackUserInteractions: false,
          trackResources: true,
          trackLongTasks: false,
          defaultPrivacyLevel: 'mask-user-input',
        });

        datadogRum.setUser(userInformation);
      }
    }
  }

  render() {
    //rerender on each url change
    let isAuthenticated = false;
    if (localStorage.getItem('loopbackAccessToken') && localStorage.getItem('userId')) {
      isAuthenticated = true;
      if (
        process.env.NODE_ENV === 'production' &&
        process.env.REACT_APP_BUILD_ENV === 'production'
      ) {
        this.identify();
      }
    }
    const isMobile = mobileCheck(navigator, window);
    const mobileRestriction = false;
    if (mobileRestriction && isMobile) {
      return <MobileHomePage />;
    }
    return (
      <CryptrProvider {...cryptrConfig}>
        <ThemeProvider theme={theme}>
          <I18nextProvider i18n={i18n}>
            <IntercomContainer isAuthenticated={isAuthenticated}>
              <BrowserRouter>
                <QueryParamProvider ReactRouterRoute={Route}>
                  <Switch>
                    <Route
                      path={['/login', '/ask-password-reset', '/password-reset']}
                      render={() => (
                        <SignInContainer isAuthenticated={isAuthenticated}>
                          <Switch>
                            <Route
                              path="/login"
                              render={(props) => <LoginComponent {...props} />}
                              exact
                            />

                            <Route
                              path="/ask-password-reset"
                              render={(props) => <AskResetPassword {...props} />}
                            />

                            <Route
                              path="/password-reset"
                              render={(props) => <ResetPassword {...props} />}
                            />
                          </Switch>
                        </SignInContainer>
                      )}
                      exact
                    />
                    <Route path={['/Invite']} render={(props) => <Invite {...props} />} exact />
                    <Route path={['/redirect']} render={(props) => <Redirect {...props} />} exact />
                    <Secure
                      component={StyledHome}
                      handleAuthenticatedChange={this.handleAuthenticatedChange.bind(this)}
                      isAuthenticated={isAuthenticated}
                      path="/"
                    />
                  </Switch>
                </QueryParamProvider>
              </BrowserRouter>
            </IntercomContainer>
          </I18nextProvider>
          <InpulseGlobalStyle />
        </ThemeProvider>
      </CryptrProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  isAuthenticated: (id) => {
    dispatch(isAuthenticated(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
