import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';
import sortActionsList from '@commons/utils/sortActionsList';

import {
  canCancelOrderReception,
  canChangeOrderStatusToDraft,
  canChangeOrderStatusToSent,
  canCreateDraftOrder,
  canDeleteDraftOrder,
  canDeleteSentOrder,
  canDuplicateOrder,
  canSetCreditRequestProcessed,
} from '@selectors/actions/orderActions';

import { ORDER_PREPARATION_STATUS, ORDER_STATUS } from '../components/OrderForm/constants/status';
import GroupOrderModal from '../components/GroupOrderModal';

export function getActions(props) {
  const {
    stores,
    hasCreateOrdersXlsProps,
    handleNewOrder,
    loadOrders,
    handleOrdersListExport,
    handleOrdersContentExport,
    handleOrdersAnomaliesExport,
    handleOrdersAggregatedExport,
    locations,
    reloadOrders,
    setIsRetrievingData,
    setShouldRetrieveData,
    ordersSelected,
    markAsSent,
    deleteOrder,
    deleteSentOrder,
    cancelReception,
    markAsDraft,
    markAsPreparing,
    markAsPrepared,
    setCreditRequestProcessed,
    authorizedActions,
    setEdiSettingsErrorModalParams,
    isForCentralKitchenPreparation,
    // Import action callbacks
    getMultipleOrderCreationTemplate,
    openOrdersImportModal,
  } = props;

  const hasSelectedItems = !!ordersSelected.length;
  const isOneSelected = ordersSelected.length === 1;

  const areSelectedOrdersDraft =
    hasSelectedItems &&
    ordersSelected.every((order) => [ORDER_STATUS.DRAFT].includes(parseInt(order.status)));

  const allSelectedOrdersAreNotPrepared = !ordersSelected.some(({ preparationStatus }) =>
    [
      ORDER_PREPARATION_STATUS.COMPLIANT_PREPARED,
      ORDER_PREPARATION_STATUS.NON_COMPLIANT_PREPARED,
    ].includes(parseInt(preparationStatus)),
  );

  const allSelectedOrdersArePrepared = ordersSelected.every(({ preparationStatus }) =>
    [
      ORDER_PREPARATION_STATUS.COMPLIANT_PREPARED,
      ORDER_PREPARATION_STATUS.NON_COMPLIANT_PREPARED,
    ].includes(parseInt(preparationStatus)),
  );

  const areSelectedOrdersNonCompliantOrCreditProcessed =
    !hasSelectedItems ||
    ordersSelected.some((order) =>
      [ORDER_STATUS.NON_COMPLIANT, ORDER_STATUS.CREDIT_REQUEST_PROCESSED].includes(
        parseInt(order.status),
      ),
    );

  const allSelectedOrdersAreNonCompliant =
    hasSelectedItems &&
    !ordersSelected.some((order) => parseInt(order.status) != ORDER_STATUS.NON_COMPLIANT);

  const areSelectedOrdersIncompleteCompliantNonCompliantOrReceived =
    hasSelectedItems &&
    ordersSelected.every((order) =>
      [
        ORDER_STATUS.INCOMPLETE,
        ORDER_STATUS.COMPLIANT,
        ORDER_STATUS.NON_COMPLIANT,
        ORDER_STATUS.RECEPTION,
        ORDER_STATUS.CREDIT_REQUEST_PROCESSED,
      ].includes(parseInt(order.status)),
    );

  const areSelectedOrdersSentReceived =
    hasSelectedItems &&
    ordersSelected.every((order) =>
      [ORDER_STATUS.SENT, ORDER_STATUS.RECEPTION].includes(parseInt(order.status)),
    );

  const areSelectedOrdersInvoicedPreparations =
    hasSelectedItems &&
    ordersSelected.some((order) =>
      [
        ORDER_PREPARATION_STATUS.COMPLIANT_INVOICED,
        ORDER_PREPARATION_STATUS.NON_COMPLIANT_INVOICED,
      ].includes(parseInt(order.preparationStatus)),
    );

  const actions = [
    {
      id: 'action-mark-as-sent',
      actionLabel: i18next.t('ORDERS.ORDERS.ACTION_MARK_AS_SENT'),
      isHidden: () => !areSelectedOrdersDraft || !canChangeOrderStatusToSent(authorizedActions),
      handleAction: () => markAsSent(ordersSelected),
      actionIcon: () => '/images/inpulse/pen-black-small.svg',
    },
    {
      id: 'action-mark-as-prepared',
      actionLabel: i18next.t('ORDERS.ORDERS.ACTION_MARK_AS_PREPARED'),
      isHidden: () => !isForCentralKitchenPreparation || !hasSelectedItems,
      isDisabled: () => !allSelectedOrdersAreNotPrepared || areSelectedOrdersInvoicedPreparations,
      handleAction: () => markAsPrepared(ordersSelected),
      actionIcon: () =>
        !allSelectedOrdersAreNotPrepared || areSelectedOrdersInvoicedPreparations
          ? '/images/inpulse/pen-lmgrey-small.svg'
          : '/images/inpulse/pen-black-small.svg',
      disabledTooltipText: () => {
        if (areSelectedOrdersInvoicedPreparations)
          return i18next.t('ORDERS.ORDERS.ACTION_UNABLE_TO_CHANGE_INVOICED_PREPARATIONS');

        return '';
      },
    },
    {
      id: 'action-change-status-to-draft',
      actionLabel: isOneSelected
        ? i18next.t('ORDERS.ORDERS.ACTION_CHANGE_STATUS_TO_DRAFT_SINGULAR')
        : i18next.t('ORDERS.ORDERS.ACTION_CHANGE_STATUS_TO_DRAFT_PLURAL'),
      isHidden: () =>
        isForCentralKitchenPreparation ||
        !areSelectedOrdersSentReceived ||
        !canChangeOrderStatusToDraft(authorizedActions),
      isDisabled: () => areSelectedOrdersInvoicedPreparations,
      disabledTooltipText: () =>
        i18next.t('ORDERS.ORDERS.ACTION_UNABLE_TO_CHANGE_INVOICED_PREPARATIONS'),
      handleAction: () => markAsDraft(ordersSelected),
      actionIcon: () =>
        areSelectedOrdersInvoicedPreparations
          ? '/images/inpulse/pen-lmgrey-small.svg'
          : '/images/inpulse/pen-black-small.svg',
    },
    {
      id: 'action-change-preparationStatus-to-preparing',
      actionLabel: i18next.t('ORDERS.ORDERS.ACTION_CHANGE_PREPARATION_STATUS_TO_PREPARING'),
      isHidden: () => !isForCentralKitchenPreparation || !hasSelectedItems,
      isDisabled: () => !allSelectedOrdersArePrepared,
      handleAction: () => markAsPreparing(ordersSelected),
      actionIcon: () =>
        !allSelectedOrdersArePrepared
          ? '/images/inpulse/pen-lmgrey-small.svg'
          : '/images/inpulse/pen-black-small.svg',
    },
    {
      id: 'action-set-credit-request-processed',
      actionLabel: i18next.t('ORDERS.ORDERS.ACTION_SET_CREDIT_REQUEST_PROCESSED'),
      isHidden: () =>
        isForCentralKitchenPreparation ||
        !allSelectedOrdersAreNonCompliant ||
        !canSetCreditRequestProcessed(authorizedActions),
      handleAction: () => setCreditRequestProcessed(ordersSelected),
      actionIcon: () => '/images/inpulse/pen-black-small.svg',
    },
    {
      id: 'action-cancel-reception',
      actionLabel: isOneSelected
        ? i18next.t('ORDERS.ORDERS.ACTION_CANCEL_RECEPTION')
        : i18next.t('ORDERS.ORDERS.ACTION_CANCEL_MULTIPLE_RECEPTIONS'),
      isHidden: () =>
        isForCentralKitchenPreparation ||
        !areSelectedOrdersIncompleteCompliantNonCompliantOrReceived ||
        !canCancelOrderReception(authorizedActions),
      isDisabled: () => areSelectedOrdersInvoicedPreparations,
      disabledTooltipText: () =>
        i18next.t('ORDERS.ORDERS.ACTION_UNABLE_TO_CHANGE_INVOICED_PREPARATIONS'),
      handleAction: () => cancelReception(ordersSelected),
      actionIcon: () =>
        areSelectedOrdersInvoicedPreparations
          ? '/images/inpulse/close-dgrey-small.svg'
          : '/images/inpulse/close-black-small.svg',
    },
    {
      id: 'delete',
      actionLabel: isOneSelected
        ? i18next.t('ORDERS.ORDERS.LIST_DELETE_ORDER')
        : i18next.t('ORDERS.ORDERS.LIST_DELETE_ORDERS'),
      isHidden: () =>
        isForCentralKitchenPreparation ||
        !areSelectedOrdersDraft ||
        !canDeleteDraftOrder(authorizedActions),
      handleAction: () => deleteOrder(ordersSelected),
      actionIcon: () => '/images/inpulse/delete-black-small.svg',
    },
    {
      id: 'export',
      actionLabel: isOneSelected
        ? i18next.t('ORDERS.ORDERS.EXPORT_ORDER')
        : hasSelectedItems
        ? i18next.t('ORDERS.ORDERS.EXPORT_SELECTION')
        : i18next.t('GENERAL.EXPORT_LIST'),

      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      displayActionInLine: false,
      handleAction: handleOrdersListExport,
    },
    {
      id: 'export-content',
      actionLabel: isOneSelected
        ? i18next.t('GENERAL.EXPORT_CONTENT_SINGULAR')
        : i18next.t('GENERAL.EXPORT_CONTENT_PLURAL'),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      handleAction: handleOrdersContentExport,
    },
    {
      id: 'export-aggregated',
      actionLabel: i18next.t('ORDERS.ORDERS.EXPORT_AGGREGATED'),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      handleAction: handleOrdersAggregatedExport,
    },
    {
      id: 'export-anomalies',
      actionLabel: i18next.t('ORDERS.ORDERS.EXPORT_ANOMALIES'),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      handleAction: handleOrdersAnomaliesExport,
      isHidden: () =>
        isForCentralKitchenPreparation || !areSelectedOrdersNonCompliantOrCreditProcessed,
    },
    {
      id: 'delete-sent',
      actionLabel: i18next.t('ORDERS.ORDERS.LIST_DELETE_ORDER'),
      isHidden: () =>
        isForCentralKitchenPreparation ||
        !isOneSelected ||
        ![ORDER_STATUS.SENT].includes(parseInt(ordersSelected[0].status)) ||
        !canDeleteSentOrder(authorizedActions),
      handleAction: () => deleteSentOrder(ordersSelected[0]),
      isDisabled: () => areSelectedOrdersInvoicedPreparations,
      disabledTooltipText: () =>
        i18next.t('ORDERS.ORDERS.ACTION_UNABLE_TO_CHANGE_INVOICED_PREPARATIONS'),
      actionIcon: () =>
        areSelectedOrdersInvoicedPreparations
          ? '/images/inpulse/delete-dgrey-small.svg'
          : '/images/inpulse/delete-black-small.svg',
    },
    // Import actions
    {
      id: 'download-multiple-creation-file-template',
      actionLabel: i18next.t('ORDERS.ORDERS.DOWNLOAD_TEMPLATE_BATCH_CREATE_ACTION'),
      isHidden: () => !hasCreateOrdersXlsProps || !canCreateDraftOrder(authorizedActions),
      handleAction: getMultipleOrderCreationTemplate,
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
    },
    {
      id: 'import-multiple-order-creation-file',
      actionLabel: i18next.t('ORDERS.ORDERS.IMPORT_TEMPLATE'),
      isHidden: () => !hasCreateOrdersXlsProps || !canCreateDraftOrder(authorizedActions),
      handleAction: openOrdersImportModal,
      actionIcon: () => '/images/inpulse/file-upload-black-small.svg',
    },
  ];

  if (!isForCentralKitchenPreparation) {
    actions.push({
      id: 'create',
      actionLabel: i18next.t('ADMIN.PRODUCTS.EMPTY_STATE_CREATE'),
      fixed: canCreateDraftOrder(authorizedActions),
      handleAction: () => false,
      render: () => (
        <Button
          color={'inpulse-default'}
          handleClick={() =>
            handleNewOrder(
              props,
              stores,
              loadOrders,
              setIsRetrievingData,
              setShouldRetrieveData,
              setEdiSettingsErrorModalParams,
            )
          }
          icon={'/images/inpulse/add-white-small.svg'}
          label={i18next.t('GENERAL.CREATE')}
        />
      ),
      isHidden: () => !canCreateDraftOrder(authorizedActions),
    });
  }

  if (!!locations.length && props.groupedOrder && !ordersSelected.length) {
    actions.unshift({
      id: 'special-group',
      actionLabel: i18next.t('ORDERS.ORDERS.LIST_GROUP_ORDER_ACTION'),
      actionIcon: () => '/images/inpulse/send-black-small.svg',
      handleAction: () =>
        props.openModal({
          component: GroupOrderModal,
          deactivateBackgroundClick: true,
          send: props.send,
          reloadOrders: reloadOrders,
          setIsRetrievingData,
        }),
    });
  }

  return sortActionsList(actions);
}

export function getRowAction({
  markAsSent,
  deleteOrder,
  handleOrdersListExport,
  handleOrdersContentExport,
  handleOrdersAnomaliesExport,
  handleOrdersAggregatedExport,
  deleteSentOrder,
  cancelReception,
  markAsDraft,
  markAsPreparing,
  markAsPrepared,
  setCreditRequestProcessed,
  generateInvoice,
  duplicateOrder,
  authorizedActions,
  hasGenerateInvoiceProps,
  isForCentralKitchenPreparation,
}) {
  const isOrderInvoicedPreparation = ({ preparationStatus }) =>
    [
      ORDER_PREPARATION_STATUS.COMPLIANT_INVOICED,
      ORDER_PREPARATION_STATUS.NON_COMPLIANT_INVOICED,
    ].includes(parseInt(preparationStatus));

  const rowActions = [
    {
      id: 'action-mark-as-sent',
      actionLabel: () => i18next.t('ORDERS.ORDERS.ACTION_MARK_AS_SENT'),
      isHidden: (order) =>
        ![ORDER_STATUS.DRAFT].includes(parseInt(order.status)) ||
        !canChangeOrderStatusToSent(authorizedActions),
      handleAction: markAsSent,
      actionIcon: () => '/images/inpulse/pen-black-small.svg',
    },
    {
      id: 'action-mark-as-prepared',
      actionLabel: () => i18next.t('ORDERS.ORDERS.ACTION_MARK_AS_PREPARED'),
      isHidden: () => !isForCentralKitchenPreparation,
      isDisabled: ({ preparationStatus }) =>
        [
          ORDER_PREPARATION_STATUS.COMPLIANT_PREPARED,
          ORDER_PREPARATION_STATUS.NON_COMPLIANT_PREPARED,
          ORDER_PREPARATION_STATUS.COMPLIANT_INVOICED,
          ORDER_PREPARATION_STATUS.NON_COMPLIANT_INVOICED,
        ].includes(parseInt(preparationStatus)),
      actionIcon: ({ preparationStatus }) =>
        [
          ORDER_PREPARATION_STATUS.COMPLIANT_PREPARED,
          ORDER_PREPARATION_STATUS.NON_COMPLIANT_PREPARED,
          ORDER_PREPARATION_STATUS.COMPLIANT_INVOICED,
          ORDER_PREPARATION_STATUS.NON_COMPLIANT_INVOICED,
        ].includes(parseInt(preparationStatus))
          ? '/images/inpulse/pen-lmgrey-small.svg'
          : '/images/inpulse/pen-black-small.svg',
      disabledTooltipText: (order) => {
        if (isOrderInvoicedPreparation(order))
          return i18next.t('ORDERS.ORDERS.ACTION_UNABLE_TO_CHANGE_INVOICED_PREPARATIONS');

        return '';
      },
      handleAction: (order) => markAsPrepared([order]),
    },
    {
      id: 'action-change-status-to-draft',
      actionLabel: () => i18next.t('ORDERS.ORDERS.ACTION_CHANGE_STATUS_TO_DRAFT_SINGULAR'),
      isHidden: (order) =>
        isForCentralKitchenPreparation ||
        ![ORDER_STATUS.SENT, ORDER_STATUS.RECEPTION].includes(parseInt(order.status)) ||
        !canChangeOrderStatusToDraft(authorizedActions),
      isDisabled: (order) => isOrderInvoicedPreparation(order),
      disabledTooltipText: () =>
        i18next.t('ORDERS.ORDERS.ACTION_UNABLE_TO_CHANGE_INVOICED_PREPARATIONS'),
      actionIcon: (order) =>
        isOrderInvoicedPreparation(order)
          ? '/images/inpulse/pen-dgrey-small.svg'
          : '/images/inpulse/pen-black-small.svg',
      handleAction: (order) => markAsDraft([order]),
    },
    {
      id: 'action-change-preparationStatus-to-preparing',
      actionLabel: () => i18next.t('ORDERS.ORDERS.ACTION_CHANGE_PREPARATION_STATUS_TO_PREPARING'),
      isHidden: () => !isForCentralKitchenPreparation,
      isDisabled: ({ preparationStatus }) =>
        parseInt(preparationStatus) <= ORDER_PREPARATION_STATUS.PREPARING ||
        isOrderInvoicedPreparation({ preparationStatus }),
      handleAction: (order) => markAsPreparing([order]),
      actionIcon: ({ preparationStatus }) =>
        parseInt(preparationStatus) <= ORDER_PREPARATION_STATUS.PREPARING ||
        isOrderInvoicedPreparation({ preparationStatus })
          ? '/images/inpulse/pen-lmgrey-small.svg'
          : '/images/inpulse/pen-black-small.svg',
      disabledTooltipText: (order) => {
        if (isOrderInvoicedPreparation(order))
          return i18next.t('ORDERS.ORDERS.ACTION_UNABLE_TO_CHANGE_INVOICED_PREPARATIONS');

        return '';
      },
    },
    {
      id: 'action-generate-preparation-invoice',
      actionLabel: () => i18next.t('ORDERS.ORDERS.ACTION_GENERATE_PREPARATION_INVOICE'),
      isHidden: () => !isForCentralKitchenPreparation,
      isDisabled: ({ preparationStatus }) =>
        ![
          ORDER_PREPARATION_STATUS.COMPLIANT_PREPARED,
          ORDER_PREPARATION_STATUS.NON_COMPLIANT_PREPARED,
        ].includes(parseInt(preparationStatus)) || !hasGenerateInvoiceProps,
      handleAction: (order) => generateInvoice(order),
      actionIcon: ({ preparationStatus }) =>
        ![
          ORDER_PREPARATION_STATUS.COMPLIANT_PREPARED,
          ORDER_PREPARATION_STATUS.NON_COMPLIANT_PREPARED,
        ].includes(parseInt(preparationStatus)) || !hasGenerateInvoiceProps
          ? '/images/inpulse/receipt-lmgrey-small.svg'
          : '/images/inpulse/receipt-black-small.svg',
    },
    {
      id: 'action-duplicate-order',
      actionLabel: () => i18next.t('ORDERS.ORDERS.DUPLICATE_ORDER'),
      isHidden: () => !canDuplicateOrder(authorizedActions),
      handleAction: duplicateOrder,
      actionIcon: () => '/images/inpulse/add-black-small.svg',
    },
    {
      id: 'action-set-credit-request-processed',
      actionLabel: () => i18next.t('ORDERS.ORDERS.ACTION_SET_CREDIT_REQUEST_PROCESSED'),
      isHidden: ({ status }) =>
        isForCentralKitchenPreparation ||
        parseInt(status) != ORDER_STATUS.NON_COMPLIANT ||
        !canSetCreditRequestProcessed(authorizedActions),
      handleAction: (order) => setCreditRequestProcessed([order]),
      actionIcon: () => '/images/inpulse/pen-black-small.svg',
    },
    {
      id: 'action-cancel-reception',
      actionLabel: () => i18next.t('ORDERS.ORDERS.ACTION_CANCEL_RECEPTION'),
      isHidden: (order) =>
        ![
          ORDER_STATUS.INCOMPLETE,
          ORDER_STATUS.COMPLIANT,
          ORDER_STATUS.NON_COMPLIANT,
          ORDER_STATUS.CREDIT_REQUEST_PROCESSED,
        ].includes(parseInt(order.status)) ||
        !canCancelOrderReception(authorizedActions) ||
        isForCentralKitchenPreparation,
      isDisabled: (order) => isOrderInvoicedPreparation(order),
      disabledTooltipText: () =>
        i18next.t('ORDERS.ORDERS.ACTION_UNABLE_TO_CHANGE_INVOICED_PREPARATIONS'),
      actionIcon: (order) =>
        isOrderInvoicedPreparation(order)
          ? '/images/inpulse/close-dgrey-small.svg'
          : '/images/inpulse/close-black-small.svg',
      handleAction: (order) => cancelReception([order]),
    },
    {
      id: 'delete',
      actionLabel: () => i18next.t('ORDERS.ORDERS.LIST_DELETE_ORDER'),
      isHidden: (order) =>
        isForCentralKitchenPreparation ||
        ![ORDER_STATUS.DRAFT].includes(parseInt(order.status)) ||
        !canDeleteDraftOrder(authorizedActions),
      isDisabled: (order) => isOrderInvoicedPreparation(order),
      disabledTooltipText: () =>
        i18next.t('ORDERS.ORDERS.ACTION_UNABLE_TO_CHANGE_INVOICED_PREPARATIONS'),
      actionIcon: () => '/images/inpulse/close-black-small.svg',
      handleAction: (order) => deleteOrder([order]),
    },
    {
      id: 'export',
      actionLabel: () => i18next.t('ORDERS.ORDERS.EXPORT_ORDER'),
      actionIcon: () => '/images/inpulse/file-download-black-thick.svg',
      handleAction: handleOrdersListExport,
    },
    {
      id: 'export-content',
      actionLabel: () => i18next.t('GENERAL.EXPORT_CONTENT_SINGULAR'),
      handleAction: handleOrdersContentExport,
      actionIcon: () => '/images/inpulse/file-download-black-thick.svg',
    },
    {
      id: 'export-aggregated',
      actionLabel: () => i18next.t('ORDERS.ORDERS.EXPORT_AGGREGATED'),
      handleAction: handleOrdersAggregatedExport,
      actionIcon: () => '/images/inpulse/file-download-black-thick.svg',
    },
    {
      id: 'export-anomalies',
      actionLabel: () => i18next.t('ORDERS.ORDERS.EXPORT_ANOMALIES'),
      isHidden: (order) =>
        isForCentralKitchenPreparation ||
        ![ORDER_STATUS.NON_COMPLIANT, ORDER_STATUS.CREDIT_REQUEST_PROCESSED].includes(
          parseInt(order.status),
        ),
      handleAction: handleOrdersAnomaliesExport,
      actionIcon: () => '/images/inpulse/file-download-black-thick.svg',
    },
    {
      id: 'delete-sent',
      actionLabel: () => i18next.t('ORDERS.ORDERS.LIST_DELETE_ORDER'),
      isHidden: (order) =>
        isForCentralKitchenPreparation ||
        ![ORDER_STATUS.SENT, ORDER_STATUS.RECEPTION].includes(parseInt(order.status)) ||
        !canDeleteSentOrder(authorizedActions),
      isDisabled: (order) => isOrderInvoicedPreparation(order),
      disabledTooltipText: () =>
        i18next.t('ORDERS.ORDERS.ACTION_UNABLE_TO_CHANGE_INVOICED_PREPARATIONS'),
      actionIcon: (order) =>
        isOrderInvoicedPreparation(order)
          ? '/images/inpulse/delete-dgrey-small.svg'
          : '/images/inpulse/delete-black-small.svg',
      handleAction: deleteSentOrder,
    },
  ];

  return sortActionsList(rowActions);
}

export default {
  getActions,
  getRowAction,
};
