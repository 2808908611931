import { isEmpty, head } from 'lodash';
import i18next from 'i18next';

import { ENUM_CUSTOM_FILTERS } from '@commons/utils/filtersFetches';

import centralService from '@services/central';

import { fetchCategoriesAndSet } from '@stocks/StocksAnalytics/utils/commons';

const getIngredientsPastStockFilters = async (
  clientId,
  stores,
  { setCategoriesToChoose, customFilterIsStrategic, customFilterSingleCategory, showErrorMessage },
) => {
  const clientCategories = await fetchCategoriesAndSet(
    clientId,
    setCategoriesToChoose,
    showErrorMessage,
  );

  const updatedFilters = {
    stores: {
      activeList: stores,
      list: stores,
      selectedStore: head(stores),
    },
    [ENUM_CUSTOM_FILTERS.TOGGLE_IS_STRATEGIC]: {
      ...customFilterIsStrategic,
    },
    [ENUM_CUSTOM_FILTERS.SINGLE_SELECT_CATEGORY]: !isEmpty(clientCategories)
      ? {
          ...customFilterSingleCategory,
          list: clientCategories,
        }
      : null,
  };

  return updatedFilters;
};

export const getCentralKitchenRecipesCategories = async (clientId, showErrorMessage) => {
  try {
    const clientRecipesCategories = await centralService.getCentralKitchenRecipesCategoriesOfClient(
      clientId,
    );

    const formattedCategories = clientRecipesCategories.map((category) => ({
      ...category,
      name: category.name || i18next.t('GENERAL.NONE_VALUE'),
      value: category.name || i18next.t('GENERAL.NONE_VALUE'),
    }));

    return formattedCategories;
  } catch {
    showErrorMessage(i18next.t('STOCKS.CURRENT_STOCKS.ANALYSIS_CATEGORIES_FETCH_ERROR'));
    return [];
  }
};

const getRecipesPastStockFilters = async (
  clientId,
  stores,
  { setCategoriesToChoose, setSelectedCategory, customFilterSingleCategory, showErrorMessage },
) => {
  try {
    const clientRecipesCategories = await getCentralKitchenRecipesCategories(
      clientId,
      showErrorMessage,
    );

    setCategoriesToChoose(clientRecipesCategories);
    setSelectedCategory(head(clientRecipesCategories));

    const updatedFilters = {
      stores: {
        activeList: stores,
        list: stores,
        selectedStore: head(stores),
      },
      [ENUM_CUSTOM_FILTERS.SINGLE_SELECT_CATEGORY]: !isEmpty(clientRecipesCategories)
        ? {
            ...customFilterSingleCategory,
            list: clientRecipesCategories,
            defaultSelectedItem: head(clientRecipesCategories),
            selectedItem: head(clientRecipesCategories),
          }
        : null,
    };

    return updatedFilters;
  } catch {
    showErrorMessage(i18next.t('STOCKS.CURRENT_STOCKS.ANALYSIS_CATEGORIES_FETCH_ERROR'));
    return [];
  }
};

export default { getIngredientsPastStockFilters, getRecipesPastStockFilters };
