import React, { useEffect, useState } from 'react';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftjsToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { EditorContainer } from './styledComponents';

import theme from '../../theme';

const WRAPPER_STYLE = {
  border: `1px solid ${theme.colors.greys.light}`,
  borderRadius: '8px',
  minHeight: '160px',
};

// WYSIWYG EDITOR DOCUMENTATION AND DEMO => https://jpuri.github.io/react-draft-wysiwyg/#/
// Basic implementation for now, we can customize it later with product's specific settings
export const WysiwygEditor = (props) => {
  const {
    content,
    setter,
    isDisabled,
    toolbarHidden = true,
    toolbar,
    stripPastedStyles = false,
    onlyReturnText = false,
    onlyReturnTextJoinCharacters = '<br />',
  } = props;

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (!content) {
      return;
    }

    const blocksFromHtml = htmlToDraft(content);
    const { contentBlocks, entityMap } = blocksFromHtml;

    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

    setEditorState(EditorState.createWithContent(contentState));
  }, []);

  useEffect(() => {
    const contentToRaw = convertToRaw(editorState.getCurrentContent());

    // In some cases w don't want to return the html, we want to return the text
    if (onlyReturnText) {
      const fullText = contentToRaw.blocks.map(({ text }) => text).join('\n');

      const fullTextWithLineBreak = fullText + '\n';

      setter(fullTextWithLineBreak.replace(/\n/g, onlyReturnTextJoinCharacters));
      return;
    }

    const draftHtml = draftjsToHtml(contentToRaw);
    setter(draftHtml.replace(/\n/g, ''));
  }, [editorState]);

  return (
    <EditorContainer isDisabled={isDisabled}>
      <Editor
        editorClassName="editor-class"
        editorState={editorState}
        readOnly={isDisabled}
        stripPastedStyles={stripPastedStyles}
        toolbar={toolbar}
        toolbarClassName="toolbar-class"
        toolbarHidden={toolbarHidden}
        wrapperClassName="wrapper-class"
        wrapperStyle={WRAPPER_STYLE}
        onEditorStateChange={setEditorState}
      />
    </EditorContainer>
  );
};

export default WysiwygEditor;
