import { connect } from 'react-redux';
import { get, isEmpty, head, sortBy } from 'lodash';
import { getTimezonesForCountry } from 'countries-and-timezones';
import { useHistory } from 'react-router-dom';
import i18next from 'i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { closeGenericModal, openGenericModal, refreshGenericModal } from '@actions/modal';
import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { Button } from '@commons/utils/styledLibraryComponents';
import { ENUM_MODULE_NAME } from '@commons/utils/features';
import { formatNewItemsOfDropdownSelection } from '@commons/utils/format';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getIsoCountryCode } from '@commons/utils/countryCode';
import { ISO_FRANCE } from '@commons/constants/country';
import NavBar from '@commons/NavigationBar';
import normalizeStringValue from '@commons/utils/normalizeStringValue';

import { getClientInfo } from '@selectors/client';

import { brand as brandService } from '@services/brand';
import clientService from '@services/client';
import storeService from '@services/store';

import { DAYS } from '@admin/utils/DropdownItems';

import { CENTRAL_STORE_TYPE_ID, POINT_OF_SALE_STORE_TYPE_ID } from '../utils/DropdownItems';
import InputCardSection from '../components/InputCardSection';
import utilsModalColumns from '../utils/columns';
import utilsModals from '../utils/modals';

import { Container, NavBarContainer, NavigationPage } from './styledComponents';
import utilsStoreCreation, { STORE_DUPLICATION_TYPE } from './utils';

import { getGroupCreationParamsModal } from '@admin/stores/utils/modalUtils';
import { STORE_INPUTS } from '@admin/stores/components/StoreInformations/utils/formInputsConfiguration';

const STORE_ID_CREATE_MODE = 'create';

const EMPTY_STORE_PAYLOAD_INFORMATION = {
  name: null,
  brands: null,
  groups: [],
  retailers: null,
  type: null,
  closingDays: [],
  stockConvention: null,
  phone: null,
  active: true,
  lastMenuDate: null,
  launchDate: null,
  hasRetailers: null,
};

const EMPTY_STORE_PAYLOAD_PRODUCTION = {
  targetProductionWasteRate: null,
  productionStockConvention: 'before_opening',
  productionLossConvention: 'before_opening',
};

const EMPTY_STORE_PAYLOAD_PRODUCT_MIX = {
  lastMenuDate: null,
};

const EMPTY_STORE_PAYLOAD_SETTINGS = {};

const EMPTY_STORE_PAYLOAD_DUPLICATION_SETTINGS = {
  events: null,
};

const EMPTY_CENTRAL_KITCHEN_PRODUCTION_PLANNING_PAYLOAD = {
  consumptionComputation: null,
  showYesterdayStock: false,
};

const EMPTY_STORE_COMPANY = {
  [STORE_INPUTS.COMPANY_NAME]: null,
  [STORE_INPUTS.SIRET]: null,
  [STORE_INPUTS.VAT_NUMBER]: null,
  [STORE_INPUTS.COMPANY_TYPE]: null,
  [STORE_INPUTS.CAPITAL]: null,
  [STORE_INPUTS.COMPANY_REGISTER]: null,
  [STORE_INPUTS.PAYMENT_TERM_IN_DAYS]: null,
};

export const BackOfficeStoresDetails = (props) => {
  const {
    client: { clientId, hasMultipleBrands, storeName, hasMultipleTimezones, defaultTimezone },
    pageLoaded,
    pageLoading,
    showErrorMessage,
    showSuccessMessage,
    match: { path, params },
    refreshGenericModal,
    openGenericModal,
    closeGenericModal,
    location: { state },
  } = props;

  const history = useHistory();

  const storeIdToClone = get(state, 'cloneStoreId', null); // used for store duplication only
  const selectedStoreType = get(state, 'selectedStoreType', null);
  const hasSelectedCentralStoreType =
    !!selectedStoreType && selectedStoreType.id === CENTRAL_STORE_TYPE_ID;

  const EMPTY_STORE_PAYLOAD_LOCATION = {
    additionnalAddress: null,
    location: null,
    country: ISO_FRANCE,
    timezone: defaultTimezone,
    address: null,
    city: null,
    postCode: null,
  };

  const EMPTY_STORE_PAYLOAD_GPS_COORDINATES = {
    hasLatitudeError: null,
    hasLongitudeError: null,
    latitude: null,
    longitude: null,
  };

  const [storeToCloneGroups, setStoreToCloneGroups] = useState([]);

  const [brands, setBrands] = useState([]);
  const [retailers, setRetailers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [isCreation, setIsCreation] = useState(true);
  const [isFormValid, setIsFormValid] = useState(false);

  /** GROUPS */
  const [clientGroups, setClientGroups] = useState([]);
  const [groupCreationInputValue, setGroupCreationInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [storeInformation, setStoreInformation] = useState({
    ...EMPTY_STORE_PAYLOAD_INFORMATION,
    storeType: selectedStoreType && selectedStoreType.id,
  });
  const [productionPlanningSettings, setProductionPlanningSettings] = useState(
    EMPTY_CENTRAL_KITCHEN_PRODUCTION_PLANNING_PAYLOAD,
  );

  const [storeLocation, setStoreLocation] = useState(EMPTY_STORE_PAYLOAD_LOCATION);

  const [displayKitchenForm, setDisplayKitchenForm] = useState(hasSelectedCentralStoreType);
  const [storeGPSCoordinates, setStoreGPSCoordinates] = useState(
    EMPTY_STORE_PAYLOAD_GPS_COORDINATES,
  );
  const [storeCompany, setStoreCompany] = useState(EMPTY_STORE_COMPANY);
  const [storeProduction, setStoreProduction] = useState(EMPTY_STORE_PAYLOAD_PRODUCTION);
  const [storeProductMix, setStoreProductMix] = useState(EMPTY_STORE_PAYLOAD_PRODUCT_MIX);
  const [storeSettings, setStoreSettings] = useState(EMPTY_STORE_PAYLOAD_SETTINGS);
  const [storeDuplicationSettings, setStoreDuplicationSettings] = useState(
    EMPTY_STORE_PAYLOAD_DUPLICATION_SETTINGS,
  );
  const [isDuplication] = useState(!isEmpty(storeIdToClone));

  const [hasLatitudeError, setHasLatitudeError] = useState(false);
  const [hasLongitudeError, setHasLongitudeError] = useState(false);
  const [initialisationLocationValue, setInitialisationLocationValue] = useState(false);
  const [selectableStores, setSelectableStores] = useState([]);
  const [hasHolidayZone, setHasHolidayZone] = useState(true);
  const [countryHasUniqueTimezone, setCountryHasUniqueTimezone] = useState(true);

  // Cloning states
  const [cloneStoreStep, setCloneStoreStep] = useState(null);
  const [storeToCloneFrom, setStoreToCloneFrom] = useState({});
  const [storeToCloneInto, setStoreToCloneInto] = useState({});
  const [cloningType, setCloningType] = useState(null);
  const [cloneDataColumns, setCloneDataColumns] = useState(
    utilsModalColumns.getColumnsSettings({}),
  );

  /** CARD SETTINGS */
  const [errorsByPropertyKey, setErrorsByPropertyKey] = useState({});

  const centralKitchenProductionPlanningSettings =
    utilsStoreCreation.getCentralKitchenProductionPlanningSettings(productionPlanningSettings);

  const storeLocationSettings = utilsStoreCreation.getStoreLocationCardSettings(
    storeLocation,
    locations,
    isCreation,
    hasHolidayZone,
    hasMultipleTimezones,
    countryHasUniqueTimezone,
  );

  // Handle the fetch of brands of client if he has multiple brands
  useEffect(() => {
    pageLoading();

    (async () => {
      await fetchRetailersOfClient(clientId, showErrorMessage);
      await fetchLocationsOfClient(clientId, showErrorMessage);
      await fetchGroupsOfClient(clientId);

      const activeStores = await getStoresOfClient();

      if (isDuplication) {
        const storeToCloneData = activeStores.find((store) => store.id === storeIdToClone);
        setStoreToCloneGroups(get(storeToCloneData, 'mappedGroups', []));
      }

      const currentSelectableStores = activeStores.filter((store) => store.id !== params.id);

      setSelectableStores(currentSelectableStores);

      const hasGroups = currentSelectableStores.some(
        (store) => get(store, 'mappedGroups', []).length > 0,
      );
      const shouldDisplayLocations = locations.length >= 1;

      const columns = utilsModalColumns.getColumnsSettings({
        hasMultipleBrands,
        hasGroups,
        shouldDisplayLocations,
      });

      setCloneDataColumns(columns);
    })();

    if (!hasMultipleBrands) {
      pageLoaded();
      return;
    }

    (async function loadData() {
      await fetchBrandsOfClient(clientId, showErrorMessage);
      pageLoaded();
    })();
  }, [clientId, hasMultipleBrands]);

  useEffect(() => {
    const result = get(params, 'id') === STORE_ID_CREATE_MODE;

    setIsCreation(result);
  }, [path]);

  useEffect(() => {
    if (isCreation && !isDuplication) {
      return;
    }

    fetchStoreDetail(isDuplication ? storeIdToClone : params.id);
  }, [isCreation, isDuplication]);

  useEffect(() => {
    if (!storeLocation) {
      return;
    }

    const countryHasHolidayZone = getIfCountryHasHolidayZone();
    setHasHolidayZone(countryHasHolidayZone);

    if (
      !countryHasHolidayZone &&
      ![ISO_FRANCE].includes(storeLocation.country) &&
      !!storeLocation.holidayZone
    ) {
      setStoreLocation({ ...storeLocation, holidayZone: null });
    }
  }, [storeLocation]);

  useEffect(() => {
    const hasValidHolidayZone = getIfHolidayZoneIsValid();

    const isStoreTypeValid = displayKitchenForm ? true : storeInformation.type;

    const isProductionPlanningSettingsValid =
      !displayKitchenForm || productionPlanningSettings.consumptionComputation;

    const updatedStoreInformationSettings = utilsStoreCreation.getStoreInformationCardSettings(
      {
        storeInformation,
        brands,
        retailers,
        clientGroups,
        handleGroupsCreation,
        hasMultipleBrands,
        errorsByPropertyKey,
      },
      isCreation,
      displayKitchenForm,
    );

    const hasSomeErrors = updatedStoreInformationSettings.inputs.some(({ hasError }) => hasError);

    setIsFormValid(
      !(
        !storeInformation.name ||
        !storeInformation.stockConvention ||
        !storeInformation.launchDate ||
        (!isCreation && !storeInformation.partnerId) ||
        !isStoreTypeValid ||
        !isProductionPlanningSettingsValid ||
        !storeLocation.adress ||
        !storeLocation.postCode ||
        !storeLocation.city ||
        !storeLocation.country ||
        !storeGPSCoordinates.latitude ||
        !storeGPSCoordinates.longitude ||
        !storeLocation.timezone ||
        !hasValidHolidayZone ||
        !storeProduction.productionLossConvention ||
        !storeProduction.productionStockConvention ||
        hasLongitudeError ||
        hasLatitudeError
      ) && !hasSomeErrors,
    );
  }, [
    storeInformation,
    storeLocation,
    storeProduction,
    clientGroups,
    storeGPSCoordinates,
    productionPlanningSettings,
    errorsByPropertyKey,
  ]);

  useEffect(() => {
    refreshGenericModal(
      getGroupCreationParamsModal(
        groupCreationInputValue,
        handleNewGroupInputChange,
        handleSaveNewGroupDropdown,
        errorMessage,
        closeCleanUp,
      ),
    );
  }, [groupCreationInputValue]);

  const getIfHolidayZoneIsValid = () => {
    if (!!storeLocation.country) {
      if ([ISO_FRANCE].includes(storeLocation.country)) {
        return !!storeLocation.holidayZone;
      }
      return true;
    }
    return false;
  };

  const fetchBrandsOfClient = async (clientId) => {
    try {
      const brands = await brandService.getBrandsOfClient(clientId);
      setBrands(brands);
    } catch (err) {
      showErrorMessage(i18next.t('HOME.ACTIVITY_REPORT_FETCH_BRANDS_FAILURE'));

      return [];
    }
  };

  const fetchGroupsOfClient = async (clientId) => {
    try {
      const fetchedClientGroups = await clientService.getGroupsOfClient(clientId);
      setClientGroups(fetchedClientGroups);

      return fetchedClientGroups;
    } catch {
      showErrorMessage(i18next.t('BACKOFFICE.GROUPS.FETCHING_ERROR'));

      return [];
    }
  };

  const fetchRetailersOfClient = async (clientId) => {
    try {
      const retailers = await clientService.getRetailersOfClient(clientId);
      setRetailers(retailers);
    } catch (err) {
      showErrorMessage(i18next.t('STOCKS.CURRENT_STOCKS.GET_RETAILERS_FAILURE'));
      return [];
    }
  };

  const fetchLocationsOfClient = async (clientId) => {
    try {
      const locations = await clientService.getLocationsByClientId(clientId);
      setLocations(locations);
    } catch (err) {
      showErrorMessage(i18next.t('HOME.ACTIVITY_REPORT_FETCH_LOCATIONS_FAILURE'));
      return [];
    }
  };

  const checkValidPosition = (value, min, max) => !!value && (value < min || value > max);

  const fetchStoreDetail = async (storeId) => {
    pageLoading();

    try {
      const result = await storeService.getById(storeId);

      const closingDays = (result.closingDays || '').split(',');
      const closingDaysFormatted = DAYS().filter((day) =>
        closingDays.includes(day.itemValue.toString()),
      );
      result.closingDays = closingDaysFormatted;
      result.launchDate = result.launchDate ? moment(result.launchDate) : null;

      const locationValueArray = !!result.locationValue ? result.locationValue.split(',') : null;
      const latitude = get(locationValueArray, 0, '');
      const longitude = get(locationValueArray, 1, '');

      const lastMenuDateFormatted = !!result.lastMenuDate ? moment(result.lastMenuDate) : null;

      const updatedProductionPlanningValues = {
        consumptionComputation: result.consumptionComputation,
        showYesterdayStock: result.showYesterdayStock,
      };

      const resultFormatted = {
        ...result,
        name: isDuplication
          ? `${i18next.t('GENERAL.CLONE_NAME', { name: result.name })}`
          : result.name,
        country: getIsoCountryCode(result.country),
        location: result.locationId,
        targetProductionWasteRate: result.targetProductionWasteRate * 100,
        latitude,
        longitude,
        partnerId: isDuplication ? null : result.partnerId,
        type: result.type ? normalizeStringValue(result.type) : '',
        storeType: result.isKitchen ? CENTRAL_STORE_TYPE_ID : POINT_OF_SALE_STORE_TYPE_ID,
      };

      setStoreInformation(resultFormatted);
      setStoreLocation(resultFormatted);
      setStoreProduction(resultFormatted);
      setStoreGPSCoordinates(resultFormatted);
      setStoreProductMix({ lastMenuDate: lastMenuDateFormatted });
      setDisplayKitchenForm(result.isKitchen);
      setProductionPlanningSettings(updatedProductionPlanningValues);
      setStoreCompany(resultFormatted);
    } catch {
      showErrorMessage(i18next.t('ADMIN.STORES.PRODUCT_MAPPING_LOADING_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const translatedStoreName = getClientStoreNameTranslation(storeName).toLowerCase();

  const getPayloadStore = () => {
    const isKitchen = storeInformation.storeType === CENTRAL_STORE_TYPE_ID;

    return {
      active: storeInformation.active,
      additionnalAddress: storeLocation.additionnalAddress,
      address: storeLocation.adress,
      brandId: storeInformation.brandId,
      city: storeLocation.city,
      closingDays: storeInformation.closingDays.map(({ id }) => id).join(','),
      country: storeLocation.country,
      groups: formatNewItemsOfDropdownSelection(storeInformation.groups),
      holidayZone: storeLocation.holidayZone,
      isKitchen,
      latitude: storeGPSCoordinates.latitude,
      launchDate: storeInformation.launchDate,
      locationId: storeLocation.location,
      longitude: storeGPSCoordinates.longitude,
      name: storeInformation.name,
      partnerId: storeInformation.partnerId,
      postCode: storeLocation.postCode,
      productionLossConvention: storeProduction.productionLossConvention,
      productionStockConvention: storeProduction.productionStockConvention,
      retailerId: storeInformation.retailerId,
      stockConvention: storeInformation.stockConvention,
      targetProductionWasteRate: storeProduction.targetProductionWasteRate / 100,
      telephone: storeInformation.telephone,
      timezone: storeLocation.timezone,
      type: isKitchen ? null : storeInformation.type,
      consumptionComputation: isKitchen ? productionPlanningSettings.consumptionComputation : null,
      showYesterdayStock: isKitchen ? productionPlanningSettings.showYesterdayStock : false,
      companyName: storeCompany.companyName,
      siret: storeCompany.siret,
      vatNumber: storeCompany.vatNumber,
      companyType: storeCompany.companyType,
      capital: storeCompany.capital,
      companyRegister: storeCompany.companyRegister,
      paymentTermInDays: storeCompany.paymentTermInDays,
    };
  };

  const createStore = async (store) => {
    pageLoading();

    try {
      await clientService.createStore(clientId, store);

      showSuccessMessage(i18next.t('BACKOFFICE.STORES.CREATION_SUCCESS', { translatedStoreName }));
      history.goBack();
    } catch (err) {
      if (get(err, 'response.status') === 409) {
        setErrorsByPropertyKey({
          ...errorsByPropertyKey,
          partnerId: {
            value: storeInformation.partnerId,
            error: i18next.t('BACKOFFICE.STORES.VALUE_ALREADY_IN_USE'),
          },
        });
      }

      if (get(err, 'response.data.message') === 'The partnerId can not be an empty string') {
        setErrorsByPropertyKey({
          ...errorsByPropertyKey,
          partnerId: {
            value: storeInformation.partnerId,
            error: i18next.t('BACKOFFICE.STORES.CANNOT_BE_EMPTY_STRING'),
          },
        });
      }

      showErrorMessage(i18next.t('BACKOFFICE.STORES.CREATION_ERROR', { translatedStoreName }));
    } finally {
      pageLoaded();
    }
  };

  const updateStore = async (store) => {
    const storeId = params.id;

    pageLoading();

    try {
      await storeService.updateStoresInBatch([storeId], store);

      showSuccessMessage(i18next.t('BACKOFFICE.STORES.UPDATE_SUCCESS', { translatedStoreName }));

      history.goBack();
    } catch (err) {
      if (get(err, 'response.status') === 409) {
        setErrorsByPropertyKey({
          ...errorsByPropertyKey,
          partnerId: {
            value: storeInformation.partnerId,
            error: i18next.t('BACKOFFICE.STORES.VALUE_ALREADY_IN_USE'),
          },
        });
      }

      if (get(err, 'response.data.message') === 'The partnerId can not be an empty string') {
        setErrorsByPropertyKey({
          ...errorsByPropertyKey,
          partnerId: {
            value: storeInformation.partnerId,
            error: i18next.t('BACKOFFICE.STORES.CANNOT_BE_EMPTY_STRING'),
          },
        });
      }

      showErrorMessage(i18next.t('BACKOFFICE.STORES.UPDATE_FAILURE', { translatedStoreName }));
    } finally {
      pageLoaded();
    }
  };

  const duplicateStore = async (store) => {
    pageLoading();

    let createdStore, createdStoreId;

    try {
      createdStore = await clientService.createStore(clientId, store);
      createdStoreId = get(createdStore, 'id', null);
    } catch (err) {
      if (get(err, 'response.status') === 409) {
        setErrorsByPropertyKey({
          ...errorsByPropertyKey,
          partnerId: {
            value: storeInformation.partnerId,
            error: i18next.t('BACKOFFICE.STORES.VALUE_ALREADY_IN_USE'),
          },
        });
      }

      if (get(err, 'response.data.message') === 'The partnerId can not be an empty string') {
        setErrorsByPropertyKey({
          ...errorsByPropertyKey,
          partnerId: {
            value: storeInformation.partnerId,
            error: i18next.t('BACKOFFICE.STORES.CANNOT_BE_EMPTY_STRING'),
          },
        });
      }
    }

    try {
      await clientService.cloneEvents(clientId, createdStoreId, storeIdToClone);

      const storeToCloneGroupIds = storeToCloneGroups.map((group) => group.id);

      if (!isEmpty(storeToCloneGroupIds)) {
        await clientService.createStoreStoreGroupMappings(
          clientId,
          createdStoreId,
          storeToCloneGroupIds,
        );
      }

      showSuccessMessage(
        i18next.t('BACKOFFICE.STORES.DUPLICATION_SUCCESS', { translatedStoreName }),
      );

      history.goBack();
    } catch {
      showErrorMessage(i18next.t('BACKOFFICE.STORES.DUPLICATION_ERROR', { translatedStoreName }));
    } finally {
      pageLoaded();
    }
  };

  const handleSave = () => {
    const store = getPayloadStore();

    if (isDuplication) {
      duplicateStore(store);
      return;
    }

    if (isCreation) {
      createStore(store);
    } else {
      const storeWithUpdatedFields = {
        ...store,
        lastMenuDate: storeProductMix.lastMenuDate,
        adress: storeLocation.adress,
        locationValue: `${store.latitude},${store.longitude}`,
      };
      updateStore(storeWithUpdatedFields);
    }
  };

  const getIfCountryHasHolidayZone = () => [ISO_FRANCE].includes(storeLocation.country);

  const setCoordinateError = (type, value) => {
    if (type === 'latitude') {
      setHasLatitudeError(checkValidPosition(value, -90, 90));
    } else {
      setHasLongitudeError(checkValidPosition(value, -180, 180));
    }
  };

  const setLocationValue = (property, value, state, setter) => {
    const locationValueArray = state.locationValue.split(',');
    if (property === 'latitude') {
      setCoordinateError(property, value);
      const locationValue = `${value},${locationValueArray[1]}`;
      setter({ ...state, locationValue, [property]: value });
    }
    if (property === 'longitude') {
      setCoordinateError(property, value);
      const locationValue = `${locationValueArray[0]},${value}`;
      setter({ ...state, locationValue, [property]: value });
    }
  };

  const handleCardInformationsLocation = (property, value, state, setter) => {
    if (property === 'country') {
      const countryTimezones = getTimezonesForCountry(value);

      const defaultInput = hasMultipleTimezones ? null : state.timezone;

      setCountryHasUniqueTimezone(countryTimezones.length === 1);

      setter({
        ...state,
        country: value,
        timezone:
          hasMultipleTimezones && countryTimezones.length === 1
            ? head(countryTimezones).name
            : defaultInput,
      });
    } else {
      setter({ ...state, [property]: value });
    }
  };

  const storeGPSCoordinatesSettings = utilsStoreCreation.getStoreGPSCoordinatesCardSettings(
    storeGPSCoordinates,
    hasLatitudeError,
    hasLongitudeError,
  );

  const handleCardInformationsGPSCoordinates = (property, value, state, setter) => {
    if (isCreation && !initialisationLocationValue) {
      if (property === 'latitude') {
        setCoordinateError(property, value);
        const locationValue = `${value},}`;
        setter({ ...state, locationValue, [property]: value });
        setInitialisationLocationValue(true);
        return;
      }

      if (property === 'longitude') {
        setCoordinateError(property, value);
        const locationValue = `,${value}`;
        setter({ ...state, locationValue, [property]: value });
        setInitialisationLocationValue(true);

        return;
      }
      setter({ ...state, [property]: value });
    }
    if (!state.locationValue) {
      if (property === 'latitude') {
        setCoordinateError(property, value);
        const locationValue = `${value},}`;
        setter({ ...state, locationValue, [property]: value });
        setInitialisationLocationValue(true);
        return;
      }

      if (property === 'longitude') {
        setCoordinateError(property, value);
        const locationValue = `,${value}`;
        setter({ ...state, locationValue, [property]: value });
        setInitialisationLocationValue(true);

        return;
      }
    }
    if (!(isCreation && !initialisationLocationValue) && !!state.locationValue) {
      setLocationValue(property, value, state, setter);
    }
  };

  /** GROUPS */
  const handleNewGroupInputChange = (newValue) => {
    setGroupCreationInputValue(newValue);

    if (!newValue) {
      setErrorMessage(i18next.t('GENERAL.REQUIRED_FILED_ERROR_MESSAGE'));

      return;
    }

    const alreadyExists = clientGroups.some(
      ({ name }) => normalizeStringValue(name) === normalizeStringValue(newValue),
    );

    if (alreadyExists) {
      setErrorMessage(i18next.t('GENERAL.MODAL_GROUP_ALREADY_USED'));

      return;
    }

    setErrorMessage('');
  };

  const handleSaveNewGroupDropdown = () => {
    const newGroup = { id: clientGroups.length, name: groupCreationInputValue.trim() };

    const updatedGroups = sortBy([...clientGroups, newGroup], ['name']);

    setClientGroups(updatedGroups);

    const alreadySelectedGroups = storeInformation.groups;

    setStoreInformation({ ...storeInformation, groups: [...alreadySelectedGroups, newGroup] });

    closeCleanUp();
  };

  const closeCleanUp = () => {
    setErrorMessage('');
    setGroupCreationInputValue('');
  };

  const handleGroupsCreation = () => {
    openGenericModal(
      getGroupCreationParamsModal(
        groupCreationInputValue,
        handleNewGroupInputChange,
        handleSaveNewGroupDropdown,
        errorMessage,
        closeCleanUp,
      ),
    );
  };

  const storeProductionSettings = utilsStoreCreation.getStoreProductionCardSettings(
    storeProduction,
    displayKitchenForm,
  );

  const storeProductMixSettings =
    utilsStoreCreation.getStoreProductMixCardSettings(storeProductMix);

  const storeSettingsCardSettings = utilsStoreCreation.getStoreSettingsCardSettings(storeSettings);

  const storeDuplicationSettingsCardSettings =
    utilsStoreCreation.getStoreDuplicationSettingsCardSettings(storeDuplicationSettings);

  const storeInformationsSettings = utilsStoreCreation.getStoreInformationCardSettings(
    {
      storeInformation,
      brands,
      retailers,
      clientGroups,
      handleGroupsCreation,
      hasMultipleBrands,
    },
    isCreation,
    displayKitchenForm,
  );

  const storeCompanySettings = utilsStoreCreation.getStoreCompanySettings(storeCompany);

  /**********************/
  /**    Store clone   **/
  /**********************/

  const handleCloningAction = (cloningType, step) => {
    setCloneStoreStep(step);
    setCloningType(cloningType);
  };

  const cloneCall = async ({
    clientId,
    storeToCloneIntoId,
    storeToCloneFromId,
    successMessage,
    errorMessage,
    functionToCall,
  }) => {
    pageLoading();

    try {
      await functionToCall(clientId, storeToCloneIntoId, storeToCloneFromId);

      showSuccessMessage(successMessage);
    } catch (err) {
      showErrorMessage(errorMessage);
    }

    closeDuplicationModalCleanUp();
    closeGenericModal();
    pageLoaded();
  };

  const handleStoreDuplicationSave = async () => {
    const storeToCloneIntoId = params.id;
    const storeToCloneFromId = storeToCloneFrom.id;

    const cloningParameters = {
      [STORE_DUPLICATION_TYPE.PRODUCT_MIX]: {
        clientId,
        storeToCloneIntoId,
        storeToCloneFromId,
        successMessage: i18next.t('BACKOFFICE.STORES.DUPLICATION_PRODUCT_MIX_SUCCESS'),
        errorMessage: i18next.t('BACKOFFICE.STORES.DUPLICATION_PRODUCT_MIX_ERROR'),
        functionToCall: clientService.cloneProductMix,
      },
      [STORE_DUPLICATION_TYPE.PRODUCTION]: {
        clientId,
        storeToCloneIntoId,
        storeToCloneFromId,
        successMessage: i18next.t('BACKOFFICE.STORES.DUPLICATION_PROD_SUCCESS'),
        errorMessage: i18next.t('BACKOFFICE.STORES.DUPLICATION_PROD_ERROR'),
        functionToCall: clientService.cloneProduction,
      },
      [STORE_DUPLICATION_TYPE.EVENTS]: {
        clientId,
        storeToCloneIntoId,
        storeToCloneFromId,
        successMessage: i18next.t('BACKOFFICE.STORES.DUPLICATION_EVENTS_SUCCESS'),
        errorMessage: i18next.t('BACKOFFICE.STORES.DUPLICATION_EVENTS_ERROR'),
        functionToCall: clientService.cloneEvents,
      },
    };

    await cloneCall(cloningParameters[cloningType]);
  };

  const handleStoreToCloneFromChange = (selectedStore) => {
    setStoreToCloneFrom(selectedStore || {});
  };

  const closeDuplicationModalCleanUp = () => {
    setCloneStoreStep(null);
    setStoreToCloneFrom({});
    setCloningType(null);
  };

  const handleCloneStoreStep = (step) => {
    setCloneStoreStep(step);
  };

  const getStoresOfClient = async () => {
    try {
      const stores = await storeService.getStoresOfClient(clientId);

      const currentStore = find(stores, (store) => store.id === params.id);

      setStoreToCloneInto(currentStore);

      return stores.map((store) => ({
        ...store,
        ...(hasMultipleBrands ? { brandName: get(store, 'lnkBrandStorerel.name', '') } : {}),
        retailerName: get(store, 'lnkRetailerStorerel.name', ''),
        createdAt: moment(store.createdAt),
        mappedAccounts: store.mappedAccounts.length,
        launchDate: store.launchDate ? moment(store.launchDate) : null,
      }));
    } catch (err) {
      showSuccessMessage(
        i18next.t('GENERAL.STORES_CLIENT_FETCH_FAILURE', {
          storeName: translatedStoreName,
        }),
      );

      return [];
    }
  };

  useEffect(() => {
    if (!cloneStoreStep) {
      return;
    }

    const isModalOpened = get(props, 'modal.GenericModalBool', false);

    const modalParams = utilsModals.getStoreDuplicationModalConfig({
      cloningType,
      selectableStores,
      brands,
      dataColumns: cloneDataColumns,
      handleStoreDuplicationSave,
      handleStoreToCloneFromChange,
      storeToCloneFrom,
      closeDuplicationModalCleanUp,
      handleCloneStoreStep,
      cloneStoreStep,
      storeToCloneInto,
    });

    if (isModalOpened) {
      refreshGenericModal(modalParams);
      return;
    }

    openGenericModal(modalParams);
  }, [cloneStoreStep, storeToCloneFrom]);

  return (
    <>
      <NavBarContainer>
        <NavBar
          action={
            <Button
              color={'inpulse-default'}
              handleClick={handleSave}
              icon={'/images/inpulse/save-white-small.svg'}
              isDisabled={!isFormValid}
              label={i18next.t('GENERAL.SAVE')}
            />
          }
          bigTopBar={true}
          isCreation={isCreation}
          isDuplication={isDuplication}
          module={ENUM_MODULE_NAME.BACKOFFICE_STORE_DETAIL}
          name={storeInformation.name}
          path={path}
        />
        <NavigationPage />
      </NavBarContainer>
      <Container>
        <InputCardSection
          cardInformations={storeInformation}
          handleCardInformations={(property, value, state, setter) => {
            setter({ ...state, [property]: value });
          }}
          inputs={storeInformationsSettings.inputs}
          setter={setStoreInformation}
          title={storeInformationsSettings.title}
        />
        <InputCardSection
          cardInformations={productionPlanningSettings}
          handleCardInformations={(property, value, state, setter) => {
            setter({ ...state, [property]: value });
          }}
          inputs={centralKitchenProductionPlanningSettings.inputs}
          isDisabled={!displayKitchenForm}
          setter={setProductionPlanningSettings}
          title={centralKitchenProductionPlanningSettings.title}
        />
        <InputCardSection
          cardInformations={storeLocation}
          handleCardInformations={(property, value, state, setter) => {
            handleCardInformationsLocation(property, value, state, setter);
          }}
          inputs={storeLocationSettings.inputs}
          setter={setStoreLocation}
          title={storeLocationSettings.title}
        />
        <InputCardSection
          cardInformations={storeGPSCoordinates}
          handleCardInformations={(property, value, state, setter) => {
            handleCardInformationsGPSCoordinates(property, value, state, setter);
          }}
          inputs={storeGPSCoordinatesSettings.inputs}
          setter={setStoreGPSCoordinates}
          title={storeGPSCoordinatesSettings.title}
        />
        <InputCardSection
          cardInformations={storeCompany}
          handleCardInformations={(property, value, state, setter) => {
            setter({ ...state, [property]: value });
          }}
          inputs={storeCompanySettings.inputs}
          setter={setStoreCompany}
          title={storeCompanySettings.title}
        />
        <InputCardSection
          cardInformations={storeProductMix}
          handleCardInformations={(property, value, state, setter) => {
            setter({ ...state, [property]: value });
          }}
          inputs={storeProductMixSettings.inputs}
          isDisabled={isCreation || displayKitchenForm}
          setter={setStoreProductMix}
          title={storeProductMixSettings.title}
        />
        <InputCardSection
          cardInformations={storeProduction}
          handleCardInformations={(property, value, state, setter) => {
            setter({ ...state, [property]: value });
          }}
          inputs={storeProductionSettings.inputs}
          isDisabled={displayKitchenForm}
          setter={setStoreProduction}
          title={storeProductionSettings.title}
        />
        <InputCardSection
          cardInformations={storeSettings}
          handleCardInformations={handleCloningAction}
          inputs={storeSettingsCardSettings.inputs}
          isDisabled={isCreation || displayKitchenForm}
          setter={setStoreSettings}
          title={storeSettingsCardSettings.title}
        />
        <InputCardSection
          cardInformations={storeDuplicationSettings}
          contentsPadding="12px 0px 0px 0px"
          inputs={storeDuplicationSettingsCardSettings.inputs}
          isDisabled={!isDuplication || displayKitchenForm}
          setter={setStoreDuplicationSettings}
          title={storeDuplicationSettingsCardSettings.title}
        />
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  closeGenericModal: (params) => {
    dispatch(closeGenericModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeStoresDetails);
