import axios from '../core/http';

import { APIcaller } from './APIcaller';
import { config } from '../config';

const baseApiUrl = config.baseApiUrl;

export function getAllCashiers() {
  return axios
    .get(`${baseApiUrl}/backoffice/cashiers`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('loopbackAccessToken'),
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export const getFtpStatusByStoreIdsAndDate = (storeIds, startDate, endDate) =>
  APIcaller.apiPost('/admin/cashiers/cashierFtpStatus', { storeIds, startDate, endDate });

export default {
  getAllCashiers,
  getFtpStatusByStoreIdsAndDate,
};
